import "core-js/modules/es.number.constructor.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "sharing-btn-text"
};
export default {
  __name: 'ShareButton',
  props: {
    text: {
      type: String,
      default: ""
    },
    showBreathAnimation: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: undefined
    }
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["sharing-btn haptics-feedback--80", {
          breath: __props.showBreathAnimation,
          'sharing-btn--70': __props.height === 70
        }])
      }, [_createElementVNode("div", _hoisted_1, _toDisplayString(__props.text), 1)], 2);
    };
  }
};