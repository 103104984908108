import { ref } from "vue";

export function useShare() {
  const showSharePopup = ref(false);
  const shareText = ref("");
  const shareLink = ref("");
  const toShare = async (title, text, url) => {
    if ("canShare" in navigator) {
      await navigator.share({
        title,
        text,
        url,
      });
    } else {
      showSharePopup.value = true;
      shareText.value = text;
      shareLink.value = url;
    }
  };

  return {
    showSharePopup,
    shareText,
    shareLink,
    toShare,
  };
}
