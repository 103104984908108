import { ref } from "vue";

export const useScrollMenu = (options = {}) => {
  const sidebarScrollBoxId = options.sidebarScrollBoxId || "sidebar-scroll-box";
  const contentScrollBoxId = options.contentScrollBoxId || "content-scroll-box";
  const contentScrollAnchorClass =
    options.contentScrollAnchorClass || "content-scroll-anchor";
  const sidebarCateItemClass =
    options.sidebarCateItemClass || "sidebar-cate-item";
  const customAnchorElTopOffset = options.customAnchorElTopOffset || 0;
  const isContentScrollSmooth = options.isContentScrollSmooth || false;

  const curCateIndex = ref(0); // sidebar上当前分类的索引
  let chosenCateIndex = -1; // handleChooseCate选中的sidebar上的分类索引
  const sidebarScrollBox = ref(null);
  const contentScrollBox = ref(null);
  const scrollAnchors = ref([]); // 每个分类的滚动区域
  const sidebarCateItem = ref(null);

  // 处理点击sidebar上的分类，contentScrollBox滚动到该分类的滚动区域内
  function handleChooseCate(index) {
    if (!contentScrollBox.value) {
      return;
    }

    curCateIndex.value = index;

    // 判断是否可滚动到对应的位置
    const maxScrollTop =
      contentScrollBox.value.scrollHeight - contentScrollBox.value.clientHeight;
    const cannotScroll =
      Math.abs(contentScrollBox.value.scrollTop - maxScrollTop) < 2 &&
      scrollAnchors.value[index]?.elTop > maxScrollTop;

    if (cannotScroll) {
      chosenCateIndex = -1;
    } else {
      chosenCateIndex = index;
      if (isContentScrollSmooth) {
        contentScrollBox.value.scrollTo({
          left: 0,
          top: scrollAnchors.value[index]?.elTop || 0,
          behavior: "smooth",
        });
      } else {
        contentScrollBox.value.scrollTop =
          scrollAnchors.value[index]?.elTop || 0;
      }
    }
  }

  // mounted后，计算每个分类对应的滚动区域
  function calculateScrollAnchors() {
    sidebarScrollBox.value = document.getElementById(sidebarScrollBoxId);
    contentScrollBox.value = document.getElementById(contentScrollBoxId);
    sidebarCateItem.value =
      document.getElementsByClassName(sidebarCateItemClass)[0];

    const cateContentDoms = document.getElementsByClassName(
      contentScrollAnchorClass
    );

    const anchors = [];
    const contentScrollBoxOffsetTop =
      contentScrollBox.value?.offsetTop &&
      contentScrollBox.value.offsetTop === cateContentDoms[0]?.offsetTop
        ? contentScrollBox.value.offsetTop
        : 0;

    for (let i = 0; i < cateContentDoms.length; i++) {
      anchors.push({
        elTop:
          i === 0
            ? 0
            : cateContentDoms[i].offsetTop -
              contentScrollBoxOffsetTop +
              customAnchorElTopOffset,
        nextTop:
          cateContentDoms[i].offsetTop +
          cateContentDoms[i].offsetHeight -
          contentScrollBoxOffsetTop +
          customAnchorElTopOffset,
      });
    }

    scrollAnchors.value = anchors;
  }

  // 处理contentScrollBox的滚动，scrollTop落在某个分类的滚动区域内时，更新curCateIndex
  let resetChosenCateIndexTimer;
  function handleContentScroll() {
    if (!contentScrollBox.value) {
      return;
    }
    clearTimeout(resetChosenCateIndexTimer);

    let scrollTop = contentScrollBox.value.scrollTop || 0;
    const activeIndex = scrollAnchors.value.findIndex((item) => {
      // scrollTop小数误差纠正
      if (Math.abs(item.elTop - scrollTop) < 2) {
        scrollTop = item.elTop;
      } else if (Math.abs(item.nextTop - scrollTop) < 2) {
        scrollTop = item.nextTop;
      }

      return scrollTop >= item.elTop && scrollTop < item.nextTop;
    });

    if (chosenCateIndex >= 0) {
      // 直奔chosenCateIndex对应的锚点，忽略中途经过的锚点
      if (activeIndex === chosenCateIndex) {
        curCateIndex.value = activeIndex;
        chosenCateIndex = -1;
      } else {
        const value =
          contentScrollBox.value.scrollTop +
          contentScrollBox.value.clientHeight;
        const scrollHeight = contentScrollBox.value.scrollHeight;

        // 滚到底部都滚不到chosenCateIndex对应的位置
        if (Math.abs(value - scrollHeight) < 2) {
          // resetChosenCateIndexTimer不被clear，说明滚动停止
          resetChosenCateIndexTimer = setTimeout(() => {
            chosenCateIndex = -1;
          }, 30);
        }
      }
    } else {
      curCateIndex.value = activeIndex;
    }
  }

  // curCateIndex变化时，sidebarScrollBox滚动，使得curCateIndex总是位于sidebarScrollBox高度三分之一的位置
  function handleCurCateIndexChange(val) {
    const sidebarCateItemHeight = sidebarCateItem.value?.offsetHeight;
    if (sidebarScrollBox.value && sidebarCateItemHeight) {
      const top =
        sidebarCateItemHeight * val > sidebarScrollBox.value.offsetHeight / 3
          ? sidebarCateItemHeight * val -
            sidebarScrollBox.value.offsetHeight / 3
          : 0;

      sidebarScrollBox.value.scrollTo({
        left: 0,
        top,
        behavior: "smooth",
      });
    }
  }

  return {
    curCateIndex,
    sidebarScrollBox,
    contentScrollBox,
    scrollAnchors,
    handleChooseCate,
    calculateScrollAnchors,
    handleContentScroll,
    handleCurCateIndexChange,
  };
};
