import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default {
  __name: 'Button',
  props: {
    text: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "content" // 目前分border和content两种
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click", "disabledClick"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emits = __emit;
    var handleClick = function handleClick(e) {
      if (!props.disabled) {
        emits("click", e);
      } else {
        emits("disabledClick", e);
      }
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["btn-ctn", ["btn-".concat(__props.type === 'content' ? 'content' : 'border'), {
          'btn-disabled': __props.disabled,
          'van-haptics-feedback': !__props.disabled
        }]]),
        onClick: handleClick
      }, [_renderSlot(_ctx.$slots, "default")], 2);
    };
  }
};