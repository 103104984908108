import { defineStore } from "pinia";
import store from "./index";

export const useActivityHistoryListStore = defineStore({
  id: "activityHistoryList",
  state: () => {
    return {
      list: [],
      pageNum: 1,
      finished: false,
    };
  },
  getters: {
    getList(state) {
      return state.list;
    },
    getPageNum(state) {
      return state.pageNum;
    },
    getFinished(state) {
      return state.finished;
    },
  },
  actions: {
    setList(val) {
      this.list = val;
    },
    setPageNum(val) {
      this.pageNum = val;
    },
    setFinished(val) {
      this.finished = val;
    },
    resetState() {
      this.setList([]);
      this.setPageNum(1);
      this.setFinished(false);
    },
  },
});

export function useActivityHistoryListStoreWithOut() {
  return useActivityHistoryListStore(store);
}
