import { ErrorType, FilterErrorReportType, WarnedErrorType } from "./type";

// 配置错误降级规则，任意一个handler返回true时降级
export const warnedErrorRules = [
  {
    type: WarnedErrorType.NETWORK_ERROR,
    handler(errorDetail) {
      return /ERR_NETWORK|Network Error/.test(errorDetail.errorMsg);
    },
  },
  {
    type: WarnedErrorType.ECONNABORTED,
    handler(errorDetail) {
      return errorDetail.errorMsg.indexOf("ECONNABORTED") !== -1;
    },
  },
  {
    type: WarnedErrorType.RESOURCE,
    handler(errorDetail) {
      return errorDetail.type === ErrorType.Resource;
    },
  },
  {
    type: WarnedErrorType.GOOGLE_SCRIPT_ERROR,
    handler(errorDetail) {
      // return /load google map error|Failed to load Google Maps script|load Google sign in script failed/.test(
      return /load Google sign in script failed/.test(errorDetail.errorMsg);
    },
  },
];

// 配置错误报告过滤规则，任意一个handler返回false时过滤错误
export const filterErrorReportRules = [
  {
    type: FilterErrorReportType.IMG_RESOURCE,
    handler(errorDetail) {
      return errorDetail.tag !== "IMG";
    },
  },
  {
    type: FilterErrorReportType.RESIZEOBSERVER_LOOP_LIMIT_EXCEEDED,
    handler(reportData) {
      return !/ResizeObserver loop limit exceeded/.test(reportData.errorMsg);
    },
  },
  {
    type: FilterErrorReportType.SCRIPT_ERROR,
    handler(reportData) {
      return !/Script error/.test(reportData.errorMsg);
    },
  },
  {
    type: FilterErrorReportType.LOGIN_ACTION,
    handler(reportData) {
      return !/popup_closed_by_user|user_trigger_new_signin_flow/.test(
        reportData.errorMsg
      );
    },
  },
];
