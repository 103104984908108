import "core-js/modules/es.array.concat.js";
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/images/icons/close.svg';
import _imports_1 from '@/assets/images/whatsapp.svg';
import _imports_2 from '@/assets/images/facebook.svg';
import _imports_3 from '@/assets/images/copy.svg';
var _hoisted_1 = {
  class: "share-popup_body"
};
import { useVModel } from "@vueuse/core";
import { useClipboard } from "@vueuse/core";
import { showToast } from "@/utils/toastUtil";
export default {
  __name: 'SharePopup',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ""
    },
    link: {
      type: String,
      default: ""
    }
  },
  emits: ["update:show"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var showPopup = useVModel(props, "show", emit);
    var share2Whatsapp = function share2Whatsapp(t) {
      try {
        var url = "https://api.whatsapp.com/send?text=".concat(t);
        location.href = url;
      } catch (e) {
        showToast("Whatsapp is not detected on your phone");
      }
    };
    var share2Facebook = function share2Facebook(t) {
      location.href = "https://www.facebook.com/sharer/sharer.php?u=".concat(encodeURIComponent(t));
    };
    var toCopy = function toCopy(t) {
      var _useClipboard = useClipboard({
          legacy: true
        }),
        copy = _useClipboard.copy;
      copy(t).then(function (res) {
        showToast("copied!");
      }).catch(function (e) {
        showToast("Failed!");
      });
    };
    var onShare = function onShare(key) {
      if (key === "whatsapp") {
        share2Whatsapp("".concat(props.text, " ").concat(props.link));
      } else if (key === "facebook") {
        share2Facebook(props.link);
      } else if (key === "copy") {
        toCopy("".concat(props.text, " ").concat(props.link));
      }
    };
    return function (_ctx, _cache) {
      var _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createBlock(_component_van_popup, {
        show: _unref(showPopup),
        "onUpdate:show": _cache[4] || (_cache[4] = function ($event) {
          return _isRef(showPopup) ? showPopup.value = $event : null;
        }),
        class: "share-popup",
        position: "bottom"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("img", {
            class: "close",
            src: _imports_0,
            alt: "",
            onClick: _cache[0] || (_cache[0] = function ($event) {
              return showPopup.value = false;
            })
          }), _cache[8] || (_cache[8] = _createElementVNode("div", {
            class: "share-popup_title"
          }, "Select Share Method", -1)), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
            class: "item",
            onClick: _cache[1] || (_cache[1] = function ($event) {
              return onShare('whatsapp');
            })
          }, _cache[5] || (_cache[5] = [_createElementVNode("img", {
            src: _imports_1,
            alt: ""
          }, null, -1)])), _createElementVNode("div", {
            class: "item",
            onClick: _cache[2] || (_cache[2] = function ($event) {
              return onShare('facebook');
            })
          }, _cache[6] || (_cache[6] = [_createElementVNode("img", {
            src: _imports_2,
            alt: ""
          }, null, -1)])), _createElementVNode("div", {
            class: "item",
            onClick: _cache[3] || (_cache[3] = function ($event) {
              return onShare('copy');
            })
          }, _cache[7] || (_cache[7] = [_createElementVNode("img", {
            src: _imports_3,
            alt: ""
          }, null, -1)]))])];
        }),
        _: 1
      }, 8, ["show"]);
    };
  }
};