import { useListStore } from "@/store/listStore";
import { onBeforeRouteLeave } from "vue-router";
export function useRememberScroll(id, getter, setter) {
  const listStore = useListStore();
  let isSet = false;
  function rememberScrollPos() {
    onBeforeRouteLeave(() => {
      const listDom = document.getElementById(id);
      listStore[setter](listDom?.scrollTop || 0);
    });
  }

  function setScrollPos() {
    const listDom = document.getElementById(id);
    if (listDom && listStore[getter] > 0 && !isSet) {
      listDom.scrollTop = listStore[getter];
      isSet = true;
    }
  }

  return {
    rememberScrollPos,
    setScrollPos,
  };
}
