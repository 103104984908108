import { getUserDetail } from "@/api/user";
import { isLogin } from "./authUtil";
import { useUserStoreWithOut } from "@/store/userStore";
import { useSupplierStoreWithOut } from "@/store/supplierStore";
import { useShopStoreWithOut } from "@/store/shopStore";
import { useAddressStoreWithOut } from "@/store/addressStore";
import { initMock } from "./mock";
import { isFunction } from "./isUtil";

export async function init() {
  if (process.env.NODE_ENV === "development" && process.env.VUE_APP_TEST_ID) {
    isFunction(initMock) && initMock();
  }

  const supplierStore = useSupplierStoreWithOut();
  await supplierStore.setSupplierSetting();

  // 如果query里有shop_id，则切换到该店铺
  if (window.__SHOP_ID__) {
    const shopStore = useShopStoreWithOut();
    shopStore.setShopId(window.__SHOP_ID__);
  }

  if (isLogin()) {
    await afterLoginInit();
  }
}

export function initDocHeight() {
  const documentHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
  };
  window.addEventListener("resize", documentHeight);
  documentHeight();
}

export async function afterLoginInit() {
  const supplierStore = useSupplierStoreWithOut();
  const userStore = useUserStoreWithOut();
  const shopStore = useShopStoreWithOut();
  const addressStore = useAddressStoreWithOut();

  // 并行处理：
  // 1.获取用户信息
  // 2.获取用户自配送地址列表
  // 3.缓存定位
  await Promise.all([
    (async function () {
      const res = await getUserDetail();
      if (res.isOK) {
        userStore.setUserInfo(res.data);
      }
    })(),
    addressStore.loadAddressList(),
    (async function () {
      // 活动页不获取地理位置
      if (!window.__ACTIVITY_INFO__?.activity_id) {
        await userStore.initCoordinate();
      }
    })(),
  ]);

  // 如果有记录过的店铺id，手动切换一次到该店铺
  if (shopStore.getShopId) {
    const targetShop = supplierStore.getShopList.find((item) => {
      return item.shop_id === shopStore.getShopId;
    });
    if (targetShop) {
      shopStore.setShopId("");
      shopStore.switchShop(targetShop);
    }
  }
}
