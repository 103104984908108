export const PriceStrCurrencySymbolEnum = {
  MYR: "RM ",
  VND: " ₫",
  THB: "฿ ",
  IDR: "Rp ",
  TRY: " TL",
  PHP: "₱ ",
  USD: "$ ",
  default: "",
};
