import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/images/rewards/coupon_bg.png';
var _hoisted_1 = {
  class: "recharge-reward-list-wrap"
};
var _hoisted_2 = {
  class: "main"
};
var _hoisted_3 = {
  class: "name van-ellipsis"
};
var _hoisted_4 = {
  key: 0,
  class: "qty"
};
var _hoisted_5 = {
  key: 0,
  class: "condition-list"
};
import Common from "@/components/dialog/src/Common.vue";
import { computed, ref, watch } from "vue";
import { priceUtil } from "@op/shared";
import { OrderTypeLabelMap } from "@/constant/order";
export default {
  __name: 'RechargeRuleRewardPopup',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    rewardList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    currency: {
      type: String,
      default: ""
    }
  },
  emits: ["update:show"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emits = __emit;
    var isVisible = computed({
      get: function get() {
        return props.show;
      },
      set: function set(val) {
        emits("update:show", val);
      }
    });
    var getCouponConditionlist = function getCouponConditionlist(rewardItem) {
      var couponInfo = rewardItem.coupon_info;
      var discountRule = couponInfo.discount_rule;
      var shopScope = couponInfo.using_scope.shop_scope;
      var dishScope = couponInfo.using_scope.dishes_scope;
      var arr = [];
      // 有效期
      var usingTimeRule = couponInfo.using_time_rule;
      if ((usingTimeRule === null || usingTimeRule === void 0 ? void 0 : usingTimeRule.expired_interval) > 0) {
        arr.push("Valid for ".concat(usingTimeRule.expired_interval, " days upon receiving."));
      }
      // 使用方式
      arr.push("Applicable for ".concat(couponInfo.using_scope.order_types.map(function (t) {
        return OrderTypeLabelMap[t];
      }).join(","), "."));
      // 使用门槛
      if (discountRule.min_consumption) {
        arr.push("Minimum spend of ".concat(priceUtil.formatBaseUnitToShowPrice(discountRule.min_consumption, discountRule.currency), " is required."));
      }
      // 最高使用规则
      // if (!isFixedCoupon.value) {
      arr.push("Maximum reduction ".concat(priceUtil.formatBaseUnitToShowPrice(discountRule.max_discount, discountRule.currency), "."));
      // }
      // 适用门店范围
      if (!!shopScope) {
        var shopNameList = shopScope === null || shopScope === void 0 ? void 0 : shopScope.scope_names;
        // 适用所有店铺
        if ((shopScope === null || shopScope === void 0 ? void 0 : shopScope.scope_type) === "all") {
          arr.push("Store restrictions: apply to all stores.");
        } else if ((shopScope === null || shopScope === void 0 ? void 0 : shopScope.scope_type) === "exclude_shops") {
          // 排除某些店铺不可用
          var str = "";
          shopNameList.forEach(function (item, index) {
            str += item + "";
            if (index < shopNameList.length - 1) str += ",";
          });
          arr.push("Store restrictions: not apply to ".concat(str, "."));
        } else {
          // 某些店铺可用
          var _str = "";
          shopNameList.forEach(function (item, index) {
            _str += item + "";
            if (index < shopNameList.length - 1) _str += ",";
          });
          arr.push("Store restrictions: apply to ".concat(_str, "."));
        }
      }
      // 适用菜品范围
      if (!!dishScope) {
        var dishesNameList = dishScope === null || dishScope === void 0 ? void 0 : dishScope.scope_names;
        // 适用所有
        if ((dishScope === null || dishScope === void 0 ? void 0 : dishScope.scope_type) === "all") {
          arr.push("Products restrictions: apply to all products.");
        } else if ((dishScope === null || dishScope === void 0 ? void 0 : dishScope.scope_type) === "dishes") {
          // 某些菜品可用
          var _str2 = "";
          dishesNameList.forEach(function (item, index) {
            _str2 += item + "";
            if (index < dishesNameList.length - 1) _str2 += ",";
          });
          arr.push("Products restrictions: apply to product(".concat(_str2, ")."));
        } else {
          // 某些分类可用
          var _str3 = "";
          dishesNameList.forEach(function (item, index) {
            _str3 += item + "";
            if (index < dishesNameList.length - 1) _str3 += ",";
          });
          arr.push("Products restrictions: apply to category(".concat(_str3, ")."));
        }
      }
      return arr;
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(Common, {
        visible: isVisible.value,
        "onUpdate:visible": _cache[0] || (_cache[0] = function ($event) {
          return isVisible.value = $event;
        }),
        "without-cancel": "",
        "show-close-btn": "",
        "show-bottom-button": false,
        title: 'Gift items'
      }, {
        content: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.rewardList, function (item, index) {
            return _openBlock(), _createElementBlock("div", {
              key: item.resource_id || 'balance' + index,
              class: "item"
            }, [_createElementVNode("div", _hoisted_2, [_cache[1] || (_cache[1] = _createElementVNode("img", {
              class: "icon",
              src: _imports_0
            }, null, -1)), _createElementVNode("div", _hoisted_3, _toDisplayString(item.type === "balance" ? _unref(priceUtil).formatBaseUnitToShowPrice(item.amount, __props.currency) : item.resource_name), 1), item.type === 'coupon' ? (_openBlock(), _createElementBlock("div", _hoisted_4, " x" + _toDisplayString(item.quantity), 1)) : _createCommentVNode("", true)]), item.type === 'coupon' ? (_openBlock(), _createElementBlock("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getCouponConditionlist(item), function (cond) {
              return _openBlock(), _createElementBlock("div", {
                key: cond,
                class: "condition-item"
              }, [_cache[2] || (_cache[2] = _createElementVNode("div", {
                class: "dot"
              }, "•", -1)), _createElementVNode("div", null, _toDisplayString(cond), 1)]);
            }), 128))])) : _createCommentVNode("", true)]);
          }), 128))])];
        }),
        _: 1
      }, 8, ["visible"]);
    };
  }
};