export function setCssVar(key, val) {
  const root = document.querySelector(":root");
  root.style.setProperty(key, val);
}

export function setFavicon(url) {
  // 获取 head 标签
  const head = document.getElementsByTagName("head")[0];

  // 获取当前 favicon 元素
  const favicon = document.createElement("link");
  favicon.type = "image/x-icon";
  favicon.rel = "shortcut icon";
  // 设置新的 favicon 地址
  favicon.href = url;
  // 如果当前 head 标签中不存在 favicon 元素，则将新的 favicon 添加到 head 标签中
  if (!document.querySelector("link[rel*='icon']")) {
    head.appendChild(favicon);
  }
}
