import { getSupplyId, getUserId } from "@/utils/authUtil";
import { post } from "@/utils/requestUtil";
import { useUserStoreWithOut } from "@/store/userStore";

export function getOngoingActivity(params = {}) {
  const userStore = useUserStoreWithOut();
  params.company_id = getSupplyId();
  params.crm_user_id = userStore.getCrmUserId;
  return post("/crm/marketing/activity/v1/user_activity/ongoing", params, {
    apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
  });
}

export function getUserActivity(params) {
  const userStore = useUserStoreWithOut();
  params.company_id = getSupplyId();
  params.crm_user_id = userStore.getCrmUserId;
  return post("/crm/marketing/activity/v1/user_activity/list", params, {
    apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
  });
}

export function getSharingUserActivityInfo(params) {
  params.company_id = getSupplyId();
  params.user_id = getUserId();
  return post(
    "/crm/marketing/activity/v1/sharing_activity/user_sharing_info",
    params,
    {
      apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
    }
  );
}

export function createSharingUserActivity(params) {
  params.user_id = getUserId();
  return post("/crm/marketing/activity/v1/sharing_activity/sharing", params, {
    apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
  });
}

export function assistSharingUserActivity(params) {
  params.user_id = getUserId();
  return post("/crm/marketing/activity/v1/sharing_activity/assist", params, {
    apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
  });
}

export function participateSendCouponActivity(params) {
  params.user_id = getUserId();
  return post(
    "/crm/marketing/activity/v1/send_coupon_activity/user_participate",
    params,
    {
      apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
    }
  );
}

export function participateRandomCouponActivity(params) {
  params.user_id = getUserId();
  return post(
    "/crm/customer/activity/v1/random_coupon_activity/user_participate",
    params,
    {
      apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
    }
  );
}
