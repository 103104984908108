import { createPinia } from "pinia";
import piniaPersist from "./plugin/persist";

const pinia = createPinia();
pinia.use(piniaPersist);

export function setupStore(app) {
  app.use(pinia);
}

export default pinia;
