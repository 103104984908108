/**
 * 本地存储实现,封装localStorage和sessionStorage
 */
const isServer = typeof window === "undefined";

export const Storage = {
  version: "1.1.1",
  storage: !isServer ? window.localStorage : null,
  session: {
    storage: !isServer ? window.sessionStorage : null,
  },
};

const api = {
  set(key, val) {
    if (this.disabled) {
      return;
    }
    if (val === undefined) {
      return this.remove(key);
    }
    this.storage.setItem(key, serialize(val));
    return val;
  },

  get(key, def) {
    if (this.disabled) {
      return def;
    }
    let val = deserialize(this.storage.getItem(key));
    return val === undefined ? def : val;
  },

  has(key) {
    return this.get(key) !== undefined;
  },

  remove(key) {
    if (this.disabled) {
      return;
    }
    this.storage.removeItem(key);
  },

  clear() {
    if (this.disabled) {
      return;
    }
    this.storage.clear();
  },

  getAll() {
    if (this.disabled) {
      return null;
    }
    let ret = {};
    this.forEach((key, val) => {
      ret[key] = val;
    });
    return ret;
  },

  forEach(callback) {
    if (this.disabled) {
      return;
    }
    for (let i = 0; i < this.storage.length; i++) {
      let key = this.storage.key(i);
      callback(key, this.get(key));
    }
  },
};

Object.assign(Storage, api);

Object.assign(Storage.session, api);

function serialize(val) {
  return JSON.stringify(val);
}

function deserialize(val) {
  if (typeof val !== "string") {
    return undefined;
  }
  try {
    return JSON.parse(val);
  } catch (e) {
    return val || undefined;
  }
}

try {
  const testKey = "__storejs__";
  Storage.set(testKey, testKey);
  if (Storage.get(testKey) !== testKey) {
    Storage.disabled = true;
    alert("本地缓存被禁用, 请查看你的设置, 否则无法正常使用应用");
  }
  Storage.remove(testKey);
} catch (e) {
  Storage.disabled = true;
  alert("本地缓存被禁用, 请查看你的设置, 否则无法正常使用应用");
}
