import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Page from "./components/Page.vue";
import Gap from "./components/base/Gap.vue";
import "./styles/common.scss";
import "./styles/animation.scss";
import "vant/es/toast/style";

import "vant/lib/index.css";
import { setupStore } from "./store";
import { setupDirective } from "./directive";
import { setupWebTrack } from "@/hooks/useTracker";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { init, initDocHeight } from "./utils/initUtil";
import { Notify } from "vant";
import { initPwa } from "@/utils/pwaGuide/pwaGuide";
import Mixins from "./mixins/global";
// import vconsole from "vconsole";

// 尝试吧initPwa放到最前面
initPwa();

const requireContext = require.context("./assets/icons", false, /\.svg$/);
requireContext.keys().forEach(requireContext);
dayjs.extend(utc);
dayjs.extend(timezone);
const app = createApp(App);

setupStore(app);
setupDirective(app);
setupWebTrack(app);

app.use(Notify);
app.component("Page", Page).component("Gap", Gap).use(router);
// use vconsole
// const vConsole = new vconsole();
// app.use(vConsole);
init()
  .then(() => {
    // initDocHeight();
    app.mount("#app");
    document.getElementById("loading")?.remove();
  })
  .catch((e) => {
    // initDocHeight();
    app.mount("#app");
    document.getElementById("loading")?.remove();
  });

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/sw.js");
}

app.mixin(Mixins);
