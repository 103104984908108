import { post, get } from "@/utils/requestUtil";

export function getPaymentSetting(params) {
  return get(
    `/orderpin/merchant_operation/c/v1/payment_setting/${params.shop_id}`
  );
}

// export function getAllDishes(params) {
//   return get(`/orderpin/dishes/c/v1/dishes/all/${params.shop_id}`);
// }
export function getAllDishes(params) {
  return post("/orderpin/dishes/c/v1/shop/menu/dishes/all", {
    shop_id: params.shop_id,
    channel: "mini_program",
  });
}

export function getAllShopBySupplier(params) {
  return post(
    `/o2oms/merchant/v1/supplier/shop_list/${params.supplier_id}`,
    params
  );
}
