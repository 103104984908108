import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "select-coupon-list-wrap"
};
import Common from "@/components/dialog/src/Common.vue";
import { computed, ref, watch } from "vue";
import SelectCouponItem from "./SelectCouponItem.vue";
import { useCartStore } from "@/store/cartStore";
export default {
  __name: 'SelectCouponList',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    couponList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ["update:show"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var cartStore = useCartStore();
    var props = __props;
    var emits = __emit;
    var showSelectCouponList = computed({
      get: function get() {
        return props.show;
      },
      set: function set(val) {
        emits("update:show", val);
      }
    });
    watch(showSelectCouponList, function (val) {
      if (val) {
        if (cartStore.getCoupon) {
          tempSelectCoupon.value = cartStore.getCoupon;
        }
      }
    });
    var tempSelectCoupon = ref(null);
    function selectCoupon(couponItem) {
      if (tempSelectCoupon.value && tempSelectCoupon.value.id === couponItem.id) {
        tempSelectCoupon.value = null;
      } else if (!couponItem.cannot_use) {
        tempSelectCoupon.value = couponItem;
      }
    }
    function handleConfirm() {
      if (tempSelectCoupon.value) {
        cartStore.setCoupon(tempSelectCoupon.value);
      } else {
        cartStore.setCoupon(null);
      }
      showSelectCouponList.value = false;
    }
    var okText = computed(function () {
      return tempSelectCoupon.value ? "Use this voucher" : "Don't use voucher";
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(Common, {
        visible: showSelectCouponList.value,
        "onUpdate:visible": _cache[0] || (_cache[0] = function ($event) {
          return showSelectCouponList.value = $event;
        }),
        "ok-text": okText.value,
        "without-cancel": "",
        "show-close-btn": "",
        title: 'Select Vouchers',
        "on-ok": handleConfirm
      }, {
        content: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.couponList, function (item) {
            var _tempSelectCoupon$val;
            return _openBlock(), _createBlock(SelectCouponItem, {
              key: item.id,
              class: "select-coupon-item",
              "coupon-item": item,
              "selected-id": ((_tempSelectCoupon$val = tempSelectCoupon.value) === null || _tempSelectCoupon$val === void 0 ? void 0 : _tempSelectCoupon$val.id) || '',
              onSelect: function onSelect($event) {
                return selectCoupon(item);
              }
            }, null, 8, ["coupon-item", "selected-id", "onSelect"]);
          }), 128))])];
        }),
        _: 1
      }, 8, ["visible", "ok-text"]);
    };
  }
};