import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["xlink:href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    class: "svg-icon",
    "aria-hidden": "true",
    style: _normalizeStyle(_ctx.getStyle)
  }, [_createElementVNode("use", {
    "xlink:href": "#icon-".concat(_ctx.name)
  }, null, 8, _hoisted_1)], 4);
}