import "./pwaGuide.scss";
import { checkPlatform, getIsIphoneSafari } from "@/utils/tools";
import Dialog from "@/components/dialog/dialog";
// import { useSupplierStore } from "@/store/supplierStore";
// import { saTrack } from "../sensorsUtil";
const closeIcon = require("@/assets/images/icons/close_white.svg");
// const logo = require("@/assets/images/logo-no-text.png");

const appInstallStorageKey = "hide-app-install";
let installAppBanner = null;

// let pageType = "unknown";

// if (window.__INITIAL_STATE__ && window.__INITIAL_STATE__.route) {
//   pageType = window.__INITIAL_STATE__.route.name;
// }

// export const isInPwa = window.matchMedia("(display-mode: standalone)").matches;

export const getIsInPwa = () => {
  if (checkPlatform("ios")) {
    return !!navigator.standalone;
  } else {
    return window.matchMedia("(display-mode: standalone)").matches;
  }
};

let deferredPrompt;

// 非ios下的初始化方法
export function initElse() {
  window.addEventListener("beforeinstallprompt", (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Update UI notify the user they can install the PWA
    showInstallPromotion();
  });

  window.addEventListener("appinstalled", () => {
    // Hide the app-provided install promotion
    hideInstallPromotion();
    // Clear the deferredPrompt so it can be garbage collected
    deferredPrompt = null;
  });
}

// ios中的初始化方法
export function initInIos() {
  if (!getIsInPwa() && getIsIphoneSafari()) {
    // 非pwa且在safari中才做
    showInstallPromotion();
  }
}

// 总的初始化方法
export function initPwa() {
  if (checkPlatform("ios")) {
    initInIos();
  } else {
    initElse();
  }
}

export function showInstallPromotion() {
  // if (!showBannerPageTypes.includes(pageType)) return;
  if (installAppBanner) return;
  // 在同一会话下如果关闭过弹窗则不再弹
  try {
    const hasShow = sessionStorage.getItem(appInstallStorageKey);
    if (hasShow) return;
  } catch {}

  createInstallAppBanner();
  const installAppBtn = document.getElementById("app-install-btn");
  installAppBtn.onclick = async function () {
    if (checkPlatform("ios")) {
      Dialog.pwaGuidInIos();
    } else {
      deferredPrompt.prompt();
      try {
        const { outcome } = await deferredPrompt.userChoice;

        if (outcome === "accepted") {
          hideInstallPromotion(true);
          deferredPrompt = null;
        }

        // saTrack("pwa_install", {
        //   is_install: outcome === "accepted" ? 1 : 0,
        // });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const installAppCloseBtn = document.getElementById("app-install-close");

  installAppCloseBtn.onclick = function () {
    hideInstallPromotion(true);
  };
}

function createInstallAppBanner() {
  installAppBanner = document.createElement("section");
  installAppBanner.classList = "app-install-banner";
  const programIcon =
    window.__CONFIG__?.image_setting?.pure_logo_image?.image_url;
  const imgStr = programIcon
    ? `<img
  class="logo"
  src="${programIcon}"
  alt="logo"
  />`
    : "";
  installAppBanner.innerHTML = `
    <div id="app-install-close" class="app-install-close">
        <img
        class="close-icon"
        src="${closeIcon}"
        alt="close-app-banner"
        />
    </div>
    <div class="banner-content">
        ${imgStr}
        <div class="text">Please add shortcuts to get order progress and get voucher</div>
    </div>
    <div  id="app-install-btn" class="app-install-btn">Get it</div>
   `;

  document.body.append(installAppBanner);
}

export function hideInstallPromotion(hide) {
  if (installAppBanner) {
    installAppBanner.remove();
    installAppBanner = null;
  }
  if (hide) {
    sessionStorage.setItem(appInstallStorageKey, 1);
  }
}
