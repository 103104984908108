import { showToast as _showToast, closeToast } from "vant";

export function showToast(message, duration = 2000) {
  _showToast({
    message,
    wordBreak: "break-word",
    duration,
  });
}

export function hideToast() {
  closeToast();
}
