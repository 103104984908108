import _defineProperty from "/builds/qMu61Dfa/0/gcp-backend/tea-h5/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  class: "coupon-detail-desc"
};
var _hoisted_2 = {
  class: "section"
};
var _hoisted_3 = {
  class: "value"
};
var _hoisted_4 = {
  key: 0,
  class: "section"
};
var _hoisted_5 = {
  class: "value hightlight"
};
var _hoisted_6 = {
  key: 1,
  class: "section"
};
var _hoisted_7 = {
  class: "value"
};
var _hoisted_8 = {
  key: 2,
  class: "section"
};
var _hoisted_9 = {
  class: "label"
};
var _hoisted_10 = {
  class: "value"
};
var _hoisted_11 = {
  class: "section"
};
var _hoisted_12 = {
  class: "desc-list"
};
var _hoisted_13 = {
  key: 3,
  class: "section"
};
var _hoisted_14 = {
  class: "value"
};
var _hoisted_15 = {
  key: 4,
  class: "section"
};
var _hoisted_16 = {
  class: "value"
};
var _hoisted_17 = {
  class: "section"
};
var _hoisted_18 = {
  class: "desc-list"
};
var _hoisted_19 = {
  key: 5,
  class: "section"
};
import { priceUtil } from "@op/shared";
import { CouponResourceEnum, CouponStatusEnum, DiscountTypeEnum, CouponUseOrderType } from "@/constant/coupon";
import { OrderTypeLabelMap } from "@/constant/order";
import { formatPercent, formatDateWithTZ } from "@/utils/format";
import { isDef } from "@/utils/isUtil";
import { computed } from "vue";
export default {
  __name: 'CouponDetailDesc',
  props: {
    isMy: {
      type: Boolean,
      default: false
    },
    coupon: {
      type: [Object, null],
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var sourceText = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, CouponResourceEnum.NEWCOMER_ACTIVITY, "Get from newcomer bonus"), CouponResourceEnum.POINTS_MALL, "Get from redeem"), CouponResourceEnum.DIRECT, "Get from merchant"), CouponResourceEnum.SHARING_ACTIVITY, "Get from sharing activity"), CouponResourceEnum.SEND_COUPON_ACTIVITY, "Send coupon activity"), CouponResourceEnum.RANDOM_COUPON_ACTIVITY, "Random coupon activity"), CouponResourceEnum.BIRTHDAY_ACTIVITY, "Get from birthday activity");
    var isFixedCoupon = computed(function () {
      return props.coupon.coupon_info.discount_type === DiscountTypeEnum.FIXED;
    });
    var desc = computed(function () {
      var couponInfo = props.coupon.coupon_info;
      var discountRule = couponInfo.discount_rule;
      if (isFixedCoupon.value) {
        return "Enjoy ".concat(priceUtil.formatBaseUnitToShowPrice(discountRule.discount, discountRule.currency), " off your order!");
      }
      return "Enjoy ".concat(formatPercent(couponInfo.discount_rule.discount_percent), " off your order!");
    });
    var conditions = computed(function () {
      var couponInfo = props.coupon.coupon_info;
      var discountRule = couponInfo.discount_rule;
      var shopScope = couponInfo === null || couponInfo === void 0 ? void 0 : couponInfo.shop_scope;
      var dishScope = couponInfo === null || couponInfo === void 0 ? void 0 : couponInfo.dishes_scope;
      var usingRestriction = couponInfo === null || couponInfo === void 0 ? void 0 : couponInfo.using_restriction;
      var arr = [];
      // 有效期
      var usingTimeRule = couponInfo.using_time_rule;
      if ((usingTimeRule === null || usingTimeRule === void 0 ? void 0 : usingTimeRule.expired_interval) > 0) {
        arr.push("Valid for ".concat(usingTimeRule.expired_interval, " days upon receiving."));
      }
      // 叠加规则
      arr.push("Not applicable with other vouchers.");
      // 使用方式
      arr.push("Applicable for ".concat(couponInfo.order_types.map(function (t) {
        return OrderTypeLabelMap[t];
      }).join(","), "."));
      // 使用门槛
      if (discountRule.min_consumption) {
        arr.push("Minimum spend of ".concat(priceUtil.formatBaseUnitToShowPrice(discountRule.min_consumption, discountRule.currency), " is required."));
      }
      // 最高使用规则
      if (!isFixedCoupon.value) {
        arr.push("Maximum reduction ".concat(priceUtil.formatBaseUnitToShowPrice(discountRule.max_discount, discountRule.currency), "."));
      }
      // 适用门店范围
      if (!!shopScope) {
        var shopNameList = shopScope === null || shopScope === void 0 ? void 0 : shopScope.scope_names;
        // 适用所有店铺
        if ((shopScope === null || shopScope === void 0 ? void 0 : shopScope.scope_type) === "all") {
          arr.push("Store restrictions: apply to all stores.");
        } else if ((shopScope === null || shopScope === void 0 ? void 0 : shopScope.scope_type) === "exclude_shops") {
          // 排除某些店铺不可用
          var str = "";
          shopNameList.forEach(function (item, index) {
            str += item + "";
            if (index < shopNameList.length - 1) str += ",";
          });
          arr.push("Store restrictions: not apply to ".concat(str, "."));
        } else {
          // 某些店铺可用
          var _str = "";
          shopNameList.forEach(function (item, index) {
            _str += item + "";
            if (index < shopNameList.length - 1) _str += ",";
          });
          arr.push("Store restrictions: apply to ".concat(_str, "."));
        }
      }
      // 适用菜品范围
      if (!!dishScope) {
        var dishesNameList = dishScope === null || dishScope === void 0 ? void 0 : dishScope.scope_names;
        // 适用所有
        if ((dishScope === null || dishScope === void 0 ? void 0 : dishScope.scope_type) === "all") {
          arr.push("Products restrictions: apply to all products.");
        } else if ((dishScope === null || dishScope === void 0 ? void 0 : dishScope.scope_type) === "dishes") {
          // 某些菜品可用
          var _str2 = "";
          dishesNameList.forEach(function (item, index) {
            _str2 += item + "";
            if (index < dishesNameList.length - 1) _str2 += ",";
          });
          arr.push("Products restrictions: apply to product(".concat(_str2, ")."));
        } else {
          // 某些分类可用
          var _str3 = "";
          dishesNameList.forEach(function (item, index) {
            _str3 += item + "";
            if (index < dishesNameList.length - 1) _str3 += ",";
          });
          arr.push("Products restrictions: apply to category(".concat(_str3, ")."));
        }
      }
      // 是否只可兑换一次
      if (props.coupon.limit_repurchase) {
        arr.push("This voucher can only be redeemed once.");
      }
      if (usingRestriction && usingRestriction.once_a_day) {
        arr.push("Usage restriction: only one can be used per day");
      }
      if (usingRestriction && usingRestriction.week_days) {
        arr.push(" Date restriction: only available on ".concat(usingRestriction.week_days.join(",")));
      }
      return arr;
    });
    var remarks = computed(function () {
      return ["Applicable Products or Services: Valid at the time of checkout.", "Quantity Limits: One coupon per transaction.", "Cannot be Combined with Other Offers: Not valid in conjunction with other promotions.", "Non-Refundable or Redeemable: Non-refundable and cannot be redeemed for cash.", "Term Modifications: We reserve the right to modify the terms, but customers will be notified before any changes.", "Abuse Provision: We reserve the right to cancel or refuse the use of the coupon if any misuse is detected."];
    });
    var diffDay = function diffDay(endTime) {
      return Math.ceil((endTime - Date.now()) / (24 * 60 * 60 * 1000));
    };
    var genValidTimeTip = function genValidTimeTip() {
      if (props.coupon.status === CouponStatusEnum.INEFFECTIVE) {
        return "Effective the next day";
      }
      if (props.coupon.status === CouponStatusEnum.UNUSED) {
        var days = diffDay(props.coupon.end_time);
        if (days <= 3) {
          return " Expires in ".concat(days, " days");
        }
      }
      return "";
    };
    var isShowVerfitype = computed(function () {
      var couponInfo = props.coupon.coupon_info;
      if ((couponInfo.order_types.includes(CouponUseOrderType.DINE_IN) || couponInfo.order_types.includes(CouponUseOrderType.TAKEAWAY)) && !couponInfo.order_types.includes(CouponUseOrderType.SELF_DELIVERY) && !couponInfo.order_types.includes(CouponUseOrderType.SELF_PICK_UP)) {
        return true;
      }
      return false;
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("section", _hoisted_2, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "label"
      }, "Description", -1)), _createElementVNode("div", _hoisted_3, _toDisplayString(desc.value), 1)]), _unref(isDef)(__props.coupon.coupon_info.discount_rule.min_consumption) ? (_openBlock(), _createElementBlock("section", _hoisted_4, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "label"
      }, "Minimum spend", -1)), _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(priceUtil).formatBaseUnitToShowPrice(__props.coupon.coupon_info.discount_rule.min_consumption, __props.coupon.coupon_info.discount_rule.currency)), 1)])) : _createCommentVNode("", true), __props.coupon.obtain_time ? (_openBlock(), _createElementBlock("section", _hoisted_6, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "label"
      }, "Received date", -1)), _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(formatDateWithTZ)(__props.coupon.obtain_time, "YYYY-MM-DD")), 1)])) : _createCommentVNode("", true), __props.coupon.end_time ? (_openBlock(), _createElementBlock("section", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_cache[3] || (_cache[3] = _createTextVNode(" Valid period ")), genValidTimeTip() ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass(["tip", __props.coupon.status])
      }, "(" + _toDisplayString(genValidTimeTip()) + ")", 3)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(formatDateWithTZ)(__props.coupon.start_time, "YYYY-MM-DD HH:mm:ss")) + " ~ " + _toDisplayString(_unref(formatDateWithTZ)(__props.coupon.end_time, "YYYY-MM-DD HH:mm:ss")), 1)])) : _createCommentVNode("", true), _createElementVNode("section", _hoisted_11, [_cache[5] || (_cache[5] = _createElementVNode("div", {
        class: "label"
      }, "Terms and conditions", -1)), _createElementVNode("div", _hoisted_12, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(conditions.value, function (text) {
        return _openBlock(), _createElementBlock("div", {
          key: text,
          class: "desc-item"
        }, [_cache[4] || (_cache[4] = _createElementVNode("div", {
          class: "dot"
        }, "•", -1)), _createElementVNode("div", null, _toDisplayString(text), 1)]);
      }), 128))])]), __props.coupon.used_time ? (_openBlock(), _createElementBlock("section", _hoisted_13, [_cache[6] || (_cache[6] = _createElementVNode("div", {
        class: "label"
      }, "Used time", -1)), _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(formatDateWithTZ)(__props.coupon.used_time, "YYYY-MM-DD HH:mm:ss")), 1)])) : _createCommentVNode("", true), __props.coupon.obtain_source ? (_openBlock(), _createElementBlock("section", _hoisted_15, [_cache[7] || (_cache[7] = _createElementVNode("div", {
        class: "label"
      }, "Coupon source", -1)), _createElementVNode("div", _hoisted_16, [_createTextVNode(_toDisplayString(sourceText[__props.coupon.obtain_source]) + " ", 1), _createElementVNode("p", null, _toDisplayString(_unref(formatDateWithTZ)(__props.coupon.obtain_time, "YYYY-MM-DD HH:mm:ss")), 1)])])) : _createCommentVNode("", true), _createElementVNode("section", _hoisted_17, [_cache[9] || (_cache[9] = _createElementVNode("div", {
        class: "label"
      }, "Remarks", -1)), _createElementVNode("div", _hoisted_18, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(remarks.value, function (text) {
        return _openBlock(), _createElementBlock("div", {
          key: text,
          class: "desc-item"
        }, [_cache[8] || (_cache[8] = _createElementVNode("div", {
          class: "dot"
        }, "•", -1)), _createElementVNode("div", null, _toDisplayString(text), 1)]);
      }), 128))])]), isShowVerfitype.value ? (_openBlock(), _createElementBlock("section", _hoisted_19, _cache[10] || (_cache[10] = [_createElementVNode("div", {
        class: "label"
      }, "Usage restrictions", -1), _createElementVNode("div", {
        class: "value"
      }, "This voucher can only be used in store", -1)]))) : _createCommentVNode("", true)]);
    };
  }
};