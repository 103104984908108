import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
export default {
  __name: 'PoweredBy',
  props: {
    isList: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return !_ctx.isAgentChannel ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["powered-by", {
          'is-list': __props.isList
        }])
      }, null, 2)) : _createCommentVNode("", true);
    };
  }
};