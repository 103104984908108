import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "comp-preparing-info"
};
var _hoisted_2 = {
  class: "highlight"
};
var _hoisted_3 = {
  class: "highlight"
};
import { computed, watch, onMounted } from "vue";
import { useShopStore } from "@/store/shopStore";
export default {
  __name: 'PreparingInfo',
  setup: function setup(__props) {
    var shopStore = useShopStore();
    var waitOrderCount = computed(function () {
      return shopStore.getWaitOrderCount;
    });
    var waitDishesCount = computed(function () {
      return shopStore.getWaitDishesCount;
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[0] || (_cache[0] = _createTextVNode(" Preparing ")), _createElementVNode("span", _hoisted_2, _toDisplayString(waitOrderCount.value), 1), _cache[1] || (_cache[1] = _createTextVNode(" orders / ")), _createElementVNode("span", _hoisted_3, _toDisplayString(waitDishesCount.value), 1), _cache[2] || (_cache[2] = _createTextVNode(" cups "))]);
    };
  }
};