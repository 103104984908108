import { ErrorType } from "./type";
import { formatDateWithTZ } from "@/utils/format";
import { useShopStoreWithOut } from "@/store/shopStore";
import { useSupplierStoreWithOut } from "@/store/supplierStore";
import { isArray, isString } from "@/utils/isUtil";
import { filterErrorReportRules, warnedErrorRules } from "./config";

// 当入参是数组时，合并errorMsg，其余字段取最后一项的。针对console.error可能有多个入参的情况
export function formatErrorDetail(e) {
  let errorDetail;
  if (isArray(e)) {
    errorDetail = e.reduce((cal, cur) => {
      const curItemErrorDetail = getErrorDetail(cur);
      curItemErrorDetail.errorMsg =
        (cal.errorMsg ? cal.errorMsg + " " : "") + curItemErrorDetail.errorMsg;
      return curItemErrorDetail;
    }, {});
  } else {
    errorDetail = getErrorDetail(e);
  }

  if (errorDetail) {
    checkIfWarnedError(errorDetail);
  }

  return errorDetail;
}

export function getErrorDetail(e) {
  let errorDetail;

  if (e instanceof Error) {
    // console.error、vue errorHandler捕获到的Error类型错误
    errorDetail = {
      level: "error",
      type: ErrorType.Sync,
      ...parseError(e),
    };
  } else if (e instanceof ErrorEvent) {
    // addEventListener('error')捕获到的同步错误
    errorDetail = {
      level: "error",
      type: ErrorType.Sync,
      ...(e.error
        ? parseError(e.error)
        : {
            errorMsg: e.message,
            resourceUrl: e.filename,
            lineNo: e.lineno,
            colNo: e.colno,
          }),
    };
  } else if (e instanceof PromiseRejectionEvent) {
    // addEventListener('unhandledrejection')
    errorDetail = {
      level: "error",
      type: ErrorType.Promise,
      ...parseError(e.reason),
    };
  } else if (e instanceof Event) {
    if (e.target instanceof WebSocket) {
      // console.error捕获到的websocket错误
      errorDetail = {
        level: "error",
        type: ErrorType.Websocket,
        errorMsg: "websocket异常",
        ...parseWebsocketError(e),
      };
    } else {
      // addEventListener('error')捕获到的资源加载错误
      errorDetail = {
        level: "error",
        type: ErrorType.Resource,
        errorMsg: "资源加载异常",
        ...parseResourceError(e),
      };
    }
  } else {
    // console.error、vue errorHandler捕获到的非Error类型错误
    errorDetail = {
      level: "error",
      type: ErrorType.Sync,
      errorMsg: isString(e) ? e : JSON.stringify(e),
    };
  }

  return errorDetail;
}

export function getErrorReport(errorDetail) {
  const supplierStore = useSupplierStoreWithOut();
  const shopStore = useShopStoreWithOut();

  const reportData = {
    time: formatDateWithTZ(Date.now()),
    // projectVersion: __APP_INFO__.pkg.version,
    // projectName: "tea-h5",
    country: shopStore.getCountry,
    supplierId: supplierStore.getSupplierId,
    supplierName: supplierStore.getSupplierName,
    shopId: shopStore.getShopId,
    shopName: shopStore.getShopName,
    pageUrl: location.href,
    userAgent: window.navigator.userAgent,
    ...errorDetail,
  };

  // 过滤报告
  const isValid = validErrorReport(reportData);
  if (!isValid) {
    return null;
  }

  // 暂时过滤掉warning级别错误
  if (reportData.level === "warning") {
    return null;
  }

  // if (errorDetail.lineNo && errorDetail.colNo) {
  //   reportData.lineAndCol = `${errorDetail.lineNo}/${errorDetail.colNo}`;
  // }

  const reportFields = [
    { key: "time", label: "时间" },
    { key: "projectName", label: "项目名称" },
    { key: "projectVersion", label: "版本号" },
    { key: "country", label: "国家" },
    { key: "supplierId", label: "公司id" },
    { key: "supplierName", label: "公司名称" },
    { key: "shopId", label: "店铺id" },
    { key: "shopName", label: "店铺名称" },
    { key: "pageUrl", label: "Page url" },
    { key: "type", label: "错误类型" },
    // { key: 'resourceUrl', label: 'Resource url' },
    // { key: 'lineAndCol', label: '行/列' },
    { key: "tag", label: "标签名" },
    { key: "linkUrl", label: "Link url" },
    { key: "wsUrl", label: "Connect url" },
    { key: "userAgent", label: "UserAgent", isBlockquotes: true },
    { key: "errorMsg", label: "错误信息", isBlockquotes: true },
    { key: "stack", label: "错误栈", isBlockquotes: true },
  ];

  // const reportText = reportFields
  //   .map((item) => {
  //     if (reportData[item.key]) {
  //       return `#### ${item.label}${item.isBlockquotes ? "\n >" : ": "}${
  //         reportData[item.key]
  //       }`;
  //     } else {
  //       return "";
  //     }
  //   })
  //   .filter(Boolean)
  //   .join("\n ");

  // return {
  //   title: "TEA H5错误通知",
  //   text: reportText,
  //   level: reportData.level,
  // };
  return reportFields.reduce((prev, cur) => {
    if (reportData[cur.key]) {
      prev[cur.key] = reportData[cur.key];
      return prev;
    } else {
      return prev;
    }
  }, {});
}

// 将正常业务错误转换为warning级别错误
function checkIfWarnedError(errorDetail) {
  if (warnedErrorRules.some((rule) => rule.handler(errorDetail))) {
    errorDetail.level = "warning";
  }
}

// 过滤错误报告
function validErrorReport(reportData) {
  return filterErrorReportRules.every((rule) => rule.handler(reportData));
}

export function parseError(e) {
  if (e instanceof Error) {
    const { message, stack } = e;
    const { filename, lineno, colno } = parseStack(stack || "");
    const detail = {
      errorMsg: message,
      resourceUrl: filename,
      lineNo: lineno,
      colNo: colno,
      stack,
    };
    return detail;
  } else {
    return {
      errorMsg: isString(e) ? e : JSON.stringify(e),
    };
  }
}

export function parseWebsocketError(e) {
  const { target } = e;
  const detail = {
    wsUrl: target.url,
  };
  return detail;
}

export function parseResourceError(e) {
  const { target } = e;
  const detail = {
    tag: target.nodeName,
    linkUrl: target.src || target.href,
  };
  return detail;
}

// 从stack中解析出filename、lineno、colno
export function parseStack(stack) {
  try {
    const results = stack.split("\n");
    const result1SplitedParts = results[1].trim().split(" ");
    const topFile = result1SplitedParts[result1SplitedParts.length - 1];
    const regResults = topFile.match(/\(?(.*)?\:(\d+)\:(\d+)\)?$/) || [];
    const [, filename, lineno, colno] = regResults;

    return {
      filename,
      lineno: Number(lineno),
      colno: Number(colno),
    };
  } catch (e) {
    return {
      filename: undefined,
      lineno: undefined,
      colno: undefined,
    };
  }
}
