import { genUid } from "@/utils/tools";
import { isArray, isNumber } from "@/utils/isUtil";
import { CouponAvailableOrderTypeMap } from "@/constant/order";
import { useShopStoreWithOut } from "@/store/shopStore";
import { createOrderDishesItems as createOrderDishesItemsFromShared } from "@op/shared";

// 将用户的优惠券转换为下单时可选择的优惠券列表
export function calcSelectCouponList({
  userCouponList,
  grandTotal,
  deliveryMethod,
  cartList,
}) {
  if (
    !isArray(userCouponList) ||
    !isNumber(grandTotal) ||
    !deliveryMethod ||
    !isArray(cartList)
  ) {
    return [];
  }

  let res = [];
  res = userCouponList
    .map((couponItem) => {
      let cannotUse = false;
      const cannotUseReasonList = [];

      // 服务端判断当前优惠券是否可用
      if (couponItem.is_unusable) {
        cannotUse = true;
        cannotUseReasonList.push(couponItem.unusable_reason);
      }

      // grandTotal小于优惠券可使用的最低消费
      if (grandTotal < couponItem.coupon.discount_rule.min_consumption) {
        cannotUse = true;
        cannotUseReasonList.push("min_consumption");
      }
      // deliveryMethod不在优惠券支持的订单类型内
      if (
        !couponItem.coupon.order_types.includes(
          CouponAvailableOrderTypeMap[deliveryMethod]
        )
      ) {
        cannotUse = true;
        cannotUseReasonList.push("order_types");
      }
      // 不在优惠券可用的店铺内
      if (
        couponItem.coupon.shop_scope?.scope_type &&
        couponItem.coupon.shop_scope.scope_type !== "all"
      ) {
        const shopStore = useShopStoreWithOut();
        const shopId = shopStore.getShopId;
        if (couponItem.coupon.shop_scope.scope_type === "shops") {
          const scopeIds = couponItem.coupon.shop_scope.scope_ids || [];
          if (!scopeIds.includes(shopId)) {
            cannotUse = true;
            cannotUseReasonList.push("shops");
          }
        } else if (
          couponItem.coupon.shop_scope.scope_type === "exclude_shops"
        ) {
          const scopeIds = couponItem.coupon.shop_scope.scope_ids || [];
          if (scopeIds.includes(shopId)) {
            cannotUse = true;
            cannotUseReasonList.push("exclude_shops");
          }
        }
      }
      // cartList不包含可用的菜品分类、菜品
      if (
        couponItem.coupon.dishes_scope?.scope_type &&
        couponItem.coupon.dishes_scope.scope_type !== "all"
      ) {
        if (couponItem.coupon.dishes_scope.scope_type === "category") {
          const shopStore = useShopStoreWithOut();
          const dishesCategory = shopStore.getDishesCategory;
          // 构建菜品id - 菜品分类id映射
          const dishesIdCategoryIdMap = {};
          dishesCategory.forEach((cateItem) => {
            cateItem.dishes_list?.forEach((dishItem) => {
              if (dishesIdCategoryIdMap[dishItem.id]) {
                if (
                  !dishesIdCategoryIdMap[dishItem.id].includes(
                    cateItem.category_id
                  )
                ) {
                  dishesIdCategoryIdMap[dishItem.id].push(cateItem.category_id);
                }
              } else {
                dishesIdCategoryIdMap[dishItem.id] = [cateItem.category_id];
              }
            });
          });
          const scopeIds = couponItem.coupon.dishes_scope.scope_ids || [];
          const canUseFlag = cartList.find((cartItem) => {
            const cateIdList = dishesIdCategoryIdMap[cartItem.id] || [];
            return cateIdList.find((cateId) => scopeIds.includes(cateId));
          });
          if (!canUseFlag) {
            cannotUse = true;
            cannotUseReasonList.push("dishes_category_scope");
          }
        } else if (couponItem.coupon.dishes_scope.scope_type === "dishes") {
          const scopeIds = couponItem.coupon.dishes_scope.scope_ids || [];
          const canUseFlag = cartList.find((cartItem) =>
            scopeIds.includes(cartItem.id)
          );
          if (!canUseFlag) {
            cannotUse = true;
            cannotUseReasonList.push("dishes_scope");
          }
        }
      }

      return {
        ...couponItem,
        cannot_use: cannotUse,
        cannot_use_reason_list: cannotUseReasonList,
      };
    })
    .sort((a, b) => {
      if (a.cannot_use === b.cannot_use) {
        return a.end_time - b.end_time;
      } else {
        return a.cannot_use - b.cannot_use;
      }
    });

  return res;
}

// 将购物车菜品转化为创建订单使用的菜品结构
export function createOrderDishesItems(cartList) {
  return createOrderDishesItemsFromShared(cartList);
}
