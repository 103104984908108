import { defineStore } from "pinia";
import store from "./index";
import {
  getUserAddressList,
  addUserAddress,
  deleteUserAddress,
  updateUserAddress,
  setDefaultToAddress,
} from "@/api/address";
import { getSupplyId, getUserId, getCustomerId } from "@/utils/authUtil";

export const useAddressStore = defineStore({
  id: "address",
  state: () => {
    return {
      addressList: [],
      selectedId: "", // 初始'',代表不选中
      notifySuccessText: "",
    };
  },
  getters: {
    getAddressList(state) {
      return state.addressList;
    },
    getSelectId(state) {
      return state.selectedId;
    },
    getSelectedAddress(state) {
      return state.addressList.find((item) => item.id === state.selectedId);
    },
    getNotifySuccessText(state) {
      return state.notifySuccessText;
    },
  },
  actions: {
    async loadAddressList() {
      // 获取用户地址列表时可能还没有获取crm用户id，故使用associate_user_id
      const res = await getUserAddressList({
        associate_user_id: getUserId(),
        supplier_id: getSupplyId(),
      });
      if (res.isOK) {
        const addressList = res.data?.delivery_address || [];
        this.addressList = addressList;
        const selectAddress = addressList.find((item) => item.is_default);
        selectAddress && (this.selectedId = selectAddress.id);
      }
    },
    addAddress(deliveryAddress) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await addUserAddress({
            delivery_address: [deliveryAddress],
            user_id: getCustomerId(),
          });
          if (res.isOK) {
            await this.loadAddressList();
            this.setNotifySuccessText("Delivery address successfully saved");
            resolve();
          } else {
            reject();
          }
        } catch (err) {
          reject(err);
        }
      });
    },
    updateAddress(deliveryAddress) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await updateUserAddress({
            delivery_address: deliveryAddress,
            user_id: getCustomerId(),
          });
          if (res.isOK) {
            await this.loadAddressList();
            this.setNotifySuccessText("Delivery address successfully saved");
            resolve();
          } else {
            reject();
          }
        } catch (err) {
          reject(err);
        }
      });
    },
    deleteAddress(id) {
      return new Promise(async (resolve, reject) => {
        try {
          const targetAddress = this.addressList.find((item) => item.id === id);
          if (targetAddress) {
            const res = await deleteUserAddress({
              user_id: getCustomerId(),
              address_ids: [targetAddress.id],
            });
            if (res.isOK) {
              await this.loadAddressList();

              if (id === this.selectedId) {
                // 当前所选地址就是删除者,直接重置成''
                this.selectedId = "";
              }

              resolve();
            } else {
              reject();
            }
          } else {
            reject();
          }
        } catch (err) {
          reject(err);
        }
      });
    },
    selectAddress(id) {
      return new Promise(async (resolve, reject) => {
        try {
          const targetAddress = this.addressList.find((item) => item.id === id);
          if (targetAddress) {
            const res = await setDefaultToAddress({
              user_id: getCustomerId(),
              address_id: targetAddress.id,
            });
            if (res.isOK) {
              await this.loadAddressList();
              this.selectedId = id;
              resolve();
            } else {
              reject();
            }
          }
        } catch (err) {
          reject(err);
        }
      });
    },
    setNotifySuccessText(val) {
      this.notifySuccessText = val;
    },
  },
  // persist: {
  //   enable: true,
  //   key: "ADDRESS",
  //   includes: ["selectedId"],
  // },
});

export function useAddressStoreWithOut() {
  return useAddressStore(store);
}
