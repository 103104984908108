import { defineStore } from "pinia";
import store from "./index";

export const useOrderListStore = defineStore({
  id: "orderList",
  state: () => {
    return {
      tab: "current",
      orders: [],
      pageNum: 1,
      finished: false,
    };
  },
  getters: {
    getTab(state) {
      return state.tab;
    },
    getOrders(state) {
      return state.orders;
    },
    getPageNum(state) {
      return state.pageNum;
    },
    getFinished(state) {
      return state.finished;
    },
  },
  actions: {
    setTab(val) {
      this.tab = val;
    },
    setOrders(val) {
      this.orders = val;
    },
    setPageNum(val) {
      this.pageNum = val;
    },
    setFinished(val) {
      this.finished = val;
    },
    resetState() {
      this.setOrders([]);
      this.setPageNum(1);
      this.setFinished(false);
    },
  },
});

export function useOrderListStoreWithOut() {
  return useOrderListStore(store);
}
