import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
import Image from "../base/Image.vue";
export default {
  __name: 'BusinessTrigger',
  props: {
    businessTrigger: {
      type: Object,
      default: null
    }
  },
  setup: function setup(__props) {
    var props = __props;
    function handleClick() {
      var _props$businessTrigge;
      if (props.businessTrigger.is_redirect && (_props$businessTrigge = props.businessTrigger.redirect_url) !== null && _props$businessTrigge !== void 0 && _props$businessTrigge[0]) {
        location.href = props.businessTrigger.redirect_url[0];
      }
    }
    return function (_ctx, _cache) {
      var _props$businessTrigg;
      return (_props$businessTrigg = __props.businessTrigger) !== null && _props$businessTrigg !== void 0 && _props$businessTrigg.is_open ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "business-trigger-wrap",
        onClick: handleClick
      }, [__props.businessTrigger.image ? (_openBlock(), _createBlock(Image, {
        key: 0,
        src: __props.businessTrigger.image,
        class: "business-trigger-img",
        "oss-stylename": "type_27"
      }, null, 8, ["src"])) : _createCommentVNode("", true)])) : _createCommentVNode("", true);
    };
  }
};