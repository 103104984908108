import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.push.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue";
var _hoisted_1 = {
  class: "text-ctn"
};
var _hoisted_2 = {
  class: "name"
};
var _hoisted_3 = {
  key: 0,
  class: "desc"
};
var _hoisted_4 = {
  class: "red"
};
var _hoisted_5 = {
  class: "red"
};
var _hoisted_6 = {
  class: "address"
};
var _hoisted_7 = {
  key: 1,
  class: "distance"
};
var _hoisted_8 = {
  class: "business"
};
var _hoisted_9 = {
  key: 0,
  class: "time"
};
var _hoisted_10 = {
  key: 1,
  class: "method"
};
var _hoisted_11 = ["src"];
import { formatLocation, formateTodayBusinessTime, formatDistance, formatBusinessHoursWithTZ } from "@/utils/format";
import { computed } from "vue";
export default {
  __name: 'ShopItem',
  props: {
    shop: {
      type: Object,
      required: true
    },
    isLike: {
      type: Boolean,
      default: false
    }
  },
  emits: ["choose", "like"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emits = __emit;
    var methodStr = computed(function () {
      var _props$shop$self_deli, _props$shop$self_pick;
      var methodList = [];
      if ((_props$shop$self_deli = props.shop.self_delivery_setting) !== null && _props$shop$self_deli !== void 0 && _props$shop$self_deli.is_accept) {
        methodList.push("Delivery");
      }
      if ((_props$shop$self_pick = props.shop.self_pickup_setting) !== null && _props$shop$self_pick !== void 0 && _props$shop$self_pick.is_accept) {
        methodList.push("Pick up");
      }
      return methodList.join(" / ");
    });
    var handleChoose = function handleChoose() {
      emits("choose", props.shop);
    };
    var handleLike = function handleLike() {
      emits("like", !props.isLike);
    };
    return function (_ctx, _cache) {
      var _props$shop$timezone;
      return _openBlock(), _createElementBlock("div", {
        class: "shop-item-ctn",
        onClick: handleChoose
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(__props.shop.shop_name), 1), __props.shop.wait_info && __props.shop.wait_info.order_count > 0 && __props.shop.wait_info.dishes_count > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("span", null, "Preparing", -1)), _createElementVNode("span", _hoisted_4, _toDisplayString(__props.shop.wait_info.order_count), 1), _cache[1] || (_cache[1] = _createElementVNode("span", {
        class: "bold"
      }, "orders", -1)), _cache[2] || (_cache[2] = _createElementVNode("span", null, "/", -1)), _createElementVNode("span", _hoisted_5, _toDisplayString(__props.shop.wait_info.dishes_count), 1), _cache[3] || (_cache[3] = _createElementVNode("span", {
        class: "bold"
      }, "cups", -1))])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(formatLocation)(__props.shop.location)), 1), __props.shop.distance ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_unref(formatDistance)(__props.shop.distance)), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", {
        class: _normalizeClass(["status", ["status-".concat(__props.shop.is_open ? 'open' : 'close')]])
      }, _toDisplayString(__props.shop.is_open ? "Open" : "Close"), 3), __props.shop.business_time ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_unref(formateTodayBusinessTime)(_unref(formatBusinessHoursWithTZ)(__props.shop.business_time, (_props$shop$timezone = __props.shop.timezone_setting) === null || _props$shop$timezone === void 0 ? void 0 : _props$shop$timezone.timezone))), 1)) : _createCommentVNode("", true), methodStr.value ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(methodStr.value), 1)) : _createCommentVNode("", true)])]), _createElementVNode("div", {
        class: "like-ctn",
        onClick: _withModifiers(handleLike, ["stop"])
      }, [_createElementVNode("img", {
        src: require("@/assets/images/icons/like_".concat(__props.isLike ? 1 : 0, ".svg"))
      }, null, 8, _hoisted_11)])]);
    };
  }
};