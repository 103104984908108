import { defineStore } from "pinia";
import store from "./index";
import { OrderTypeEnum } from "@/constant/order";
import { getUserAsset } from "@/api/user";
import { thirdPlatformLogin } from "@/api/user";
import { getSupplyId, setToken, setUserId } from "@/utils/authUtil";
import { useShopStoreWithOut } from "./shopStore";
import { hideLoading, showLoading } from "@/utils/loadingUtil";
import { afterLoginInit } from "@/utils/initUtil";
import GoogleMapHelper from "@/utils/googleMapHelper";
import { showToast } from "@/utils/toastUtil";

export const useUserStore = defineStore({
  id: "user",
  state: () => {
    return {
      likeShopIdList: [],
      user: {
        customer_id: "",
        nickname: "",
        telephone: "",
        email: "",
        avatar: "",
        username: "",
        birthday: "",
      },
      asset: {
        currency: "",
        wallet: 0,
        reward_balance: 0, // 用户资产奖励余额
        self_balance: 0, // 用户资产个人余额
        points: 0,
        vouchers: 0,
      },
      orderType: OrderTypeEnum.SELF_PICK_UP, // 默认时自提
      customerInputName: "", // 自提下单填写的名称
      customerInputMobile: "", // 自提下单填写的电话
      coordinate: null, // 用户当前的地理定位
    };
  },
  getters: {
    getLikeShopIdList(state) {
      return state.likeShopIdList;
    },
    getUserInfo(state) {
      return state.user;
    },
    getPoints(state) {
      return state.asset?.points || 0;
    },
    getVouchers(state) {
      return state.asset?.vouchers || 0;
    },
    getWallet(state) {
      return state.asset?.wallet || 0;
    },
    getRewardBalance(state) {
      return state.asset?.reward_balance || 0;
    },
    getSelfBalance(state) {
      return state.asset?.self_balance || 0;
    },
    getWalletCurrency(state) {
      return state.asset?.currency || 0;
    },
    getOrderType(state) {
      return state.orderType;
    },
    getCustomerInputName(state) {
      return state.customerInputName;
    },
    getCustomerInputMobile(state) {
      return state.customerInputMobile;
    },
    getCoordinate(state) {
      return state.coordinate;
    },
    getCrmUserId(state) {
      return state.user.customer_id;
    },
  },
  actions: {
    async thirdLogin(data) {
      const shopStore = useShopStoreWithOut();
      showLoading();
      // 服务端登录
      const res = await thirdPlatformLogin({
        third_platform: data.third_platform,
        supplier_id: getSupplyId(),
        token: data.token,
        shop_id: shopStore.getShopId,
      });
      if (res.isOK) {
        setToken(res.data.token);
        setUserId(res.data.user_id);
        await afterLoginInit();
        hideLoading();
      } else {
        hideLoading();
      }
    },
    async setUserAsset() {
      const res = await getUserAsset();
      if (res.isOK) {
        this.asset = res.data;
      }
    },
    setPoints(points) {
      this.asset.points = points;
    },
    setUserInfo(arg) {
      this.user = arg;
      if (!this.customerInputName && arg.nickname) {
        this.customerInputName = arg.nickname;
        this.customerInputMobile = arg.telephone;
      }
    },
    setLikeShopIdList(arg) {
      this.likeShopIdList = arg;
    },
    setOrderType(arg) {
      this.orderType = arg;
    },
    addShopIdListToLike(id) {
      this.likeShopIdList.push(id);
    },
    deleteShopIdFromLike(id) {
      const targetIdx = this.likeShopIdList.findIndex((item) => item === id);
      if (targetIdx > -1) {
        this.likeShopIdList.splice(targetIdx, 1);
      }
    },
    setCustomerInputName(val) {
      this.customerInputName = val;
    },
    setCustomerInputMobile(val) {
      this.customerInputMobile = val;
    },
    setCoordinate(val) {
      this.coordinate = val;
    },
    async initCoordinate() {
      const mapHelper = new GoogleMapHelper();
      await new Promise((resolve) => {
        mapHelper.initMap(undefined, {}, (e) => {
          if (e) {
            showToast("Load google map service error");
          }
          resolve();
        });
      });
      const getCurPosRes = await mapHelper.getCurrentPositionByH5();
      if (getCurPosRes.isOK && getCurPosRes.data) {
        this.setCoordinate(getCurPosRes.data);
      }
    },
  },
  persist: {
    enable: true,
    key: "USER",
    includes: [
      "likeShopIdList",
      "orderType",
      "customerInputName",
      "customerInputMobile",
    ],
  },
});

export function useUserStoreWithOut() {
  return useUserStore(store);
}
