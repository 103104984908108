import { useSupplierStoreWithOut } from "@/store/supplierStore";
import { useUserStoreWithOut } from "@/store/userStore";
import { useShopStoreWithOut } from "@/store/shopStore";
import { Persistent } from "./cache/persistent";

const TOKEN_KEY = "__TOKEN__";
const USER_ID_KEY = "__USER_ID__";

export function isLogin() {
  return !!getToken() && !!getUserId();
}

export function getTestSupplierId() {
  return Persistent.getLocal("__TEST_ID__");
}

export function getSupplyId() {
  const supplierStore = useSupplierStoreWithOut();
  return supplierStore.getSupplierId;
}

export function getUserId() {
  return Persistent.getLocal(USER_ID_KEY);
}

export function setUserId(userId) {
  return Persistent.setLocal(USER_ID_KEY, userId);
}

export function getToken() {
  return Persistent.getLocal(TOKEN_KEY);
}

export function setToken(token) {
  return Persistent.setLocal(TOKEN_KEY, token);
}

export function clearAuthInfo() {
  Persistent.removeLocal(USER_ID_KEY);
  Persistent.removeLocal(TOKEN_KEY);
}

export function getCustomerId() {
  const userStore = useUserStoreWithOut();
  return userStore.getCrmUserId;
}

export function getCountry() {
  const shopStore = useShopStoreWithOut();
  return shopStore.getCountry;
}
