import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "rewards"
};
import RewardPoints from "@/components/reward/RewardPoints.vue";
import RewardRedeemList from "@/components/reward/RewardRedeemList.vue";
import { useUserStore } from "@/store/userStore";
import { useRouter } from "@/hooks/useRouter";
import { onActivated } from "vue";
export default {
  __name: 'RewardsVIew',
  setup: function setup(__props) {
    var userStore = useUserStore();
    var _useRouter = useRouter(),
      routerPush = _useRouter.routerPush;
    var onBuySuccess = function onBuySuccess() {
      setTimeout(function () {
        routerPush({
          name: "MyCouponList"
        });
      }, 0);
    };
    onActivated(function () {
      userStore.setUserAsset();
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(RewardPoints), _createVNode(RewardRedeemList, {
        onSuccess: onBuySuccess
      })]);
    };
  }
};