import { createApp } from "vue";
import LoginPopup from "@/components/login/LoginPopup.vue";

export class Login {
  // 实例记录
  static instance = null;

  // 调用方法
  static open(options) {
    const parent = document.createElement("div");
    document.body.appendChild(parent);

    if (Login.instance) {
      try {
        Login.instance.unmount();
      } catch (_) {
        Login.instance = null;
      }
    }
    const app = createApp(LoginPopup, {
      show: true,
      ...options,
    });
    app.mount(parent);
    Login.instance = app;
  }

  static close() {
    if (Login.instance) {
      try {
        Login.instance.unmount();
      } catch (_) {
        Login.instance = null;
      }
    }
  }
}
