import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from "vue";
import { Field } from "vant";
export default {
  __name: 'Input',
  props: {
    selfType: {
      type: String,
      // default、borderBottom、borderTextarea
      default: "default"
    }
  },
  setup: function setup(__props, _ref) {
    var __expose = _ref.expose;
    var selfInput = ref();
    __expose({
      focus: function focus() {
        var _selfInput$value;
        (_selfInput$value = selfInput.value) === null || _selfInput$value === void 0 || _selfInput$value.focus();
      }
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["self-input", {
          'self-input-border-bottom': __props.selfType === 'borderBottom',
          'self-input-border-textarea': __props.selfType === 'borderTextarea'
        }])
      }, [_createVNode(_unref(Field), _mergeProps({
        ref_key: "selfInput",
        ref: selfInput,
        "label-align": "top"
      }, _ctx.$attrs), null, 16)], 2);
    };
  }
};