import { formatErrorDetail, getErrorReport } from "./error/handler";
import { WebTrackTopic } from "./type";

class WebTracker {
  constructor(options) {
    this.app = options.app;
    this.sendLogUrl = `https://${process.env.VUE_APP_WEB_TRACKER_PROJECT}.${process.env.VUE_APP_WEB_TRACKER_ENDPOINT}/logstores/${process.env.VUE_APP_WEB_TRACKER_LOGSTORE}/track`;
    this.sendErrorReportUrl = `${process.env.VUE_APP_WEB_TRACKER_REPORT_API_URL}/h5/api/v1/common/dingding`;
    this.apiversion = "0.6.0";
    this.source = "tea-h5";

    this.initErrorReport();
  }

  // 自定义行为监控
  event(topic, log) {
    this._sendLog({
      topic,
      log,
    });
  }

  // 上传单条日志
  _sendLog(data) {
    this._request({
      method: "POST",
      url: this.sendLogUrl,
      data: JSON.stringify({
        __source__: this.source,
        __topic__: data.topic,
        __logs__: [data.log],
      }),
      headers: {
        "x-log-apiversion": this.apiversion,
      },
    });
  }

  // 初始化错误监控
  initErrorReport() {
    // 改写console.error
    const __consoleError = window.console.error;
    window.__consoleError = __consoleError;
    window.console.error = (...args) => {
      this.sendErrorReport(args);
      __consoleError.apply(window.console, args);
    };

    // 监听vue errorHandler捕获到的错误
    this.app.config.errorHandler = (err) => {
      window.__consoleError("[vue errorHandler catch:]", err);
      this.sendErrorReport(err);
    };

    // 监听同步错误、资源加载错误
    window.addEventListener(
      "error",
      (e) => {
        this.sendErrorReport(e);
      },
      true
    );

    // 监听Promise Uncaught错误
    window.addEventListener(
      "unhandledrejection",
      (e) => {
        this.sendErrorReport(e);
      },
      true
    );
  }

  // 上传错误报告
  sendErrorReport(e) {
    try {
      const errorDetail = formatErrorDetail(e);
      const errorReport = getErrorReport(errorDetail);

      if (errorReport) {
        // this._request({
        //   method: "POST",
        //   url: this.sendErrorReportUrl,
        //   data: JSON.stringify(errorReport),
        // });
        this.event(WebTrackTopic.ERROR_LOG, errorReport);
      }
    } catch (e) {
      console.warn("[sendErrorReport error]:", e);
    }
  }

  _request(options) {
    if (process.env.NODE_ENV === "development") return;
    return new Promise((resolve) => {
      if (options.method === "POST") {
        const xhr = new XMLHttpRequest();

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            resolve(xhr.response);
          }
        };

        xhr.open("POST", options.url, true);
        const optionsHeaders = options.headers || {};
        const headers = {
          "Content-Type": "application/json",
          ...optionsHeaders,
        };
        Object.keys(headers).forEach((headerKey) => {
          xhr.setRequestHeader(headerKey, headers[headerKey]);
        });
        xhr.send(options.data);
      }
    });
  }
}

export default WebTracker;
