import {
  getSupplyId,
  getUserId,
  getCountry,
  getCustomerId,
} from "@/utils/authUtil";
import { post, get } from "@/utils/requestUtil";
import { Persistent } from "@/utils/cache/persistent";
export function getUserDetail() {
  return post(`/crm/customer/customer/c/v1/customer_info/get`, {
    user_id: getUserId(),
    company_id: getSupplyId(),
  });
}

export function getUserAsset() {
  const userId = getUserId();
  return post(
    `/crm/v1/user_asset/get`,
    {
      user_id: userId,
      supplier_id: getSupplyId(),
    },
    {
      apiSecret: process.env.VUE_APP_CRM_API_SECRET,
    }
  );
}

export function getPointHistory(params) {
  const userId = getUserId();
  return post(
    `/crm/v1/user_asset/points_history`,
    {
      user_id: userId,
      supplier_id: getSupplyId(),
      ...params,
    },
    {
      apiSecret: process.env.VUE_APP_CRM_API_SECRET,
    }
  );
}

// 第三方平台登录
export function mobileLogin(params) {
  return post("/crm/customer/customer/c/v1/auth/mobile_login", params);
}

export function validCode(params) {
  return post(
    "/crm/customer/customer/c/v1/auth/verification_code/verify",
    params
  );
}

export function changePassword(params) {
  return post("/crm/customer/customer/c/v1/auth/change_password", params);
}

export function sendCode(params) {
  return post(
    `/crm/customer/customer/c/v1/auth/verification_code/send`,
    params
  );
}

export function mobileRegister(params) {
  return post("/crm/customer/customer/c/v1/auth/mobile_reg", params);
}

// 第三方平台登录
export function thirdPlatformLogin(params) {
  return post("/user/v1/h5_login/by_external", params);
}

// 第三方平台登录-跳转地址
export function getThirdPlatformUrl(params) {
  return post("user/v2/callback_addr", params);
}

// 苹果token解析
export function getVerifyIdToken(params) {
  return post("https://op-test.disoo.co/api/verifyIdToken", params);
}

// 第三方登录配置
export function getThirdLoginConfig(params) {
  const key = "h5_third_platform_login_type";
  if (Persistent.getLocal(key)) {
    return Persistent.getLocal(key);
  }
  const promiseRes = post("/config/list", {
    key,
    ...params,
  });
  Persistent.setLocal(key, promiseRes);
  return promiseRes;
}

export function checkScanQrcode(crm_user_id) {
  return get(
    `/crm/v1/crm_user/check_scan_qrcode/${crm_user_id}`,
    {},
    {
      apiSecret: process.env.VUE_APP_CRM_API_SECRET,
    }
  );
}

/**
 * @param {*} 查询顾客余额流水
 */
export function queryUserBalanceHistory(params) {
  const userId = getUserId();
  return post(
    "/crm/v1/user_asset/balance_history",
    {
      user_id: userId,
      supplier_id: getSupplyId(),
      ...params,
    },
    {
      apiSecret: process.env.VUE_APP_CRM_API_SECRET,
    }
  );
}

/**
 * @param {*} 查询充值规则列表
 */
export function queryRechargeRuleList(req) {
  return post(
    "/crm/customer/top_up/c/v1/top_up_rule/list",
    {
      company_id: getSupplyId(),
      shop_id: req.shop_id,
    },
    {
      apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
    }
  );
}

/**
 * @param {*} 获取充值配置
 */
export function getRechargeSetting() {
  return get(
    `/crm/customer/top_up/c/v1/top_up_setting/company/${getSupplyId()}`,
    {},
    {
      apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
    }
  );
}

/**
 * @param {*} 创建充值单
 */
export function createRechargeOrder(params) {
  const userId = getUserId();
  return post(
    "/crm/customer/top_up/c/v1/create",
    {
      customer_id: getCustomerId(),
      company_id: getSupplyId(),
      country: getCountry(),
      ...params,
    },
    {
      apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
    }
  );
}

/**
 * @param {*} 充值单在线支付创建接口
 */
export function createRechargeOnlinePayment(params) {
  return post("/crm/customer/top_up/c/v1/payment/online/create", params, {
    apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
  });
}

/**
 * @param {*} 查询充值结果
 */
export function checkRechargeOrder(params) {
  return post("/crm/customer/top_up/c/v1/get_result", params, {
    apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
  });
}

export function updateUserInfo(params) {
  return post(`/crm/customer/customer/c/v1/customer_info/update`, params, {
    apiSecret: process.env.VUE_APP_CRM_API_SECRET,
  });
}
