import "core-js/modules/es.array.slice.js";
import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "base-count-down"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({
    d: _ctx.days,
    h: _ctx.hours,
    m: _ctx.mins,
    s: _ctx.seconds,
    hh: "00".concat(_ctx.hours).slice(-2),
    mm: "00".concat(_ctx.mins).slice(-2),
    ss: "00".concat(_ctx.seconds).slice(-2)
  })))]);
}