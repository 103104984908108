import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/points/point_icon.png';
var _hoisted_1 = {
  class: "reward-points"
};
var _hoisted_2 = {
  class: "pts"
};
import { useUserStore } from "@/store/userStore.js";
export default {
  __name: 'RewardPoints',
  setup: function setup(__props) {
    var userStore = useUserStore();
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "title"
      }, "Rewards Center", -1)), _createElementVNode("div", _hoisted_2, [_cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_0,
        class: "rewards-pts-img"
      }, null, -1)), _createTextVNode(" " + _toDisplayString(_unref(userStore).getPoints) + " ", 1), _cache[1] || (_cache[1] = _createElementVNode("span", {
        class: "pts"
      }, "pts", -1))])]);
    };
  }
};