import { getSupplyId, getUserId, getCustomerId } from "@/utils/authUtil";
import { post, get } from "@/utils/requestUtil";
import { genUid } from "@/utils/tools";

export function getPointsProduct(params) {
  params.supplier_id = getSupplyId();
  // params.user_id = getUserId();

  return post("/crm/marketing/points_mall/v1/product/query", params, {
    apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
  });
}

export function getMyProduct(params) {
  params.supplier_id = getSupplyId();
  params.user_id = getUserId();
  return post("/crm/marketing/points_mall/v1/user/my_product", params, {
    apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
  });
}

export function buyProduct(params) {
  params.supplier_id = getSupplyId();
  params.user_id = getUserId();
  params.seq_number = genUid();
  return post("/crm/marketing/points_mall/v1/sale/buy_product", params, {
    apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
  });
}

export function getUserCouponList(params) {
  return post("/crm/marketing/coupon/v1/user_coupon/list", params, {
    apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
  });
}

export function getExpiringPoints(params) {
  params.supplier_id = getSupplyId();
  return post(
    "/crm/marketing/activity/v1/points_activity/get_my_expiring_points",
    params,
    {
      apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
    }
  );
}

export function getBusinessTrigger(params) {
  return get(
    "o2oms/merchant/v1/c/supplier_business_trigger/" + params.supplier_id,
    params
  );
}

// 获取新人礼活动
export function getNewcomerActivity(params) {
  const supplier_id = getSupplyId();
  return post(
    `/crm/marketing/activity/v2/newcomer_activity/${supplier_id}?activity_id=${params.activity_id}`,
    {},
    {
      apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
    }
  );
}

// 参与新人礼活动
export function getNewcomerParticipate(params) {
  params.company_id = getSupplyId();
  return post(
    `/crm/marketing/activity/v1/newcomer_activity/user_participate`,
    params,
    {
      apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
    }
  );
}

// 参与新人礼活动
export function checkNewcomerParticipate(params) {
  params.company_id = getSupplyId();
  return post(
    `/crm/marketing/activity/v1/newcomer_activity/user_participate/check`,
    params,
    {
      apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
    }
  );
}

// 查看积分商品详情
export function getPointProductDetail(params) {
  params.customer_id = getCustomerId();
  return post(
    `/crm/marketing/points_mall/v1/c/product/product_detail`,
    params,
    {
      apiSecret: process.env.VUE_APP_CRM_MARKETING_API_SECRET,
    }
  );
}
