import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from '@/assets/images/activity/sharing_go_to_use.png';
var _hoisted_1 = {
  class: "share-coupon-info"
};
var _hoisted_2 = {
  class: "tips"
};
var _hoisted_3 = {
  key: 0,
  class: "use-tips"
};
var _hoisted_4 = {
  class: "go-to-use-btn"
};
import CouponPrice from "./CouponPrice.vue";
import ShareButton from "./ShareButton.vue";
export default {
  __name: 'ShareCouponInfo',
  props: {
    title: {
      type: String,
      default: ""
    },
    useTips: {
      type: String,
      default: ""
    },
    buttonText: {
      type: String,
      default: ""
    },
    discountType: {
      type: String,
      default: ""
    },
    discountRule: {
      type: Object,
      default: function _default() {}
    }
  },
  emits: ["click-button"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var emits = __emit;
    function handleClickButton() {
      emits("click-button");
    }
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_0,
        class: "share-coupon-info-bg"
      }, null, -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, _toDisplayString(__props.title), 1)]), _createElementVNode("div", {
        class: _normalizeClass(["coupon-info", {
          'coupon-info--usetips': __props.useTips
        }])
      }, [_createVNode(CouponPrice, {
        "discount-type": __props.discountType,
        "discount-rule": __props.discountRule
      }, null, 8, ["discount-type", "discount-rule"]), __props.useTips ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(__props.useTips), 1)) : _createCommentVNode("", true)], 2), _createElementVNode("div", _hoisted_4, [_createVNode(ShareButton, {
        text: __props.buttonText,
        onClick: handleClickButton
      }, null, 8, ["text"])])]);
    };
  }
};