import _objectSpread from "/builds/qMu61Dfa/0/gcp-backend/tea-h5/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { computed } from "vue";
export default {
  __name: 'Option',
  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    gap: {
      type: Number,
      default: 0
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emits = __emit;
    var style = computed(function () {
      return _objectSpread({}, props.gap ? {
        "margin-top": "".concat(props.gap, "px"),
        "margin-right": "".concat(props.gap, "px")
      } : {});
    });
    function handleClick() {
      if (!props.isDisabled) {
        emits("click");
      }
    }
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["comp-option", {
          'comp-option--selected': __props.isSelected,
          'comp-option--disabled': __props.isDisabled
        }]),
        style: _normalizeStyle(style.value),
        onClick: handleClick
      }, [_renderSlot(_ctx.$slots, "default")], 6);
    };
  }
};