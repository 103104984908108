import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/login/mobile.png';
var _hoisted_1 = {
  class: "mobile-button-wrap"
};
import { ref } from "vue";
import MoblieLoginPopup from "./mobile/MoblieLoginPopup.vue";
import { PopupPageKey } from "@/constant/pageKey";
export default {
  __name: 'MobileSignInButton',
  emits: ["success"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var show = ref(false);
    var emit = __emit;
    var toMobleView = function toMobleView() {
      show.value = true;
    };
    var onSuccess = function onSuccess() {
      emit("success");
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "mobile-sign-in-button",
        onClick: toMobleView
      }, _cache[1] || (_cache[1] = [_createElementVNode("img", {
        class: "button-wrap-icon",
        src: _imports_0
      }, null, -1), _createTextVNode("Continue with Mobile ")])), _createVNode(MoblieLoginPopup, {
        show: show.value,
        "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
          return show.value = $event;
        }),
        "page-key": _unref(PopupPageKey).MOBILE_LOGIN,
        onSuccess: onSuccess
      }, null, 8, ["show", "page-key"])]);
    };
  }
};