import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "left-icon-wrap"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = {
  class: "notice-content"
};
var _hoisted_4 = {
  key: 1,
  class: "right-icon-wrap"
};
import { computed } from "vue";
import SvgIcon from "../base/SvgIcon.vue";
import WarnIcon from "@/assets/images/icons/icon_warn.svg";
export default {
  __name: 'NoticeBar',
  props: {
    type: {
      type: String,
      default: "warn"
    },
    mode: {
      type: String,
      default: "" // 通知模式，可选closeable、link
    },
    text: {
      type: String,
      default: ""
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var leftIconMap = {
      warn: WarnIcon
    };
    var leftIcon = computed(function () {
      return leftIconMap[props.type] || "";
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["comp-notice-bar", {
          'comp-notice-bar--warn': __props.type === 'warn'
        }])
      }, [leftIcon.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
        class: "left-icon",
        src: leftIcon.value,
        alt: ""
      }, null, 8, _hoisted_2)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, _toDisplayString(__props.text), 1), __props.mode ? (_openBlock(), _createElementBlock("div", _hoisted_4, [__props.mode === 'link' ? (_openBlock(), _createBlock(SvgIcon, {
        key: 0,
        name: "arrow_right",
        class: "right-icon"
      })) : _createCommentVNode("", true)])) : _createCommentVNode("", true)], 2);
    };
  }
};