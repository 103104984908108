export const OrderTypeEnum = {
  SELF_PICK_UP: "self_pick_up",
  SELF_DELIVERY: "self_delivery",
};

export const OrderStatusEnum = {
  TO_PAY: "to_pay",
  TO_ACCEPT: "to_accept",
  PREPARING: "preparing",
  READY: "ready",
  COMPLETED: "completed",
  OUT_OF_DELIVERY: "out_of_delivery",
  CANCELLED: "cancelled",
  TO_DELIVER_CANCELLED: "to_deliver_cancelled",
  DELIVERING_CANCELLED: "delivering_cancelled",
  READY_CANCELLED: "ready_cancelled",
  REJECT_CANCELLED: "reject_cancelled",
  REFUNDED: "refunded",
};

export const OrderTypeLabelMap = {
  "dine-in": "Dine-in",
  takeaway: "Takeaway",
  "self-pickup": "Self-Pick up",
  "self-delivery": "Self-delivery",
};

export const CouponAvailableOrderTypeMap = {
  [OrderTypeEnum.SELF_PICK_UP]: "self-pickup",
  [OrderTypeEnum.SELF_DELIVERY]: "self-delivery",
};

export const paymentMethodMap = {
  cash_on_delivery: "Cash on delivery",
  online_payment: "Online Payment",
  online: "Online Payment",
  balance: "Balance",
};
