import "core-js/modules/es.number.constructor.js";
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/images/activity/coupon.png';
var _hoisted_1 = {
  class: "giftDialog"
};
var _hoisted_2 = {
  class: "gift_content"
};
var _hoisted_3 = {
  key: 0,
  class: "gift_content_price"
};
var _hoisted_4 = {
  class: "gift_content_price_left"
};
var _hoisted_5 = {
  class: "gift_content_price_right"
};
var _hoisted_6 = {
  key: 1,
  class: "gift_content_precent"
};
var _hoisted_7 = {
  class: "gift_content_precent_left"
};
var _hoisted_8 = {
  class: "gift_rule"
};
var _hoisted_9 = {
  key: 0,
  class: "detail_title"
};
var _hoisted_10 = {
  key: 1,
  class: "detail_desc"
};
var _hoisted_11 = {
  key: 2,
  class: "detail_desc"
};
import { ref, computed } from "vue";
import { priceUtil } from "@op/shared";
import { DiscountTypeEnum } from "@/constant/coupon";
import { formatPercent, formatDateWithTZ } from "@/utils/format";
export default {
  __name: 'NewComerDialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    companyName: {
      type: String,
      default: ""
    },
    endTime: {
      type: Number,
      default: 0
    }
  },
  emits: ["update:show", "close"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emits = __emit;
    var handleOk = function handleOk() {
      emits("update:show", false);
    };
    var handleClose = function handleClose() {
      emits("close");
    };
    var desc = computed(function () {
      var _props$detail;
      if (props !== null && props !== void 0 && props.detail && (_props$detail = props.detail) !== null && _props$detail !== void 0 && _props$detail.discount_rule) {
        var couponInfo = props.detail;
        var discountRule = couponInfo.discount_rule;
        if (couponInfo.discount_type === DiscountTypeEnum.FIXED) {
          return "Enjoy ".concat(priceUtil.formatBaseUnitToShowPrice(discountRule.discount, discountRule.currency), " off your order!");
        } else {
          return "Enjoy ".concat(formatPercent(couponInfo.discount_rule.discount_percent), " off your order!");
        }
      } else {
        return "";
      }
    });
    return function (_ctx, _cache) {
      var _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createBlock(_component_van_popup, {
        show: __props.show,
        style: {
          "height": "500px",
          "border-radius": "12px",
          "background": "none"
        }
      }, {
        default: _withCtx(function () {
          var _props$detail2, _props$detail3, _props$detail4, _props$detail5, _props$detail6, _props$detail7, _props$detail8;
          return [_createElementVNode("div", _hoisted_1, [_cache[1] || (_cache[1] = _createElementVNode("img", {
            src: _imports_0,
            class: "img_style"
          }, null, -1)), _createElementVNode("div", _hoisted_2, [__props.detail && ((_props$detail2 = __props.detail) === null || _props$detail2 === void 0 ? void 0 : _props$detail2.discount_type) === _unref(DiscountTypeEnum).FIXED ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString((_props$detail3 = __props.detail) === null || _props$detail3 === void 0 ? void 0 : _props$detail3.discount_rule.currency), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(priceUtil).formatBaseUnitToShowPrice((_props$detail4 = __props.detail) === null || _props$detail4 === void 0 ? void 0 : _props$detail4.discount_rule.discount, (_props$detail5 = __props.detail) === null || _props$detail5 === void 0 ? void 0 : _props$detail5.discount_rule.currency, false)), 1)])) : ((_props$detail6 = __props.detail) === null || _props$detail6 === void 0 ? void 0 : _props$detail6.discount_type) !== _unref(DiscountTypeEnum).FIXED ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(((_props$detail7 = __props.detail) === null || _props$detail7 === void 0 || (_props$detail7 = _props$detail7.discount_rule) === null || _props$detail7 === void 0 ? void 0 : _props$detail7.discount_percent) * 100), 1), _cache[0] || (_cache[0] = _createElementVNode("div", {
            class: "gift_content_precent_right"
          }, [_createElementVNode("div", {
            class: "precent"
          }, "%"), _createElementVNode("div", {
            class: "off"
          }, "OFF")], -1))])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_8, [(_props$detail8 = __props.detail) !== null && _props$detail8 !== void 0 && _props$detail8.title ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(__props.detail.title), 1)) : _createCommentVNode("", true), desc.value ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(desc.value), 1)) : _createCommentVNode("", true), __props.endTime ? (_openBlock(), _createElementBlock("div", _hoisted_11, " Valid Until " + _toDisplayString(_unref(formatDateWithTZ)(__props.endTime, "YYYY-MM-DD")), 1)) : _createCommentVNode("", true)]), _cache[2] || (_cache[2] = _createElementVNode("div", {
            class: "gift_footer"
          }, "Thanks for being our Top Customer !", -1)), _createElementVNode("div", {
            class: "gift_btn",
            onClick: handleOk
          })])];
        }),
        _: 1
      }, 8, ["show"]);
    };
  }
};