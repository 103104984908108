import { DISH_STATUS } from "@/constant/dishes";
import { genUid } from "@/utils/tools";
import {
  genUniSelectedKey as genUniSelectedKeyFromShared,
  calcDishTagList as calcDishTagListFromShared,
  getDefaultDishSelectedInfo as getDefaultDishSelectedInfoFromShared,
  calcDishPrice as calcDishPriceFromShared,
} from "@op/shared";

const dishPriorityMap = {
  [DISH_STATUS.IN_STOCK]: 0,
  [DISH_STATUS.OUT_STOCK]: 1,
};

// 整理菜品列表，过滤掉所有菜品都下架的分类，没库存的菜品排在分类后面
export function solveDishesCategoryList(dishesCategoryList) {
  const dishesCategoryFiltered =
    dishesCategoryList.reduce((prev, cur) => {
      const dishes_list = cur.dishes_list
        .filter((dish) => dish.stock_status !== DISH_STATUS.DELISTED)
        .sort(
          (a, b) =>
            dishPriorityMap[a.stock_status] - dishPriorityMap[b.stock_status]
        );
      if (dishes_list.length > 0) {
        prev.push({
          category_id: cur.category_id,
          name: cur.name,
          show_status: cur.show_status,
          dishes_list: solveDish(dishes_list),
          img: cur.img,
        });
      }
      return prev;
    }, []) || [];

  return dishesCategoryFiltered;
}

// 菜品列表的每一项整理菜品数据结构，过滤掉菜品的下架的sku、额外选项、下架的额外需要
export function solveDish(dishes_list) {
  return dishes_list.map((dish) => {
    let extraOptionList = [];
    if (dish?.extra_option_list?.length) {
      extraOptionList = dish?.extra_option_list.reduce((prevOpt, curOpt) => {
        const valuedValues = curOpt.values.filter(
          (val) => val.stock_status !== DISH_STATUS.DELISTED
        );
        if (valuedValues.length) {
          prevOpt.push({
            ...curOpt,
            values: valuedValues,
          });
        }
        return prevOpt;
      }, []);
    }
    let extraNeedingList = [];
    if (dish?.extra_needing_list?.length) {
      extraNeedingList = dish?.extra_needing_list.reduce(
        (prevNeeding, curNeeding) => {
          const valuedValues = curNeeding.values.filter(
            (val) => val.stock_status !== DISH_STATUS.DELISTED
          );
          if (valuedValues.length) {
            prevNeeding.push({
              ...curNeeding,
              values: valuedValues,
            });
          }
          return prevNeeding;
        },
        []
      );
    }
    let extraList = [];
    if (dish?.modifier_add_ons_list?.length) {
      extraList = dish?.modifier_add_ons_list.reduce((prevExtra, curExtra) => {
        const valuedValues = curExtra.values.filter(
          (val) => val.stock_status !== DISH_STATUS.DELISTED
        );
        if (valuedValues.length) {
          prevExtra.push({
            ...curExtra,
            values: valuedValues,
          });
        }
        return prevExtra;
      }, []);
    }
    return {
      ...dish,
      sku_list: dish.sku_list.filter(
        (sku) => sku.stock_status !== DISH_STATUS.DELISTED
      ),
      extra_option_list: extraOptionList,
      extra_needing_list: extraNeedingList,
      modifier_add_ons_list: extraList,
    };
  });
}

// 菜品列表的每一项获取默认规格，便以展示价格及加菜操作
export function getDefaultSku(dishesCategory) {
  let dishesCategoryWithDefaultSku = dishesCategory;
  dishesCategoryWithDefaultSku = dishesCategoryWithDefaultSku.map((cate) => {
    const tempDishesList = [];
    cate.dishes_list.forEach((dish) => {
      let selecetedSkuId = "";
      dish?.sku_list.forEach((sku) => {
        if (sku.stock_status === DISH_STATUS.IN_STOCK) {
          // if (!selecetedSkuId) selecetedSkuId = sku.id;
          if (sku.spec?.is_default) selecetedSkuId = sku.id;
        }
      });
      tempDishesList.push({
        ...dish,
        selecetedSkuId,
      });
    });
    cate.dishes_list = tempDishesList;
    return cate;
  });

  return dishesCategoryWithDefaultSku;
}

// 根据默认规格，获取菜品默认选中的额外选项、额外加料
export function getDefaultDishSelectedInfo(dish) {
  return getDefaultDishSelectedInfoFromShared(dish);
}

// 根据菜品选择信息，计算菜品金额（菜品单价加上额外加料的总单价）
export function calcDishPrice(dish) {
  return calcDishPriceFromShared(dish);
}

// 把菜品结构转换为购物车元素的结构
export function createAddDishItem(dish) {
  const selectedInfo = getDefaultDishSelectedInfo(dish);
  const shoppingCartItem = {
    ...dish,
    selectedPrice: calcDishPrice(dish),
    UUKey: genUid(),
    selectedInfo,
    num: 1,
  };
  return shoppingCartItem;
}

// 根据菜品选择信息，计算菜品的tagList
export function calcDishTagList(dish) {
  return calcDishTagListFromShared(dish, false);
}

// 根据菜品选择信息，计算购物车元素的唯一id
export function genUniSelectedKey(dish) {
  return genUniSelectedKeyFromShared(dish);
}
