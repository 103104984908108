import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.parse-int.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "coupon-price-fixed"
};
var _hoisted_2 = {
  key: 1,
  class: "coupon-price-percent"
};
import { computed } from "vue";
import { DiscountTypeEnum } from "@/constant/coupon";
import { formatCouponReward } from "@/utils/format";
import { PriceStrCurrencySymbolEnum } from "@/constant/currency";
export default {
  __name: 'CouponPrice',
  props: {
    discountType: {
      type: String,
      default: ""
    },
    discountRule: {
      type: Object,
      default: function _default() {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var fixedData = computed(function () {
      if (props.discountType === DiscountTypeEnum.FIXED && props.discountRule) {
        return formatCouponReward(props.discountType, props.discountRule).split(" ").map(function (item) {
          return isNaN(Number(item.replace(",", "."))) ? item : parseInt(item.replace(",", "."));
        });
      } else {
        return [];
      }
    });
    var isFixedData0PriceSymbol = computed(function () {
      var _props$discountRule, _props$discountRule2;
      return fixedData.value[0] === (PriceStrCurrencySymbolEnum[(_props$discountRule = props.discountRule) === null || _props$discountRule === void 0 ? void 0 : _props$discountRule.currency] || ((_props$discountRule2 = props.discountRule) === null || _props$discountRule2 === void 0 ? void 0 : _props$discountRule2.currency)).replace(/\s/, "");
    });
    var isFixedData1PriceSymbol = computed(function () {
      var _props$discountRule3, _props$discountRule4;
      return fixedData.value[1] === (PriceStrCurrencySymbolEnum[(_props$discountRule3 = props.discountRule) === null || _props$discountRule3 === void 0 ? void 0 : _props$discountRule3.currency] || ((_props$discountRule4 = props.discountRule) === null || _props$discountRule4 === void 0 ? void 0 : _props$discountRule4.currency)).replace(/\s/, "");
    });
    var percentData = computed(function () {
      if (props.discountType === DiscountTypeEnum.PERCENT && props.discountRule) {
        return formatCouponReward(props.discountType, props.discountRule).replace("%", "");
      } else {
        return "";
      }
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["coupon-price", {
          'coupon-price--disabled': __props.disabled
        }])
      }, [__props.discountType === _unref(DiscountTypeEnum).FIXED ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: _normalizeClass({
          'price-symbol': isFixedData0PriceSymbol.value
        })
      }, _toDisplayString(fixedData.value[0]), 3), _cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "price-symbol"
      }, " ", -1)), _createElementVNode("div", {
        class: _normalizeClass({
          'price-symbol': isFixedData1PriceSymbol.value
        })
      }, _toDisplayString(fixedData.value[1]), 3)])) : __props.discountType === _unref(DiscountTypeEnum).PERCENT ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", null, _toDisplayString(percentData.value), 1), _cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "off"
      }, [_createElementVNode("div", null, "%"), _createElementVNode("div", null, "OFF")], -1))])) : _createCommentVNode("", true)], 2);
    };
  }
};