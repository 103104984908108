import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.parse-int.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/images/activity/no_coupon.png';
var _hoisted_1 = {
  class: "wrap"
};
var _hoisted_2 = {
  class: "content"
};
var _hoisted_3 = {
  class: "title"
};
var _hoisted_4 = {
  class: "coupon"
};
var _hoisted_5 = {
  key: 0,
  class: "have-coupon"
};
var _hoisted_6 = {
  class: "coupon-content"
};
var _hoisted_7 = {
  class: "coupon-title"
};
var _hoisted_8 = {
  class: "coupon-price"
};
var _hoisted_9 = {
  class: "coupon-valid"
};
var _hoisted_10 = {
  key: 1,
  class: "no-coupon"
};
import { computed } from "vue";
import { Popup } from "vant";
import ShareButton from "./ShareButton.vue";
import { formatCouponReward, formatCouponValidDays } from "@/utils/format";
export default {
  __name: 'GiftBgDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    buttonText: {
      type: String,
      default: ""
    },
    coupon: {
      type: Object,
      default: undefined
    },
    closeOnClickOverlay: {
      type: Boolean,
      default: true
    }
  },
  emits: ["update:visible", "click-button"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emits = __emit;
    var show = computed({
      get: function get() {
        return props.visible;
      },
      set: function set(val) {
        emits("update:visible", val);
      }
    });
    var couponReward = computed(function () {
      return props.coupon ? formatCouponReward(props.coupon.discount_type, props.coupon.discount_rule).split(" ").map(function (item) {
        return isNaN(Number(item.replace(",", "."))) ? item : parseInt(item.replace(",", "."));
      }).join(" ") : "";
    });
    var couponValidDays = computed(function () {
      return "Validity period: Valid within ".concat(props.coupon ? formatCouponValidDays(props.coupon.using_time_rule) : 0, " days");
    });
    function handleClickButton() {
      emits("click-button");
    }
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(_unref(Popup), {
        show: show.value,
        "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
          return show.value = $event;
        }),
        style: {
          background: 'unset',
          width: '3.11rem',
          height: '3.78rem'
        },
        "close-on-click-overlay": __props.closeOnClickOverlay
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(__props.title), 1), _createElementVNode("div", _hoisted_4, [__props.coupon ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(__props.coupon.title), 1), _createElementVNode("div", _hoisted_8, _toDisplayString(couponReward.value), 1)]), _createElementVNode("div", _hoisted_9, _toDisplayString(couponValidDays.value), 1)])) : (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[1] || (_cache[1] = [_createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1)])))]), _createVNode(ShareButton, {
            class: "share-button",
            text: __props.buttonText,
            height: 70,
            onClick: handleClickButton
          }, null, 8, ["text"])])])];
        }),
        _: 1
      }, 8, ["show", "close-on-click-overlay"]);
    };
  }
};