import "core-js/modules/es.number.constructor.js";
export default {
  props: {
    time: {
      type: [Number, String],
      default: 0
    },
    isMillisecond: {
      type: Boolean,
      default: false
    },
    isOnlySecond: {
      type: Boolean,
      default: false
    }
  },
  emits: ["end"],
  data: function data() {
    return {
      days: "0",
      hours: "00",
      mins: "00",
      seconds: "00",
      timer: null,
      curTime: 0
    };
  },
  computed: {
    duration: function duration() {
      return this.isMillisecond ? Math.round(+this.time / 1000) : Math.round(+this.time);
    }
  },
  watch: {
    duration: function duration() {
      this.countDown();
    }
  },
  mounted: function mounted() {
    this.countDown();
  },
  methods: {
    durationFormatter: function durationFormatter(time) {
      if (!time) return {
        ss: 0
      };
      if (this.isOnlySecond) return {
        ss: time
      };
      var t = time;
      var ss = t % 60;
      t = (t - ss) / 60;
      if (t < 1) return {
        ss: ss
      };
      var mm = t % 60;
      t = (t - mm) / 60;
      if (t < 1) return {
        mm: mm,
        ss: ss
      };
      var hh = t % 24;
      t = (t - hh) / 24;
      if (t < 1) return {
        hh: hh,
        mm: mm,
        ss: ss
      };
      var dd = t;
      return {
        dd: dd,
        hh: hh,
        mm: mm,
        ss: ss
      };
    },
    countDown: function countDown() {
      this.curTime = Date.now();
      this.getTime(this.duration);
    },
    getTime: function getTime(time) {
      var _this = this;
      this.timer && clearTimeout(this.timer);
      if (time < 0) {
        this.$emit("end");
        return;
      }
      var _this$durationFormatt = this.durationFormatter(time),
        dd = _this$durationFormatt.dd,
        hh = _this$durationFormatt.hh,
        mm = _this$durationFormatt.mm,
        ss = _this$durationFormatt.ss;
      this.days = dd || 0;
      this.hours = hh || 0;
      this.mins = mm || 0;
      this.seconds = ss || 0;
      this.timer = setTimeout(function () {
        var now = Date.now();
        var diffTime = Math.floor((now - _this.curTime) / 1000);
        var step = diffTime > 1 ? diffTime : 1; // 页面退到后台的时候不会计时，对比时间差，大于1s的重置倒计时
        _this.curTime = now;
        _this.getTime(time - step);
      }, 1000);
    }
  }
};