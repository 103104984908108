import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "app-wrapper"
};
var _hoisted_2 = {
  class: "app-main"
};
export function render(_ctx, _cache) {
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_router_view, null, {
    default: _withCtx(function (_ref) {
      var Component = _ref.Component,
        route = _ref.route;
      return [route.meta.keepAlive ? (_openBlock(), _createBlock(_KeepAlive, {
        key: 0
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024)) : (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        key: 1
      }))];
    }),
    _: 1
  })])]);
}