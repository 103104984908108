import GoogleMapHelper from "@/utils/googleMapHelper";
import { useSupplierStoreWithOut } from "@/store/supplierStore";
import { useAddressStoreWithOut } from "@/store/addressStore";
import { useUserStoreWithOut } from "@/store/userStore";
import { useShopStoreWithOut } from "@/store/shopStore";
// import { isOverOpenTime } from "@/utils/tools";
import { OrderTypeEnum } from "@/constant/order";
import { ShopStatusEnum, ShopOpenStatusEnum } from "@/constant/shop";

export const useAddress = () => {
  const supplierStore = useSupplierStoreWithOut();
  const addressStore = useAddressStoreWithOut();
  const userStore = useUserStoreWithOut();
  const shopStore = useShopStoreWithOut();

  let mapHelper;
  mapHelper = new GoogleMapHelper();

  /**
   * 计算距离最近且可用的店铺
   * @param coordinate {latitude,longitude}形式,若传入为等效false,则视为无可用定位,直接返回列表中第一个可用的店铺
   * @param searchType OrderTypeEnum,自提还是自配送
   */
  const getNearestAvailableShop = async (coordinate, searchType) => {
    let shopList = JSON.parse(JSON.stringify(supplierStore.getShopList));
    if (coordinate) {
      // 如果传入了coordinate,需要对shopList进行排序
      shopList.sort((a, b) => {
        const aDistance = mapHelper.computeDistanceBetween(
          { lat: coordinate.latitude, lng: coordinate.longitude },
          {
            lat: a.location.coordinate.latitude,
            lng: a.location.coordinate.longitude,
          }
        );
        const bDistance = mapHelper.computeDistanceBetween(
          { lat: coordinate.latitude, lng: coordinate.longitude },
          {
            lat: b.location.coordinate.latitude,
            lng: b.location.coordinate.longitude,
          }
        );
        return aDistance - bDistance;
      });
    }
    let result = false;
    let tmp = null;
    for (let i = 0; i < shopList.length; i++) {
      const curShop = shopList[i];
      // 根据自配送还是自提判断配置中is_accept是否开启,否则跳过

      if (searchType === OrderTypeEnum.SELF_DELIVERY) {
        // 是否在配送范围内
        const polygon = (
          curShop.self_delivery_setting?.delivery_area || []
        ).map((item) => {
          return { lat: item.latitude, lng: item.longitude };
        });
        const isInRange = mapHelper.containsLocation(
          { lat: coordinate.latitude, lng: coordinate.longitude },
          polygon
        );
        if (!isInRange) {
          continue;
        }
      }

      // 都没有问题则可选择该商店
      curShop.shopStatus = ShopStatusEnum.OPEN;
      if (
        !tmp &&
        ((searchType === OrderTypeEnum.SELF_PICK_UP &&
          !curShop.self_pickup_setting?.is_accept) ||
          (searchType === OrderTypeEnum.SELF_DELIVERY &&
            !curShop.self_delivery_setting.is_accept))
      ) {
        tmp = curShop;
        continue;
      }

      // 非营业状态或不在营业时间的跳过
      if (!tmp && curShop.status === ShopOpenStatusEnum.CLOSED) {
        tmp = curShop;
        continue;
      }

      result = curShop;

      break;
    }

    if (!result && tmp) {
      result = tmp;
    }

    return result;
  };

  // 自提情况获取店铺
  const selfPickUpGetShop = async () => {
    const coordinate = userStore.getCoordinate;

    const targetShop = await getNearestAvailableShop(
      coordinate
        ? { latitude: coordinate.lat, longitude: coordinate.lng }
        : null,
      OrderTypeEnum.SELF_PICK_UP
    );
    return targetShop;
  };

  // 自配送情况获取店铺(可传入一个地址,目前用于AddressList选择地址的场景)
  const selfDeliveryGetShop = async (address) => {
    const selectedAddress = address || addressStore.getSelectedAddress;
    if (!selectedAddress) {
      // 自配送情况,未选择地址,当未找到店铺处理
      return false;
    }
    const coordinate = selectedAddress.location.coordinate;
    const targetShop = await getNearestAvailableShop(
      coordinate,
      OrderTypeEnum.SELF_DELIVERY
    );
    return targetShop;
  };

  // 自提情况获取用户和当前店铺的距离
  const getSelfPickUpDistance = () => {
    return userStore.getCoordinate &&
      shopStore.getShopData?.location?.coordinate
      ? mapHelper.computeDistanceBetween(
          userStore.getCoordinate,
          shopStore.getShopData.location.coordinate
        )
      : NaN;
  };

  // 自配送情况获取当前收货地址和当前店铺的距离
  const getSelfDeliveryDistance = () => {
    return addressStore.getSelectedAddress?.location?.coordinate &&
      shopStore.getShopData?.location?.coordinate
      ? mapHelper.computeDistanceBetween(
          addressStore.getSelectedAddress.location.coordinate,
          shopStore.getShopData.location.coordinate
        )
      : NaN;
  };

  return {
    getNearestAvailableShop,
    selfPickUpGetShop,
    selfDeliveryGetShop,
    getSelfPickUpDistance,
    getSelfDeliveryDistance,
  };
};

// 给定一个坐标({latitude,longitude}),判断目标附近是否有可用店铺(在配送范围内)
// export async function computeIsAvailableShopNearCoor(coordinate) {
//   const shopRes = await getAllShopBySupplier({
//     supplier_id: getSupplyId(),
//     coordinate,
//   });
//   if (shopRes.isOK && shopRes.data) {
//     console.log(shopRes);
//     // init一个mapHelper
//     const mapHelper = await new GoogleMapHelper();
//     const result = await new Promise((resolve) => {
//       mapHelper.initMap(undefined, {}, async () => {
//         const shopList = shopRes.data.shop_list;
//         if (shopList.length === 0) {
//           // 公司下无店铺
//           showToast("No Shop");
//           resolve(false);
//           return;
//         }
//         // 取第一家(默认为最近)
//         const closestShop = shopList[0];
//         // 判断第一家是否在配送范围内
//         const polygon = (closestShop.self_delivery_setting?.delivery_area || []).map((item) => {
//           return {
//             lat: item.latitude,
//             lng: item.longitude,
//           };
//         });
//         const isInRange = mapHelper.containsLocation(
//           {
//             lat: coordinate.latitude,
//             lng: coordinate.longitude,
//           },
//           polygon
//         );
//         console.log(isInRange);
//         if (isInRange) {
//           // 最近的店在范围内,则返回
//           resolve(closestShop);
//         } else {
//           // 否则返回false
//           resolve(false);
//         }
//       });
//     });
//     return result;
//   }
// }

// 给定一个坐标,返回离得最近的一个店铺(不考虑是否在配送范围),无给定坐标则直接返回第一个
// export async function getNearestShop(coordinate) {
//   const params = {
//     supplier_id: getSupplyId(),
//   };
//   if (coordinate) {
//     params.coordinate = coordinate;
//   }
//   const shopRes = await getAllShopBySupplier({
//     supplier_id: getSupplyId(),
//     coordinate,
//   });
//   if (shopRes.isOK && shopRes.data) {
//     const shopList = shopRes.data.shop_list;
//     if (shopList.length === 0) {
//       // 公司下无店铺
//       showToast("No Shop");
//       return false;
//     } else {
//       return shopList[0];
//     }
//   }
// }
