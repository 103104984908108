import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "balance-sum-ctn"
};
var _hoisted_2 = {
  class: "balance-left"
};
var _hoisted_3 = {
  class: "key"
};
var _hoisted_4 = {
  class: "value"
};
var _hoisted_5 = {
  class: "balance-right"
};
var _hoisted_6 = {
  class: "balance-right-item"
};
var _hoisted_7 = {
  class: "key"
};
var _hoisted_8 = {
  class: "value"
};
var _hoisted_9 = {
  class: "balance-right-item"
};
var _hoisted_10 = {
  class: "key"
};
var _hoisted_11 = {
  class: "value"
};
import { onMounted } from "vue";
import { PriceStrCurrencySymbolEnum } from "@/constant/currency";
import { useUserStore } from "@/store/userStore";
import { priceUtil } from "@op/shared";
export default {
  __name: 'AccountBalanceSum',
  setup: function setup(__props) {
    var userStore = useUserStore();
    onMounted(function () {
      userStore.setUserAsset();
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, " Balance (" + _toDisplayString(_unref(PriceStrCurrencySymbolEnum)[_unref(userStore).getWalletCurrency]) + ") ", 1), _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(priceUtil).formatBaseUnitToShowPrice(_unref(userStore).getWallet, _unref(userStore).getWalletCurrency, false)), 1)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, " Reload (" + _toDisplayString(_unref(PriceStrCurrencySymbolEnum)[_unref(userStore).getWalletCurrency]) + ") ", 1), _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(priceUtil).formatBaseUnitToShowPrice(_unref(userStore).getSelfBalance, _unref(userStore).getWalletCurrency, false)), 1)]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, " Reward (" + _toDisplayString(_unref(PriceStrCurrencySymbolEnum)[_unref(userStore).getWalletCurrency]) + ") ", 1), _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(priceUtil).formatBaseUnitToShowPrice(_unref(userStore).getRewardBalance, _unref(userStore).getWalletCurrency, false)), 1)])])]);
    };
  }
};