import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, unref as _unref, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "order-items-card"
};
var _hoisted_2 = {
  class: "item-list"
};
var _hoisted_3 = {
  class: "info"
};
var _hoisted_4 = {
  class: "name"
};
var _hoisted_5 = {
  class: "text"
};
var _hoisted_6 = {
  class: "num"
};
var _hoisted_7 = {
  class: "attr"
};
var _hoisted_8 = {
  class: "price"
};
var _hoisted_9 = {
  class: "amount-info"
};
var _hoisted_10 = {
  class: "label"
};
var _hoisted_11 = {
  class: "value"
};
var _hoisted_12 = {
  key: 0,
  class: "line"
};
var _hoisted_13 = {
  class: "label"
};
var _hoisted_14 = {
  class: "value"
};
var _hoisted_15 = {
  class: "line"
};
var _hoisted_16 = {
  class: "value hightlight"
};
var _hoisted_17 = {
  key: 1,
  class: "line border-t-grey"
};
var _hoisted_18 = {
  class: "value"
};
import Image from "../base/Image.vue";
import { OrderStatusEnum } from "@/constant/order";
import { priceUtil } from "@op/shared";
import { handleFeeDetail } from "@op/shared";
import { computed } from "vue";
import { useShopStore } from "@/store/shopStore";
export default {
  __name: 'OrderItemsCard',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  setup: function setup(__props) {
    var shopStore = useShopStore();
    var props = __props;
    var feeList = computed(function () {
      var _props$order;
      var shopData = shopStore.getShopData;
      return handleFeeDetail({
        fare: (_props$order = props.order) === null || _props$order === void 0 ? void 0 : _props$order.fare,
        currency_setting: shopData.currency_setting
      });
    });
    return function (_ctx, _cache) {
      var _props$order$dishes_, _props$order$fare, _props$order$fare2, _props$order$fare3, _props$order$fare4;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "header"
      }, "Order Items", -1)), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_props$order$dishes_ = __props.order.dishes_item_info) === null || _props$order$dishes_ === void 0 ? void 0 : _props$order$dishes_.items, function (item) {
        var _item$imgs, _item$extra_option_li, _item$fare, _item$fare2;
        return _openBlock(), _createElementBlock("div", {
          key: item.id,
          class: "item"
        }, [_createVNode(Image, {
          "oss-stylename": "type_3",
          class: "img",
          src: (_item$imgs = item.imgs) === null || _item$imgs === void 0 ? void 0 : _item$imgs[0],
          alt: ""
        }, null, 8, ["src"]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_6, "x" + _toDisplayString(item.quantity), 1)]), _createElementVNode("div", _hoisted_7, _toDisplayString((_item$extra_option_li = item.extra_option_list) === null || _item$extra_option_li === void 0 ? void 0 : _item$extra_option_li.map(function (ex) {
          var _ex$values;
          return (_ex$values = ex.values) === null || _ex$values === void 0 ? void 0 : _ex$values.map(function (v) {
            return (v === null || v === void 0 ? void 0 : v.single_serving_quantity) > 1 ? "".concat(v.name, " x").concat(v.single_serving_quantity) : v.name;
          }).join(",");
        }).join(",")) + " - " + _toDisplayString(item.sku_attribute.name), 1), _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(priceUtil).formatBaseUnitToShowPrice((_item$fare = item.fare) === null || _item$fare === void 0 ? void 0 : _item$fare.item_total, (_item$fare2 = item.fare) === null || _item$fare2 === void 0 ? void 0 : _item$fare2.currency)), 1)])]);
      }), 128))]), _createElementVNode("div", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(feeList.value, function (item) {
        return _openBlock(), _createElementBlock("div", {
          key: item.key,
          class: "line"
        }, [_createElementVNode("div", _hoisted_10, _toDisplayString(item.label), 1), _createElementVNode("div", _hoisted_11, _toDisplayString(item.value), 1)]);
      }), 128)), (_props$order$fare = __props.order.fare) !== null && _props$order$fare !== void 0 && (_props$order$fare = _props$order$fare.coupon_info) !== null && _props$order$fare !== void 0 && _props$order$fare[0] ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, " Voucher(" + _toDisplayString(__props.order.fare.coupon_info[0].coupon_name) + ") ", 1), _createElementVNode("div", _hoisted_14, " -" + _toDisplayString(_unref(priceUtil).formatBaseUnitToShowPrice(__props.order.fare.coupon_info[0].coupon_amount, (_props$order$fare2 = __props.order.fare) === null || _props$order$fare2 === void 0 ? void 0 : _props$order$fare2.currency)), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_15, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "label hightlight"
      }, "Total", -1)), _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(priceUtil).formatBaseUnitToShowPrice((_props$order$fare3 = __props.order.fare) === null || _props$order$fare3 === void 0 ? void 0 : _props$order$fare3.customer_total_display, (_props$order$fare4 = __props.order.fare) === null || _props$order$fare4 === void 0 ? void 0 : _props$order$fare4.currency)), 1)]), __props.order.status === _unref(OrderStatusEnum).COMPLETED && __props.order.order_reward_points ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "label"
      }, "Points Earned", -1)), _createElementVNode("div", _hoisted_18, _toDisplayString(__props.order.order_reward_points) + " pts", 1)])) : _createCommentVNode("", true)])]);
    };
  }
};