import { renderSlot as _renderSlot, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import PoweredBy from "./PoweredBy.vue";
import StickyFooter from "./StickyFooter.vue";
export default {
  __name: 'StickyPowerBy',
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(StickyFooter, null, {
        footer: _withCtx(function () {
          return [_createVNode(PoweredBy)];
        }),
        default: _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "default")];
        }),
        _: 3
      });
    };
  }
};