import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
import _imports_0 from '@/assets/images/icons/close_btn.svg';
var _hoisted_1 = {
  class: "cart-list"
};
var _hoisted_2 = {
  class: "cart-list-header"
};
var _hoisted_3 = {
  class: "icon-delete-wrap"
};
import { computed } from "vue";
import { useCartStore } from "@/store/cartStore";
import CartItem from "./CartItem.vue";
import SvgIcon from "../base/SvgIcon.vue";
import Dialog from "../dialog/dialog";
export default {
  __name: 'CartList',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    disableGoConfirmOrder: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:show"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var cartStore = useCartStore();
    var props = __props;
    var emits = __emit;
    var showCartList = computed({
      get: function get() {
        return props.show;
      },
      set: function set(val) {
        emits("update:show", val);
      }
    });
    var cartList = computed(function () {
      return cartStore.getCart;
    });
    function handleUpdateCount(cartIndex, num) {
      cartStore.changeNum(cartIndex, num);
      if (cartStore.getCartLength === 0) {
        handleClose();
      }
    }
    function handleClose() {
      showCartList.value = false;
    }
    function handleClearCart() {
      Dialog.confirm({
        title: "Empty cart",
        desc: "Are you sure to empty the cart?",
        okText: "Yes",
        cancelText: "No",
        onOk: function onOk() {
          handleClose();
          setTimeout(function () {
            cartStore.clear();
          }, 400);
        }
      });
    }
    return function (_ctx, _cache) {
      var _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createBlock(_component_van_popup, {
        show: showCartList.value,
        "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
          return showCartList.value = $event;
        }),
        "safe-area-inset-bottom": "",
        position: "bottom",
        round: "",
        "z-index": "9"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
            class: "delete-wrap",
            onClick: handleClearCart
          }, [_createElementVNode("div", _hoisted_3, [_createVNode(SvgIcon, {
            name: "delete",
            class: "icon-delete"
          })]), _cache[1] || (_cache[1] = _createElementVNode("div", {
            class: "delete-text"
          }, "Empty", -1))]), _cache[3] || (_cache[3] = _createElementVNode("div", {
            class: "cart-list-title"
          }, "Cart", -1)), _createElementVNode("div", {
            class: "icon-close-wrap",
            onClick: handleClose
          }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
            class: "icon-close",
            src: _imports_0,
            alt: ""
          }, null, -1)]))]), _createElementVNode("div", {
            class: _normalizeClass(["cart-list-body", {
              'cart-list-body--starting-price': __props.disableGoConfirmOrder
            }])
          }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cartList.value, function (cartItem, cartIndex) {
            return _openBlock(), _createBlock(CartItem, {
              key: cartItem.UUKey,
              cart: cartItem,
              "onUpdate:count": function onUpdateCount($event) {
                return handleUpdateCount(cartIndex, $event);
              }
            }, null, 8, ["cart", "onUpdate:count"]);
          }), 128))], 2)])];
        }),
        _: 1
      }, 8, ["show"]);
    };
  }
};