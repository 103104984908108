import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "swipe-list"
};
import { onMounted, onBeforeUnmount, ref } from "vue";
import SvgIcon from "../base/SvgIcon.vue";
export default {
  __name: 'LoopSwipe',
  setup: function setup(__props) {
    var timer = null;
    var curSwipeIndex = ref(-1);
    var swipeList = ref(["Congratulations to T*** for getting a voucher", "Congratulations to Y** for getting a voucher", "Congratulations to C*** for getting a voucher", "Congratulations to Y*** for getting a voucher", "Congratulations to Z*** for getting a voucher", "Congratulations to H*** for getting a voucher", "Congratulations to Z*** for getting a voucher", "Congratulations to X** for getting a voucher", "Congratulations to N*** for getting a voucher", "Congratulations to C*** for getting a voucher", "Congratulations to Y*** for getting a voucher", "Congratulations to Z*** for getting a voucher"]);
    function loop() {
      var immediate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var curIndex = curSwipeIndex.value;
      curSwipeIndex.value = -1;
      timer = setTimeout(function () {
        curSwipeIndex.value = (curIndex + 1) % swipeList.value.length;
        timer = setTimeout(function () {
          loop();
        }, 3800);
      }, immediate ? 0 : 800);
    }
    onMounted(function () {
      loop(true);
    });
    onBeforeUnmount(function () {
      clearTimeout(timer);
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(SvgIcon, {
        name: "notify",
        size: 16
      }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(swipeList.value, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: "swipe-item"
        }, [_createVNode(_Transition, {
          name: "slide-up"
        }, {
          default: _withCtx(function () {
            return [_withDirectives(_createElementVNode("div", null, _toDisplayString(item), 513), [[_vShow, index === curSwipeIndex.value]])];
          }),
          _: 2
        }, 1024)]);
      }), 128))]);
    };
  }
};