import { registerSubscription, unRegisterSubscription } from "@/api/push";
import { showLoading, hideLoading } from "./loadingUtil";
import { showToast } from "./toastUtil";
import { checkPlatform, getIosVersion } from "@/utils/tools";
import { getIsInPwa } from "@/utils/pwaGuide/pwaGuide";
import { Storage } from "./storageUtil";
import { getUserId } from "./authUtil";

const curPushUserIdCacheKey = "ORDERPIN_TEA_CUR_PUSH_USER_ID";

// 订阅推送并提交到服务端
async function subscribePushAndSubmit() {
  const reg = await navigator.serviceWorker.ready;
  try {
    const subscription = await reg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: process.env.VUE_APP_PUSH_PUBLIC_KEY,
    });
    // 把subscription提交的逻辑
    showLoading();
    const res = await registerSubscription({
      business_code: "OrderPinUser",
      device_type: "mobile",
      subscription,
    });
    hideLoading();
    Storage.set(curPushUserIdCacheKey, getUserId());
    return subscription;
  } catch (err) {
    console.log(err);
    return false;
  }
}

// 获取推送对象
async function getSubscription() {
  try {
    const reg = await navigator.serviceWorker.ready;
    const subscription = await reg.pushManager.getSubscription();
    return subscription;
  } catch (err) {
    console.log(err);
  }
}

// 取消推送
async function unsubscribePushAndSubmit() {
  const sububscription = await getSubscription();
  if (sububscription) {
    showLoading();
    const res = await unRegisterSubscription({
      business_code: "OrderPinUser",
      device_type: "mobile",
      subscription,
    });
    hideLoading();
  }
}

// 弹出弹窗询问是否订阅推送(如果目前已有推送则不询问)
export async function askPushSubscription() {
  const isPwa = getIsInPwa();
  if (checkPlatform("ios") && (!isPwa || (isPwa && getIosVersion() < 16.4))) {
    // ios情况下,不在pwa,或在pwa但版本不到16.4的都不做订阅
    return;
  }
  const sububscription = await getSubscription();
  if (
    sububscription &&
    Storage.get(curPushUserIdCacheKey, null) === getUserId()
  ) {
    return;
  }
  const result = await subscribePushAndSubmit();
  if (!result) {
    showToast("Failed to obtain push permission");
  }
}
