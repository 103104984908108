import "core-js/modules/es.array.concat.js";
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "order-detail-card"
};
var _hoisted_2 = {
  class: "amount-info"
};
var _hoisted_3 = {
  class: "line"
};
var _hoisted_4 = {
  class: "value"
};
var _hoisted_5 = {
  class: "line"
};
var _hoisted_6 = {
  class: "value"
};
var _hoisted_7 = {
  key: 0,
  class: "line"
};
var _hoisted_8 = {
  class: "value"
};
var _hoisted_9 = {
  key: 1,
  class: "line"
};
var _hoisted_10 = {
  class: "value"
};
var _hoisted_11 = {
  class: "line"
};
var _hoisted_12 = {
  class: "value"
};
var _hoisted_13 = {
  class: "line"
};
var _hoisted_14 = {
  class: "value"
};
var _hoisted_15 = {
  class: "line"
};
var _hoisted_16 = {
  class: "value van-multi-ellipsis--l2"
};
import { OrderTypeEnum, paymentMethodMap } from "@/constant/order";
import { formatDateWithTZ } from "@/utils/format";
import { computed } from "vue";
import { PAYMENT_PLATFORM_TEXT } from "@op/shared";
export default {
  __name: 'OrderDetailCard',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var isDelivery = computed(function () {
      return props.order.delivery_method === OrderTypeEnum.SELF_DELIVERY;
    });
    return function (_ctx, _cache) {
      var _props$order$times, _props$order$times2, _props$order$times3, _props$order$times4, _props$order$times5, _props$order$payment, _props$order$payment2, _props$order$payment3, _props$order$payment4, _props$order$payment5, _props$order$payment6, _props$order$payment7, _props$order$payment8;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[7] || (_cache[7] = _createElementVNode("div", {
        class: "header"
      }, "Order Details", -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "label"
      }, "Order ID", -1)), _createElementVNode("div", _hoisted_4, _toDisplayString(__props.order.order_id), 1)]), _createElementVNode("div", _hoisted_5, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "label"
      }, "Order Time", -1)), _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(formatDateWithTZ)((_props$order$times = __props.order.times) === null || _props$order$times === void 0 ? void 0 : _props$order$times.order_at)), 1)]), (_props$order$times2 = __props.order.times) !== null && _props$order$times2 !== void 0 && _props$order$times2.ready_at ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "label"
      }, "Ready Time", -1)), _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(formatDateWithTZ)((_props$order$times3 = __props.order.times) === null || _props$order$times3 === void 0 ? void 0 : _props$order$times3.ready_at)), 1)])) : _createCommentVNode("", true), (_props$order$times4 = __props.order.times) !== null && _props$order$times4 !== void 0 && _props$order$times4.completed_at ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "label"
      }, "Completed Time", -1)), _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(formatDateWithTZ)((_props$order$times5 = __props.order.times) === null || _props$order$times5 === void 0 ? void 0 : _props$order$times5.completed_at)), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_11, [_cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "label"
      }, "Order Type", -1)), _createElementVNode("div", _hoisted_12, _toDisplayString(isDelivery.value ? "Delivery" : "Pickup"), 1)]), _createElementVNode("div", _hoisted_13, [_cache[5] || (_cache[5] = _createElementVNode("div", {
        class: "label"
      }, "Payment Method", -1)), _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(paymentMethodMap)[(_props$order$payment = __props.order.payment_info) === null || _props$order$payment === void 0 ? void 0 : _props$order$payment.method] ? "".concat(_unref(paymentMethodMap)[(_props$order$payment2 = __props.order.payment_info) === null || _props$order$payment2 === void 0 ? void 0 : _props$order$payment2.method]).concat((_props$order$payment3 = __props.order.payment_info) !== null && _props$order$payment3 !== void 0 && _props$order$payment3.platform && ((_props$order$payment4 = __props.order.payment_info) === null || _props$order$payment4 === void 0 ? void 0 : _props$order$payment4.platform) !== "cash_on_delivery" && ((_props$order$payment5 = __props.order.payment_info) === null || _props$order$payment5 === void 0 ? void 0 : _props$order$payment5.platform) !== "balance" ? "(" + (_unref(PAYMENT_PLATFORM_TEXT)[(_props$order$payment6 = __props.order.payment_info) === null || _props$order$payment6 === void 0 ? void 0 : _props$order$payment6.platform] || ((_props$order$payment7 = __props.order.payment_info) === null || _props$order$payment7 === void 0 ? void 0 : _props$order$payment7.platform)) + ")" : "") : ((_props$order$payment8 = __props.order.payment_info) === null || _props$order$payment8 === void 0 ? void 0 : _props$order$payment8.method) || "-"), 1)]), _createElementVNode("div", _hoisted_15, [_cache[6] || (_cache[6] = _createElementVNode("div", {
        class: "label"
      }, "Remarks", -1)), _createElementVNode("div", _hoisted_16, _toDisplayString(__props.order.remark || "-"), 1)])])]);
    };
  }
};