import { useRouter as useVueRouter } from "vue-router";

export function useRouter() {
  const router = useVueRouter();

  const routerPush = (params) => {
    window.isBack = false;
    router.push(params);
  };

  const routerReplace = (params) => {
    window.isBack = false;
    router.replace(params);
  };

  const routerBack = (params) => {
    window.isBack = true;
    router.back(params);
  };

  const routerGo = (params) => {
    window.isBack = params < 0;
    router.go(params);
  };

  return {
    router,
    routerPush,
    routerReplace,
    routerBack,
    routerGo,
  };
}
