import { useShopStore } from "@/store/shopStore";
import { useAddress } from "./useAddress";
import { useRouter } from "./useRouter";
import { OrderTypeEnum } from "@/constant/order";
import { useUserStore } from "@/store/userStore";
import { showToast } from "@/utils/toastUtil";
import { useSupplierStore } from "@/store/supplierStore";

export function useOrder() {
  const { routerPush } = useRouter();
  const supplierStore = useSupplierStore();
  const { selfPickUpGetShop, selfDeliveryGetShop } = useAddress();
  const shopStore = useShopStore();
  const userStore = useUserStore();
  // 根据商家配置开启情况选择自提自配送
  async function toOrderView() {
    // const targetShop = await selfPickUpGetShop();
    // if (targetShop) {
    //   await shopStore.switchShop(targetShop, { showToast: true });
    //   userStore.setOrderType(OrderTypeEnum.SELF_PICK_UP);
    //   routerPush({
    //     name: "Menu",
    //   });
    // } else {
    //   // 理论上不应该没有目标店铺
    //   showToast("Store not found");
    // }
    const isPickupOpen = supplierStore.getIsSupplierSelfPickupStatus;
    const isDeliveryOpen = supplierStore.getIsSupplierSelfDeliverStatus;
    if (!isPickupOpen && isDeliveryOpen) {
      // 自提没开,配送开了,此时跳配送
      await toOrderViewDelivery();
    } else {
      // 其余跳自提
      await toOrderViewPickup();
    }
  }

  // 跳转统一逻辑(自提)
  async function toOrderViewPickup() {
    const targetShop = await selfPickUpGetShop();
    if (targetShop) {
      await shopStore.switchShop(targetShop, { showToast: true });
      userStore.setOrderType(OrderTypeEnum.SELF_PICK_UP);
      routerPush({
        name: "Menu",
      });
    } else {
      // 理论上不应该没有目标店铺
      showToast("Store not found");
    }
  }

  // 跳转统一逻辑(自配送)
  async function toOrderViewDelivery() {
    const targetShop = await selfDeliveryGetShop();
    if (targetShop) {
      // 最近的店在范围内,则选用该店
      await shopStore.switchShop(targetShop, { showToast: true });
      userStore.setOrderType(OrderTypeEnum.SELF_DELIVERY);
      routerPush({
        name: "Menu",
      });
    } else {
      // 否则跳转选择地址
      routerPush({
        name: "AddressList",
        query: {
          toName: "Menu",
          isForceGo: 1,
        },
      });
    }
  }

  return {
    toOrderView,
    toOrderViewPickup,
    toOrderViewDelivery,
  };
}
