import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onActivated } from "vue";
import { useUserStore } from "@/store/userStore";
import StickyPowerBy from "@/components/StickyPowerBy.vue";
import AccountInfo from "@/components/account/AccountInfo.vue";
import AccountAsset from "@/components/account/AccountAsset.vue";
import AccountActivity from "@/components/account/AccountActivity.vue";
import AccountFeature from "@/components/account/AccountFeature.vue";
export default {
  __name: 'AccountView',
  setup: function setup(__props) {
    var userState = useUserStore();
    onActivated(function () {
      userState.setUserAsset();
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(StickyPowerBy, {
        class: "account"
      }, {
        default: _withCtx(function () {
          return [_createVNode(AccountInfo), _createVNode(AccountAsset), _createVNode(AccountActivity), _createVNode(AccountFeature)];
        }),
        _: 1
      });
    };
  }
};