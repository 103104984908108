import { times, divide } from "@/utils/mathUtil";
import { toDayBusinessHoursMap } from "./tools";
import dayjs from "dayjs";
import { priceUtil, formatPercent as _formatPercent } from "@op/shared";
import { DiscountTypeEnum, UsingTimeRuleTypeEnum } from "@/constant/coupon";
import { cloneDeep } from "lodash-es";
import { isNumber } from "./isUtil";
/**
 * 百分比展示转化
 * @param val 小数展示，如 0.12
 * @param country
 * @param isFormated 0.12 是否已转换为 12
 * @returns '12%'
 */
export function formatPercent(val = 0, country, isFormated = false) {
  return _formatPercent(val, country, isFormated);
}

// 格式化地址对象的方法(针对location这一级)
export function formatLocation(location) {
  if (!location) {
    return "";
  }
  let detail = location.detail || "";
  if (
    detail.length > 30 &&
    location.country &&
    location.state &&
    location.city
  ) {
    // 防止detail写得过长,超过30位则切除
    detail = detail.substring(0, 26) + "...";
  }
  const resArr = [
    location.unit_no,
    detail,
    location.site || "",
    location.street || "",
    location.district || "",
    [location.postcode, location.city].filter((item) => !!item).join(" ") || "",
    location.state || "",
    location.country || "",
  ];
  return resArr.filter((item) => !!item).join(", ");
}

export function formatNumberWithZero(num) {
  return isNaN(num) ? String(num) : ("0" + String(num)).slice(-2);
}

/**
 * 把arrBusinessHours的hours转换到当前时区下
 * @param {Array} arrBusinessHours business_hours,形如[{type: 'monday_to_friday', hours: [{start_at: "07:27", end_at: "23:27"}]}]
 * @param {String} shopTimezone 店铺所在的时区
 */
export function formatBusinessHoursWithTZ(arrBusinessHours, shopTimezone) {
  if (!(arrBusinessHours && Array.isArray(arrBusinessHours) && shopTimezone)) {
    return arrBusinessHours;
  }

  function formatStartAtWithTZ(data, shopTimezone) {
    const [hour, minute] = data.split(":");
    const dayjsWithTZ = dayjs()
      .tz(shopTimezone)
      .hour(Number(hour))
      .minute(Number(minute))
      .second(0)
      .millisecond(0)
      .tz();

    return `${formatNumberWithZero(dayjsWithTZ.hour())}:${formatNumberWithZero(
      dayjsWithTZ.minute()
    )}`;
  }

  return arrBusinessHours.map((bhItem) => {
    const item = cloneDeep(bhItem);
    item.hours = item.hours.map((hourItem) => ({
      start_at: formatStartAtWithTZ(hourItem.start_at, shopTimezone),
      end_at: formatStartAtWithTZ(hourItem.end_at, shopTimezone),
    }));
    return item;
  });
}

export function formateTodayBusinessTime(businessTime) {
  const openTime = toDayBusinessHoursMap(businessTime);
  return openTime[new Date().getDay()]?.str || "";
}

export function formatDateWithTZ(timeStamp, pattern = "YYYY-MM-DD HH:mm") {
  // 修改时间戳判断逻辑
  return isNumber(timeStamp) && !isNaN(timeStamp) && timeStamp !== 0
    ? dayjs.tz(timeStamp).format(pattern)
    : "-";
}

export function formatDistance(distance) {
  return distance >= 1000
    ? (distance / 1000).toFixed(1) + "km"
    : distance.toFixed(1) + "m";
}

// 格式化优惠券的优惠金额
export function formatCouponReward(discountType, discountRule) {
  if (!discountType || !discountRule) {
    return "";
  }

  if (discountType === DiscountTypeEnum.FIXED) {
    return priceUtil.formatBaseUnitToShowPrice(
      discountRule.discount,
      discountRule.currency
    );
  } else if (discountType === DiscountTypeEnum.PERCENT) {
    return `${times(discountRule.discount_percent, 100)}%`;
  } else {
    return "";
  }
}

// 格式化优惠券的日数
export function formatCouponValidDays(usingTimeRule) {
  if (!usingTimeRule) {
    return 0;
  }

  if (usingTimeRule.rule_type === UsingTimeRuleTypeEnum.FIXED) {
    if (usingTimeRule.start_time && usingTimeRule.end_time) {
      return Math.floor(
        (usingTimeRule.end_time - usingTimeRule.start_time) /
          (24 * 60 * 60 * 1000)
      );
    } else {
      return 0;
    }
  } else if (usingTimeRule.rule_type === UsingTimeRuleTypeEnum.AFTER_SEND) {
    return usingTimeRule.expired_interval || 0;
  } else {
    return 0;
  }
}
