export const ShopStatusEnum = {
  OPEN: "OPEN",
  NOT_SUPPORT_PICKUP: "NOT_SUPPORT_PICKUP",
  NOT_SUPPORT_DELIVERY: "NOT_SUPPORT_DELIVERY",
  CLOSED: "CLOSED",
};

export const ShopOpenStatusEnum = {
  IN_OPERATION: "in_operation",
  CLOSED: "closed",
};
