import { post, get } from "@/utils/requestUtil";

// 注册订阅
export function registerSubscription(params) {
  return post("/orderpin/message/c/v1/web_push/register_subscription", params);
}

// 取消订阅
export function unRegisterSubscription(params) {
  return post(
    "/orderpin/message/c/v1/web_push/unregister_subscription",
    params
  );
}
