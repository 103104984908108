export const ActivityTypeEnum = {
  SHARING: "sharing",
};

export const ActivityStatusEnum = {
  ONGOING: "ongoing",
  ENDED: "ended",
  DONE: "done",
};

export const UserActivityStatusEnum = {
  INIT: "init",
  ONGOING: "ongoing",
  ENDED: "ended",
  DONE: "done",
};
