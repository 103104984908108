export const OK = 0;
// auth codes
export const TOKEN_INVALID = 41004;
export const INCORRECT_USERNAME = 13004; // 错误的账号或者密码
export const INCORRECT_PASSEORD = 2011; // 错误的账号或者密码
export const TOO_MANY_REQUEST = 2012; // 频繁请求
export const CODE_INVALID = 13008; // 无效的验证码
export const CODE_ERROR = 13009; // 无效的验证码
export const PHONE_REGISTER = 1016; // 手机号已被注册
export const PHONE_BINDED = 41001; // 手机号已被绑定

export const SHOP_UNSUPPORT_DELIVERY = 12102;
export const SHOP_BUSY = 12103;
export const SHOP_NOT_IN_BUSINESS_STATUS = 12104;
export const DISHES_ORDER_ITEM_INVALID = 12015;
// 活动相关
export const USER_ALREADY_PARTICIPATED_ACTIVITY = 51106; // 已参与
export const ACTIVITY_NOT_START = 51109; // 未开始
export const ACTIVITY_REACH_MAX_PARTICIPATOR = 51107; // 已达到最大参与
export const ACTIVITY_ENDED = 51110; // 已结束
export const ACTIVITY_INEFFECTIVE = 51111; // 已失效
export const ACTIVITY_NO_EXISTS = 51101; // 活动不存在
export const SHARING_ACTIVITY_ASSISTED = 14005; // 裂变活动已助力

export const whiteListcode = [
  OK,
  INCORRECT_USERNAME,
  INCORRECT_PASSEORD,
  CODE_INVALID,
  CODE_ERROR,
  PHONE_REGISTER,
  PHONE_BINDED,

  SHOP_UNSUPPORT_DELIVERY,
  SHOP_BUSY,
  SHOP_NOT_IN_BUSINESS_STATUS,
  DISHES_ORDER_ITEM_INVALID,
  USER_ALREADY_PARTICIPATED_ACTIVITY,
  ACTIVITY_NOT_START,
  ACTIVITY_REACH_MAX_PARTICIPATOR,
  ACTIVITY_ENDED,
  ACTIVITY_INEFFECTIVE,
  ACTIVITY_NO_EXISTS,
  SHARING_ACTIVITY_ASSISTED,
];
