import { createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/images/pwa-guide/step-1.png';
import _imports_1 from '@/assets/images/pwa-guide/step-2.png';
import { ref, watchEffect, onMounted } from "vue";
import Common from "./Common.vue";
export default {
  __name: 'PwaGuideInIos',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    showAfterMounted: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var show = ref(false);
    watchEffect(function () {
      if (props.visible) {
        show.value = true;
      }
    });
    var handleOk = function handleOk() {
      show.value = false;
    };
    onMounted(function () {
      if (props.showAfterMounted) {
        show.value = true;
      }
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(Common, {
        visible: show.value,
        "onUpdate:visible": _cache[0] || (_cache[0] = function ($event) {
          return show.value = $event;
        }),
        title: "Installation",
        "on-ok": handleOk,
        "ok-text": "Confirm",
        "without-cancel": true,
        "show-bottom-button": true,
        "hide-bottom-shadow": true
      }, {
        content: _withCtx(function () {
          return _cache[1] || (_cache[1] = [_createElementVNode("div", {
            class: "pwag-ctn"
          }, [_createElementVNode("div", {
            class: "pwag-img-flex"
          }, [_createElementVNode("img", {
            src: _imports_0
          }), _createElementVNode("img", {
            src: _imports_1
          })]), _createElementVNode("div", {
            class: "pwag-text-list"
          }, [_createElementVNode("p", null, "1. Click this button."), _createElementVNode("p", null, "2. Add a website to your home screen."), _createElementVNode("p", null, " 3. Then,you can quickly access the website from your desktop to get order progress and discount information in a timely manner. ")])], -1)]);
        }),
        _: 1
      }, 8, ["visible"]);
    };
  }
};