import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/activity/popover_arrow.png';
var _hoisted_1 = {
  class: "popover-arrow-wrap"
};
var _hoisted_2 = {
  class: "popover-content"
};
import { ref, computed } from "vue";
export default {
  __name: 'ProgressBar',
  props: {
    assistMinCount: {
      type: Number,
      default: 0
    },
    assistedCount: {
      type: Number,
      default: 0
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var neededParticipatedNum = computed(function () {
      return props.assistMinCount - props.assistedCount;
    });
    var neededParticipatedTips = computed(function () {
      return neededParticipatedNum.value > 0 ? "".concat(neededParticipatedNum.value, " friends are still needed") : "Activity successful";
    });
    var progressBarWrap = ref();
    var progressBarPopover = ref();
    var progressRatio = computed(function () {
      return neededParticipatedNum.value > 0 ? 1 - neededParticipatedNum.value / props.assistMinCount : 1;
    });
    var progressWidth = computed(function () {
      return "".concat((progressRatio.value * 100).toFixed(2), "%");
    });
    var popoverPosition = computed(function () {
      if (progressBarWrap.value && progressBarPopover.value) {
        // 判断两头是否有突出
        var progressBarWrapWidth = progressBarWrap.value.offsetWidth;
        var progressBarPopoverWidth = progressBarPopover.value.offsetWidth;
        var popoverRatio = progressBarPopoverWidth / progressBarWrapWidth;
        if (progressRatio.value < popoverRatio / 2) {
          return "0%";
        } else if (progressRatio.value > 1 - popoverRatio / 2) {
          return "100%";
        } else {
          return "".concat((progressRatio.value * 100).toFixed(2), "%");
        }
      } else {
        return "0%";
      }
    });
    var popoverArrowPosition = computed(function () {
      if (progressRatio.value < 0.05) {
        return "5%";
      } else if (progressRatio.value > 0.95) {
        return "95%";
      } else {
        return "".concat((progressRatio.value * 100).toFixed(2), "%");
      }
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "progressBarWrap",
        ref: progressBarWrap,
        class: "progress-bar-wrap"
      }, [_createElementVNode("div", {
        class: "progress-bar",
        style: _normalizeStyle({
          width: progressWidth.value
        })
      }, _cache[0] || (_cache[0] = [_createElementVNode("div", {
        class: "bar-shadow"
      }, null, -1)]), 4), _createElementVNode("div", _hoisted_1, [_createElementVNode("img", {
        class: "popover-arrow",
        src: _imports_0,
        style: _normalizeStyle({
          left: popoverArrowPosition.value
        })
      }, null, 4)]), _createElementVNode("div", {
        ref_key: "progressBarPopover",
        ref: progressBarPopover,
        class: "progress-bar-popover",
        style: _normalizeStyle({
          left: popoverPosition.value === '100%' ? 'unset' : popoverPosition.value === '0%' ? 0 : popoverPosition.value,
          right: popoverPosition.value === '100%' ? 0 : 'unset',
          transform: popoverPosition.value !== '0%' && popoverPosition.value !== '100%' ? 'translateX(-50%)' : ''
        })
      }, [_createElementVNode("div", _hoisted_2, _toDisplayString(neededParticipatedTips.value), 1)], 4)], 512);
    };
  }
};