export default class LazyLoadImage {
  constructor(option = {}) {
    this._observer = null;
    this._loadingImage = option.default ?? "";
    this._rootMargin = option._rootMargin;
    this.init();
  }

  init() {
    if (!IntersectionObserver) return;
    this._observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // 触发进入视窗条件，替换真正的图片链接到src属性上
          if (entry.intersectionRatio > 0) {
            const url = entry.target.getAttribute("data-src");
            entry.target.setAttribute("src", url ?? "");
            // entry.target.setAttribute('data-src', '');

            this._observer && this._observer.unobserve(entry.target);
          }
        });
      },
      {
        root: null,
        rootMargin: this._rootMargin,
        threshold: 0.1,
      }
    );
  }

  add(entry) {
    if (this._observer) {
      this._observer.observe(entry);
    } else {
      // 兼容不支持IntersectionObserver的情况
      const url = entry.getAttribute("data-src");
      entry.setAttribute("src", url ?? "");
    }
  }
}
