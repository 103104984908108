// import { useGlobSetting } from "@/utils/setting/globSetting";
import { Loader, Libraries } from "@googlemaps/js-api-loader";
import { isFunction } from "./isUtil";
// const glob = useGlobSetting();

// interface Params {
//   libraries?: Libraries[];
//   key?: string;
// }

// interface PlaceResultItem {
//   site: string;
//   detail: string;
//   location: string;
// }

// interface PlacesServiceCallMethodReq {
//   query: string;
//   fields: string[];
//   location?: google.maps.LatLngLiteral;
// }

export const GeolocationPositionCode = {
  SUCCESS: 0,
  PERMISSION_DENIED: 1,
  POSITION_UNAVAILABLE: 2,
  TIMEOUT: 3,
  BROWSER_NOT_SUPPORT: 101,
};

// export interface GetCurrentPositionByH5Resp {
//   isOK: boolean;
//   code: GeolocationPositionCode;
//   message?: string;
//   data?: google.maps.LatLngLiteral;
// }

// mapLoader全局只加载一次
let mapLoader = null;
export default class GoogleMapHelper {
  // map: google.maps.Map | null;
  // mapLoader: Loader;
  constructor(params) {
    this.map = null;
    this.autocomplete = null;
    if (mapLoader) {
      this.mapLoader = mapLoader;
    } else {
      mapLoader = new Loader({
        apiKey: params?.key || "AIzaSyAiBwvZsj9Nj40CZSqVh6gERsSsqQLGEqc",
        version: "weekly",
        libraries: params?.libraries || ["geometry", "places"],
      });
      this.mapLoader = mapLoader;
    }
  }

  // 初始化谷歌地图。传入元素id则创建地图，否则不创建地图
  initMap(id, options, callback) {
    this.mapLoader.loadCallback((e) => {
      if (e) {
        console.error("[load google map error]:", e);
        callback(e);
      } else {
        if (id && document.getElementById(id)) {
          this.map = new google.maps.Map(document.getElementById(id), options);
        }
        if (isFunction(callback)) {
          callback();
        }
      }
    });
  }

  // 初始化autocomplete,流程与initMap相似(调用)
  initAutocomplete(id, options, changeHandler) {
    this.mapLoader.loadCallback((e) => {
      if (e) {
        console.error("[load google map error]:", e);
      } else {
        if (id && document.getElementById(id)) {
          this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById(id),
            options
          );
          this.autocomplete.addListener("place_changed", () => {
            const place = this.autocomplete.getPlace();
            if (isFunction(changeHandler)) {
              changeHandler(place);
            }
          });
        }
      }
    });
  }

  isLoadGoogleMap() {
    return !!(google && google.maps && google.maps.Marker);
  }

  // 为地图设置中心点
  setCenter(position) {
    this.map.setCenter({
      lat: position.lat,
      lng: position.lng,
    });
  }

  // 向地图上添加标注
  addMarker(position, options = {}) {
    if (!this.isLoadGoogleMap()) return;
    return new google.maps.Marker({
      position,
      map: this.map,
      ...options,
    });
  }

  // 从地图上移除标记
  removeMarker(marker) {
    if (marker) {
      marker.setMap(null);
    }
  }

  /**
   * 获取当前定位
   * 注意：此方法在手机端使用时，必须是 https 协议的网站才能生效
   * @returns {*} {lat, lng}
   */
  async getCurrentPositionByH5() {
    // 1) 通过 h5 的 navigator.geolocation 获取当前位置经纬度
    const position = await new Promise((resolve) => {
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              isOK: true,
              code: GeolocationPositionCode.SUCCESS,
              data: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            });
          },
          (err) => {
            console.warn(`[getCurrentPosition](${err.code}): ${err.message}`);
            resolve({
              isOK: false,
              code: err.code,
              message: err.message,
            });
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
        );
      } else {
        // Browser doesn't support Geolocation
        console.warn(`Browser doesn't support Geolocation`);
        resolve({
          isOK: false,
          code: GeolocationPositionCode.BROWSER_NOT_SUPPORT,
          message: `Browser doesn't support Geolocation`,
        });
      }
    });

    return position;
  }

  /**
   * 通过经纬度查询Address
   */
  async reverseGeocoding(latlng) {
    if (!this.isLoadGoogleMap()) return null;
    try {
      const geocoder = new google.maps.Geocoder();
      const response = await geocoder.geocode({ location: latlng });

      if (response.results.length > 0) {
        return response.results[0];
      }

      return null;
    } catch (e) {
      console.warn("Geocoder failed due to: " + e);
      return null;
    }
  }

  /**
   * 通过文本查询地理位置
   * @param {*} address 文本
   */
  async findPlaceFromQuery(address, pos) {
    if (!this.isLoadGoogleMap() || !this.map || !address) {
      return Promise.resolve([]);
    }

    let methodName = "findPlaceFromQuery";
    const request = {
      query: address,
      fields: ["name", "geometry", "formatted_address"],
    };

    if (pos && pos.lat && pos.lng) {
      request.location = {
        lat: pos.lat,
        lng: pos.lng,
      };

      methodName = "textSearch";
    }

    return await new Promise((resolve) => {
      const service = new google.maps.places.PlacesService(this.map);
      service[methodName](request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          const addrs = results.map((item) => ({
            site: item.name,
            detail: item.formatted_address,
            location: item.geometry.location && item.geometry.location.toJSON(),
          }));
          resolve(addrs);
        } else {
          resolve([]);
        }
      });
    });
  }

  /**
   * getLatLng
   * @private
   * @param {*} latlng {lat, lng}
   * @returns {LatLng} google latlng
   */
  getLatLng(latlng) {
    if (!this.isLoadGoogleMap()) return;
    return new google.maps.LatLng(
      latlng.lat || latlng.latitude,
      latlng.lng || latlng.longitude
    );
  }

  /**
   * 计算两个经纬度之间的距离
   * @public
   * @param {*} from {lat, lng}
   * @param {*} to {lat, lng}
   * @param {*} radius custom radius
   * @returns {*} meters
   */
  computeDistanceBetween(from, to, radius) {
    if (!this.isLoadGoogleMap()) {
      return this.calDistanceFromLatLng(
        from.lat,
        from.lng,
        to.latitude,
        to.longitude
      );
    }
    return google.maps.geometry.spherical.computeDistanceBetween(
      this.getLatLng(from),
      this.getLatLng(to),
      radius
    );
  }

  calDistanceFromLatLng(lat1, lng1, lat2, lng2) {
    var radLat1 = (lat1 * Math.PI) / 180.0;
    var radLat2 = (lat2 * Math.PI) / 180.0;
    var a = radLat1 - radLat2;
    var b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
    var s =
      2 *
      Math.asin(
        Math.sqrt(
          Math.pow(Math.sin(a / 2), 2) +
            Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)
        )
      );
    s = s * 6378.137; // EARTH_RADIUS;
    s = Math.round(s * 10000) / 10;
    return s;
  }

  /**
   * 确定给定点是否在多边形或折线内部或附近
   * @param {LatLng} latlng
   * @param {Polygon} polygon
   * @returns {Boolean}
   */
  containsLocation(latlng, polygon) {
    if (!Array.isArray(polygon)) return false;
    if (!this.isLoadGoogleMap()) return false;
    const paths = polygon.map((item) => this.getLatLng(item));
    const range = new google.maps.Polygon({ paths });
    return google.maps.geometry.poly.containsLocation(latlng, range);
  }
}
