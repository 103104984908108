import LazyLoadImage from "@/utils/lazyImage";
const lazyload = new LazyLoadImage();

function renderSrc(el) {
  el.setAttribute("data-src", el.src);
  el.setAttribute("src", lazyload._loadingImage);
  lazyload.add(el);
}

export default {
  mounted(el, binding) {
    const { value } = binding;

    if (value) {
      if (!(el instanceof HTMLImageElement)) {
        console.warn("v-lazy is only for img elements", el);
        return;
      }

      renderSrc(el);
    }
  },
  updated(el, binding) {
    const { value } = binding;

    if (value) {
      if (el.src && el.src !== el.dataset.src) {
        renderSrc(el);
      }
    }
  },
};
