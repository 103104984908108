import _defineProperty from "/builds/qMu61Dfa/0/gcp-backend/tea-h5/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.concat.js";
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "coupon-detail-info"
};
var _hoisted_2 = {
  class: "coupon-img"
};
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  class: "detail-title"
};
var _hoisted_5 = {
  key: 0,
  class: "detail-desc"
};
var _hoisted_6 = {
  key: 1,
  class: "detail-desc"
};
var _hoisted_7 = {
  class: "point"
};
var _hoisted_8 = {
  key: 2,
  class: "detail-tag"
};
import { priceUtil } from "@op/shared";
import { CouponStatusEnum, DiscountTypeEnum } from "@/constant/coupon";
import { formatPercent } from "@/utils/format";
import { computed, onMounted } from "vue";
import { useUserStore } from "@/store/userStore";
import { ossStyleProcess } from "@/utils/tools";
// import JsBarcode from "jsbarcode";

export default {
  __name: 'CouponDetailInfo',
  props: {
    isMy: {
      type: Boolean,
      default: false
    },
    coupon: {
      type: [Object],
      required: true
    }
  },
  setup: function setup(__props) {
    var userStore = useUserStore();
    var statusText = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, CouponStatusEnum.UNUSED, "Unused"), CouponStatusEnum.USED, "Used"), CouponStatusEnum.INEFFECTIVE, "Not activated"), CouponStatusEnum.EXPIRED, "Expired");
    var props = __props;
    var isFixedCoupon = computed(function () {
      return props.coupon.coupon_info.discount_type === DiscountTypeEnum.FIXED;
    });
    var title = computed(function () {
      var couponInfo = props.coupon.coupon_info;
      var discountRule = couponInfo.discount_rule;
      if (!props.isMy) {
        // 兑换页
        if (isFixedCoupon.value) {
          return "".concat(priceUtil.formatBaseUnitToShowPrice(discountRule.discount, discountRule.currency), " OFF (").concat(props.coupon.points_price, " Points)");
        }
        return "".concat(formatPercent(couponInfo.discount_rule.discount_percent), " OFF (").concat(props.coupon.points_price, " Points)");
      } else {
        // 使用页
        if (isFixedCoupon.value) {
          return "".concat(priceUtil.formatBaseUnitToShowPrice(discountRule.discount, discountRule.currency), " OFF (").concat(couponInfo.title, ")");
        }
        return "".concat(formatPercent(couponInfo.discount_rule.discount_percent), " OFF (").concat(couponInfo.title, ")");
      }
    });
    onMounted(function () {
      // if (props.coupon.coupon_info.coupon_id) {
      //   JsBarcode("#barcode", props.coupon.coupon_info?.coupon_id, {
      //     format: "CODE39", //选择要使用的条形码类型
      //     width: 1.75,
      //     height: 44, //高度
      //     displayValue: false, //是否在条形码下方显示文字
      //     textPosition: "bottom", //设置文本的垂直位置
      //     textMargin: 12, //设置条形码和文本之间的间距
      //     fontSize: 20, //设置文本的大小
      //     background: "#000", //设置条形码的背景
      //     lineColor: "#fff", //设置条和文本的颜色。
      //     margin: 0, //设置条形码周围的空白边距
      //     font: "DINPro",
      //   });
      // }
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
        alt: "",
        src: _unref(ossStyleProcess)(__props.coupon.picture || 'https://res.disoo.com/store/ca01be2f-009e-4fdb-86a3-515772ccca7b.png', 'type_3')
      }, null, 8, _hoisted_3)]), _createElementVNode("div", _hoisted_4, _toDisplayString(__props.coupon.title), 1), __props.isMy ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(__props.coupon.coupon_info.description), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", null, [_cache[0] || (_cache[0] = _createTextVNode(" Redeem with ")), _createElementVNode("span", _hoisted_7, _toDisplayString(__props.coupon.points_price), 1), _cache[1] || (_cache[1] = _createTextVNode(" points "))]), _createElementVNode("div", null, "You have " + _toDisplayString(_unref(userStore).getPoints) + " points", 1)])), __props.coupon.status ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("span", {
        class: _normalizeClass(__props.coupon.status)
      }, _toDisplayString(statusText[__props.coupon.status]), 3)])) : _createCommentVNode("", true)]);
    };
  }
};