import { createApp } from "vue";
import UserInfoInputPopup from "./UserInfoInputPopup.vue";

export class UserInfoInputDialog {
  // 实例记录
  static instance = null;

  // 调用方法
  static open(options) {
    const parent = document.createElement("div");
    document.body.appendChild(parent);

    if (this.instance) {
      try {
        this.instance.unmount();
      } catch (_) {
        this.instance = null;
      }
    }
    const app = createApp(UserInfoInputPopup, {
      show: true,
      ...options,
    });
    app.mount(parent);
    this.instance = app;
  }

  static close() {
    if (this.instance) {
      this.instance.unmount();
    }
  }
}
