export function getUrlParam(name, url) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function removeUrlParameter(url, parameter) {
  var urlParts = url.split("?");

  if (urlParts.length >= 2) {
    var urlParams = urlParts[1].split("&");

    for (var i = urlParams.length - 1; i >= 0; i--) {
      var paramName = urlParams[i].split("=")[0];

      if (paramName === parameter) {
        urlParams.splice(i, 1);
      }
    }
    url = urlParts[0] + (urlParams.length > 0 ? "?" + urlParams.join("&") : "");
    return url;
  } else {
    return url;
  }
}
