import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "order-customer-card"
};
var _hoisted_2 = {
  class: "status"
};
var _hoisted_3 = {
  class: "tag"
};
var _hoisted_4 = {
  class: "name"
};
var _hoisted_5 = {
  class: "contact"
};
var _hoisted_6 = {
  key: 0
};
var _hoisted_7 = {
  class: "info"
};
var _hoisted_8 = {
  class: "n"
};
var _hoisted_9 = {
  class: "p"
};
var _hoisted_10 = {
  class: "address"
};
var _hoisted_11 = {
  key: 1
};
var _hoisted_12 = {
  class: "info"
};
var _hoisted_13 = {
  class: "n"
};
var _hoisted_14 = {
  class: "p"
};
var _hoisted_15 = {
  class: "address"
};
import { OrderTypeEnum } from "@/constant/order";
import { computed } from "vue";
import { formatLocation } from "@/utils/format";
import { useShopStoreWithOut } from "@/store/shopStore";
export default {
  __name: 'OrderCustomerCard',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var isDelivery = computed(function () {
      return props.order.delivery_method === OrderTypeEnum.SELF_DELIVERY;
    });
    var shopStore = useShopStoreWithOut();
    // const getAddress = (addr) => {
    //   if (!addr) return;
    //   return [
    //     addr.label,
    //     addr.name,
    //     addr.telephone,
    //     addr.unit_no,
    //     addr.location?.detail,
    //     addr.location?.district,
    //     addr.location?.city,
    //     addr.location?.postcode,
    //     addr.location?.state,
    //     addr.location?.country,
    //   ]
    //     .filter((a) => !!a)
    //     .join(",");
    // };

    return function (_ctx, _cache) {
      var _props$order$op_stor, _props$order$deliver, _props$order$deliver2, _props$order$custome, _props$order$custome2, _unref$getShopData;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(isDelivery.value ? "Delivery" : "Pickup"), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(isDelivery.value ? " Delivered by" : "") + " " + _toDisplayString((_props$order$op_stor = __props.order.op_store) === null || _props$order$op_stor === void 0 ? void 0 : _props$order$op_stor.name), 1)]), _createElementVNode("div", _hoisted_5, [isDelivery.value ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString((_props$order$deliver = __props.order.delivery_address) === null || _props$order$deliver === void 0 ? void 0 : _props$order$deliver.name), 1), _createElementVNode("div", _hoisted_9, _toDisplayString((_props$order$deliver2 = __props.order.delivery_address) === null || _props$order$deliver2 === void 0 ? void 0 : _props$order$deliver2.mobile), 1)]), _createElementVNode("div", _hoisted_10, " Delivery to: " + _toDisplayString(_unref(formatLocation)(__props.order.delivery_address)), 1)])) : (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, _toDisplayString((_props$order$custome = __props.order.customer) === null || _props$order$custome === void 0 ? void 0 : _props$order$custome.name), 1), _createElementVNode("div", _hoisted_14, _toDisplayString((_props$order$custome2 = __props.order.customer) === null || _props$order$custome2 === void 0 ? void 0 : _props$order$custome2.mobile), 1)]), _createElementVNode("div", _hoisted_15, _toDisplayString(_unref(formatLocation)(((_unref$getShopData = _unref(shopStore).getShopData) === null || _unref$getShopData === void 0 ? void 0 : _unref$getShopData.location) || {})), 1)]))])]);
    };
  }
};