import { defineStore } from "pinia";
import store from "./index";
import { getAllDishes } from "@/api/shop";
import { solveDishesCategoryList, getDefaultSku } from "@/composable/useDishes";
import { hideLoading, showLoading } from "@/utils/loadingUtil";
import { useCartWithOut } from "./cartStore";
import { useEventBus } from "@vueuse/core";
import { SHOP_CHANGE_EVENT } from "@/constant/eventKey";
import { inProcssOrderStatics } from "@/api/order";
// import { isOverOpenTime } from "@/utils/tools";
import { ShopStatusEnum, ShopOpenStatusEnum } from "@/constant/shop";
import { useUserStoreWithOut } from "./userStore";
import { OrderTypeEnum } from "@/constant/order";
import { isNumber } from "@/utils/isUtil";
import { priceUtil } from "@op/shared";
const bus = useEventBus(SHOP_CHANGE_EVENT);

export const useShopStore = defineStore({
  id: "shop",
  state: () => {
    return {
      isFetchMenuData: false,
      shopId: "",
      shopName: "",
      country: "",
      shopData: null,
      waitOrderCount: 0,
      waitDishesCount: 0,
      dishesCategory: [],
      needSwitchShopToast: false,
      nextUpdateDishesDataTimer: null,
    };
  },
  getters: {
    getShopId(state) {
      return state.shopId;
    },
    getShopName(state) {
      return state.shopName;
    },
    getCountry(state) {
      return state.country;
    },
    getShopStatus(state) {
      const userStore = useUserStoreWithOut();
      // 未开启开关
      if (
        userStore.getOrderType === OrderTypeEnum.SELF_PICK_UP &&
        !state.shopData.self_pickup_setting?.is_accept
      ) {
        return ShopStatusEnum.NOT_SUPPORT_PICKUP;
      }
      if (
        userStore.getOrderType === OrderTypeEnum.SELF_DELIVERY &&
        !state.shopData.self_delivery_setting?.is_accept
      ) {
        return ShopStatusEnum.NOT_SUPPORT_DELIVERY;
      }

      // 不在营业时间
      // if (isOverOpenTime(state.shopData?.business_time || [])) {
      if (state.shopData?.status === ShopOpenStatusEnum.CLOSED) {
        return ShopStatusEnum.CLOSED;
      }

      return ShopStatusEnum.OPEN;
    },
    getIsShopClosed() {
      return [
        ShopStatusEnum.CLOSED,
        ShopStatusEnum.NOT_SUPPORT_DELIVERY,
        ShopStatusEnum.NOT_SUPPORT_PICKUP,
      ].includes(this.getShopStatus);
    },
    getShopData(state) {
      return state.shopData;
    },
    getWaitOrderCount(state) {
      return state.waitOrderCount;
    },
    getWaitDishesCount(state) {
      return state.waitDishesCount;
    },
    getDishesCategory(state) {
      return state.dishesCategory;
    },
    getIsFetchMenuData(state) {
      return state.isFetchMenuData;
    },
    getNeedSwitchShopToast(state) {
      return state.needSwitchShopToast;
    },
  },
  actions: {
    setShopId(val) {
      this.shopId = val;
    },
    setShopName(val) {
      this.shopName = val;
    },
    setShopStatus(val) {
      this.shopStatus = val;
    },
    setCountry(val) {
      this.country = val;
    },
    setShopData(val) {
      this.shopData = val;
    },
    setWaitOrderCount(val) {
      this.waitOrderCount = val;
    },
    setWaitDishesCount(val) {
      this.waitDishesCount = val;
    },
    setNeedSwitchShopToast(val) {
      this.needSwitchShopToast = val;
    },
    async switchShop(shopData, extendParams) {
      const preShopId = this.shopId;
      if (preShopId !== shopData.shop_id) {
        const cartStore = useCartWithOut();
        cartStore.clear();
        this.isFetchMenuData = false;
        this.setShopId(shopData.shop_id);
        this.setShopName(shopData.shop_name);
        this.setCountry(shopData.country);
        this.setShopData(shopData);
        this.loadShopOrderStatics();
        if (preShopId && extendParams?.showToast) {
          this.setNeedSwitchShopToast(true);
        }
        bus.emit();
      }
      if (shopData.currency_setting) {
        priceUtil.setSettings(shopData.currency_setting);
      }
    },
    async loadShopOrderStatics() {
      if (!this.shopId) return;
      const res = await inProcssOrderStatics({
        shop_id: this.shopId,
      });

      if (res.isOK) {
        this.setWaitOrderCount(res.data.order_count || 0);
        this.setWaitDishesCount(res.data.dishes_count || 0);
      }
    },
    async loadDishesData() {
      if (!this.shopId) return;
      showLoading();
      const res = await getAllDishes({
        shop_id: this.shopId,
      });
      hideLoading();
      if (res.isOK && res.data) {
        const resItems = res.data.items || [];
        const dishesCategoryFiltered = solveDishesCategoryList(resItems);
        this.dishesCategory = getDefaultSku(dishesCategoryFiltered);

        // 若有返回合法的下一个菜单生效时间，则开启定时器，到达该时间时自动重新加载菜品数据
        if (isNumber(res.data.next_update_ttl)) {
          const nextUpdateTimeout = res.data.next_update_ttl;
          if (nextUpdateTimeout > 0) {
            if (this.nextUpdateDishesDataTimer) {
              clearTimeout(this.nextUpdateDishesDataTimer);
            }
            this.nextUpdateDishesDataTimer = setTimeout(() => {
              this.loadDishesData();
            }, nextUpdateTimeout);
          }
        } else {
          if (this.nextUpdateDishesDataTimer) {
            clearTimeout(this.nextUpdateDishesDataTimer);
          }
          this.nextUpdateDishesDataTimer = null;
        }
      }
    },
    async loadShopData() {
      // 切换店铺时加载店铺数据，暂时没有
    },
    async loadMenuData() {
      // 进入menu页时加载菜品数据
      if (!this.shopId || this.isFetchMenuData) return;
      //initUtil中的switchShop操作和menuView中的mounted触发的loadMenuData似乎会导致loadDishesData请求两遍,待优化
      await Promise.all([this.loadDishesData()]);
      this.isFetchMenuData = true;
    },
  },
  persist: {
    enable: true,
    key: "SHOP",
    includes: ["shopId"],
  },
});

export function useShopStoreWithOut() {
  return useShopStore(store);
}
