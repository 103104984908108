import "core-js/modules/es.array.concat.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "account-features"
};
var _hoisted_2 = {
  class: "account-features-list"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  class: "item-title"
};
import ProfilePopup from "@/components/me/ProfilePopup.vue";
import PontHistoryPoupup from "@/components/points/PontHistoryPoupup.vue";
import SvgIcon from "@/components/base/SvgIcon.vue";
import { ref } from "vue";
import { useSupplierStore } from "@/store/supplierStore";
export default {
  __name: 'AccountFeature',
  setup: function setup(__props) {
    var supplierStore = useSupplierStore();
    var showProfile = ref(false);
    var showPointHistory = ref(false);
    var featureList = [{
      icon: "point",
      title: "Points History",
      // handler: toPointsHistory,
      handler: function handler() {
        return showPointHistory.value = true;
      },
      show: true
    },
    // {
    //   icon: 'faq',
    //   title: "FAQ",
    // },
    {
      icon: "profile",
      title: "My Profile",
      handler: function handler() {
        return showProfile.value = true;
      },
      show: true
    }, {
      icon: "phone",
      title: "Live Chat",
      handler: toLiveChat,
      show: supplierStore.getWhatsappid
    }];
    function toLiveChat() {
      window.location.href = "https://wa.me/".concat(supplierStore.getWhatsappid, "/?text=").concat(encodeURIComponent("Live Chat"));
    }
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "account-features-title"
      }, "Features", -1)), _createElementVNode("div", _hoisted_2, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(featureList, function (item, i) {
        return _withDirectives(_createElementVNode("div", {
          key: i,
          class: "account-features-item van-haptics-feedback",
          onClick: function onClick($event) {
            var _item$handler;
            return (_item$handler = item.handler) === null || _item$handler === void 0 ? void 0 : _item$handler.call(item);
          }
        }, [_createVNode(SvgIcon, {
          name: item.icon,
          size: 28,
          class: "item-img"
        }, null, 8, ["name"]), _createElementVNode("div", _hoisted_4, _toDisplayString(item.title), 1)], 8, _hoisted_3), [[_vShow, item.show]]);
      }), 64))]), _createVNode(ProfilePopup, {
        show: showProfile.value,
        "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
          return showProfile.value = $event;
        })
      }, null, 8, ["show"]), _createVNode(PontHistoryPoupup, {
        show: showPointHistory.value,
        "onUpdate:show": _cache[1] || (_cache[1] = function ($event) {
          return showPointHistory.value = $event;
        })
      }, null, 8, ["show"])]);
    };
  }
};