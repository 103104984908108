import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "section-title"
};
var _hoisted_2 = {
  class: "title"
};
export default {
  __name: 'SectionTitle',
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "line-left"
      }, null, -1)), _createElementVNode("div", _hoisted_2, _toDisplayString(__props.title), 1), _cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "line-right"
      }, null, -1))]);
    };
  }
};