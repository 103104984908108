import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/points/point_icon.png';
var _hoisted_1 = {
  class: "points-balance"
};
var _hoisted_2 = {
  class: "balance"
};
import { useUserStore } from "@/store/userStore";
import SvgIcon from "../base/SvgIcon.vue";
export default {
  __name: 'PointBalance',
  setup: function setup(__props) {
    var userStore = useUserStore();
    return function (_ctx, _cache) {
      var _component_van_rolling_text = _resolveComponent("van-rolling-text");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)), _createVNode(_component_van_rolling_text, {
        class: "point-rolling-text",
        "start-num": 0,
        "target-num": _unref(userStore).getPoints,
        duration: _unref(userStore).getPoints > 0 ? 1 : 0
      }, null, 8, ["target-num", "duration"]), _cache[1] || (_cache[1] = _createElementVNode("span", {
        class: "pts"
      }, "pts", -1))])]);
    };
  }
};