/**
 * Helper for apple sign in
 * 单例
 */
const appleSignInHelper = {
  auth: null,
  retryCount: 3, // 错误重试次数

  /**
   * apple sign in初始化
   * @public
   */
  async init(options = {}) {
    if (this.auth) {
      return;
    }

    await this.loadScript();

    let redirectURI = location.origin;
    if (process.env.NODE_ENV === "development") {
      redirectURI = "https://miniprogram-test.disoo.co";
    }

    this.auth.init({
      clientId: options.clientId,
      redirectURI,
      scope: "name email",
      usePopup: true,
    });

    // Listen for authorization success.
    document.addEventListener("AppleIDSignInOnSuccess", (event) => {
      if (options.onSuccess && typeof options.onSuccess === "function") {
        options.onSuccess(event.detail?.authorization);
      }
    });

    // Listen for authorization failures.
    document.addEventListener("AppleIDSignInOnFailure", (event) => {
      if (options.onError && typeof options.onError === "function") {
        options.onError(event.detail.error);
      }
    });
  },

  /**
   * 加载脚本
   * @private
   */
  async loadScript() {
    if (this.auth) {
      return;
    }

    const isLoaded = await new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src =
        "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
      script.async = true;
      script.onload = async () => {
        console.log("apple sign in script loaded");
        resolve(true);
      };

      script.onerror = (err) => {
        console.error(err);
        resolve(false);
      };

      document.getElementsByTagName("head")[0].appendChild(script);
    });

    if (isLoaded && window.AppleID) {
      // 初始化apple Auth对象
      this.auth = AppleID.auth;
      this.retryCount = 3;
    } else {
      if (this.retryCount === 0) {
        alert("load apple sign in script failed");
        throw new Error("load apple sign in script failed");
      } else {
        this.retryCount--;
        this.init({});
      }
    }
  },

  /**
   * 登录
   * @public
   * @return {Promise}
   */
  signIn() {
    return this.auth.signIn();
  },
};

export default appleSignInHelper;
