import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
import { isIOS, useTitle } from "@vueuse/core";
import { ref, watch } from "vue";
import { useRouter } from "./hooks/useRouter";
export default {
  __name: 'App',
  setup: function setup(__props) {
    var animationName = ref("");
    var _useRouter = useRouter(),
      router = _useRouter.router;
    var title = useTitle();
    watch(function () {
      return router.currentRoute.value;
    }, function (to, from) {
      if (to.meta.title) {
        title.value = to.meta.title;
      }
      if (!from || from.path === "/" || to.redirectedFrom) {
        animationName.value = "";
      } else if (!from.meta.disableAnimation && to.meta.disableAnimation && !isIOS) {
        animationName.value = "page-out";
      } else if (to.meta.disableAnimation && !window.isBack) {
        animationName.value = "";
      } else {
        if (window.isBack) {
          animationName.value = "page-out";
        } else {
          animationName.value = "page-in";
        }
      }
      window.isBack = false;
    }, {
      immediate: true
    });
    return function (_ctx, _cache) {
      var _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view, null, {
        default: _withCtx(function (_ref) {
          var Component = _ref.Component,
            route = _ref.route;
          return [_createVNode(_Transition, {
            name: animationName.value
          }, {
            default: _withCtx(function () {
              return [route.meta.keepAlive ? (_openBlock(), _createBlock(_KeepAlive, {
                key: 0
              }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024)) : (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                key: 1
              }))];
            }),
            _: 2
          }, 1032, ["name"])];
        }),
        _: 1
      });
    };
  }
};