import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "empty-list-placeholder"
};
import Button from "./base/Button.vue";
export default {
  __name: 'EmptyListPlaceholder',
  props: {
    text: {
      type: String,
      required: true
    },
    btntext: {
      type: String,
      default: ""
    }
  },
  emits: ["go"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var emit = __emit;
    var onClick = function onClick(e) {
      emit("go", e);
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("p", null, _toDisplayString(__props.text), 1), __props.btntext ? (_openBlock(), _createBlock(Button, {
        key: 0,
        class: "btn",
        onClick: onClick
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(__props.btntext), 1)];
        }),
        _: 1
      })) : _createCommentVNode("", true)]);
    };
  }
};