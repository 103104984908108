/**
 * Helper for google sign in
 * 单例
 */
const googleSignInHelper = {
  _google: null,
  retryCount: 3, // 错误重试次数
  _options: {},
  /**
   * google sign in资源加载
   */
  async load() {
    if (this._google) {
      return;
    }

    await this.loadScript();
  },

  async init(options = {}) {
    this._options = Object.assign(this._options, options);
    await this.load();
    if (!this._google) return;
    this._google.accounts.id.initialize({
      client_id: this._options.clientId,
      callback:
        typeof this._options.callback === "function"
          ? this._options.callback
          : () => {},
    });
  },

  retry(msg) {
    if (this.retryCount === 0) {
      alert(msg);
      console.error(msg);
    } else {
      this.retryCount--;
      this.init({});
    }
  },

  /**
   * 加载脚本
   * @private
   */
  async loadScript() {
    if (this._google) {
      return;
    }

    const isLoaded = await new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.onload = async () => {
        resolve(true);
      };

      script.onerror = (err) => {
        resolve(false);
        // console.error("[get google sign in script error]", err.message);
      };

      document.getElementsByTagName("head")[0].appendChild(script);
    });

    if (isLoaded && window.google) {
      // 初始化GoogleAuth对象
      this.retryCount = 3;
      this._google = window.google;
    } else {
      this.retry(
        "load Google sign in script failed, please check whether your current network is normal, you can try refreshing the page"
      );
    }
  },

  /**
   * 渲染谷歌登录按钮
   */
  renderButton(id) {
    if (!this._google) return;
    this._google.accounts.id.renderButton(document.getElementById(id), {
      theme: "outline",
      size: "large",
      shape: "pill",
      logo_alignment: "center",
      width: 320,
    });
  },

  /**
   * 谷歌一键式登录
   */
  promptOneTap() {
    this._google.accounts.id.prompt();
  },
};

export default googleSignInHelper;
