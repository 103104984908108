import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import MyRewardList from "@/components/reward/MyRewardList.vue";
import CouponDetailPopup from "@/components/reward/CouponDetailPopup.vue";
import { ref } from "vue";
import { useRoute } from "vue-router";
import { watch } from "vue";
import { useRouter } from "@/hooks/useRouter";
export default {
  __name: 'MyCouponList',
  setup: function setup(__props) {
    var _useRouter = useRouter(),
      routerPush = _useRouter.routerPush,
      routerBack = _useRouter.routerBack;
    var route = useRoute();
    var activeName = ref(route.query.type);
    watch(function () {
      return route.query.type;
    }, function () {
      activeName.value = route.query.type;
    });
    var curItem = ref(null);
    var showDetail = ref(false);
    var toDetail = function toDetail(item) {
      curItem.value = item;
      showDetail.value = true;
    };

    // 页面后退
    function handlePageBack() {
      if (route.query.back_to_home) {
        routerPush({
          name: "Index"
        });
      } else {
        routerBack();
      }
    }
    return function (_ctx, _cache) {
      var _component_van_tab = _resolveComponent("van-tab");
      var _component_van_tabs = _resolveComponent("van-tabs");
      var _component_Page = _resolveComponent("Page");
      return _openBlock(), _createBlock(_component_Page, {
        title: "Vouchers",
        class: "my-coupon-list",
        "on-back": handlePageBack
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_van_tabs, {
            active: activeName.value,
            "onUpdate:active": _cache[0] || (_cache[0] = function ($event) {
              return activeName.value = $event;
            }),
            class: "my-coupon-list_tabs"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_van_tab, {
                title: "Unused",
                name: "unused"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(MyRewardList, {
                    type: "unused",
                    onItemClick: toDetail
                  })];
                }),
                _: 1
              }), _createVNode(_component_van_tab, {
                title: "History",
                name: "history"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(MyRewardList, {
                    type: "history",
                    onItemClick: toDetail
                  })];
                }),
                _: 1
              })];
            }),
            _: 1
          }, 8, ["active"]), _createVNode(CouponDetailPopup, {
            show: showDetail.value,
            "onUpdate:show": _cache[1] || (_cache[1] = function ($event) {
              return showDetail.value = $event;
            }),
            from: "MyCouponList",
            coupon: curItem.value,
            type: "my"
          }, null, 8, ["show", "coupon"])];
        }),
        _: 1
      });
    };
  }
};