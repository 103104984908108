import _defineProperty from "/builds/qMu61Dfa/0/gcp-backend/tea-h5/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "menu-tip"
};
import { ShopStatusEnum } from "@/constant/shop";
import { useShopStore } from "@/store/shopStore";
export default {
  __name: 'MenuTip',
  setup: function setup(__props) {
    var shopStore = useShopStore();
    var text = _defineProperty(_defineProperty(_defineProperty({}, ShopStatusEnum.CLOSED, "Store is not in business status"), ShopStatusEnum.NOT_SUPPORT_DELIVERY, "Store does not support delivery"), ShopStatusEnum.NOT_SUPPORT_PICKUP, "Store does not support self-pickup");
    return function (_ctx, _cache) {
      return _unref(shopStore).getIsShopClosed ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(text[_unref(shopStore).getShopStatus]), 1)) : _createCommentVNode("", true);
    };
  }
};