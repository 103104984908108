export const DISH_STATUS = {
  IN_STOCK: "in_stock",
  OUT_STOCK: "out_of_stock_today",
  DELISTED: "delisted",
};

export const DishSpecStatusEnum = {
  SINGLE: "single",
  MULTI: "multi",
};
