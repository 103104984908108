export const ErrorType = {
  Sync: "sync", // 同步代码错误
  Resource: "resource", // 资源加载错误
  Promise: "promise", // Promise Uncaught错误
  Websocket: "websocket", // Websocket onerror
};

export const WarnedErrorType = {
  NETWORK_ERROR: "Network Error", // api network error
  ECONNABORTED: "ECONNABORTED", // api request timeout
  RESOURCE: "RESOURCE", // 资源加载异常
  GOOGLE_SCRIPT_ERROR: "Google Script error", // 谷歌登录、地图脚本在内网加载失败的
};

export const FilterErrorReportType = {
  IMG_RESOURCE: "IMG_RESOURCE", // 图片加载异常不上报
  FILTER_NOT_LOGIN: "FILTER_NOT_LOGIN", // token过期时的报错信息不上报
  RESIZEOBSERVER_LOOP_LIMIT_EXCEEDED: "RESIZEOBSERVER_LOOP_LIMIT_EXCEEDED", // This error means that ResizeObserver was not able to deliver all observations within a single animation frame. It is benign (your site will not break).
  SCRIPT_ERROR: "SCRIPT_ERROR", // 第三方脚本内部的报错不上报
  LOGIN_ACTION: "LOGIN_ACTION", // 第三方登录用户主动行为的报错不上报
};
