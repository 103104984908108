export const DiscountTypeEnum = {
  FIXED: "fixed",
  PERCENT: "percent",
};

export const CouponStatusEnum = {
  INEFFECTIVE: "ineffective",
  UNUSED: "unused",
  USED: "used",
  EXPIRED: "expired",
};

export const CouponResourceEnum = {
  NEWCOMER_ACTIVITY: "newcomer_activity",
  DIRECT: "direct",
  POINTS_MALL: "points_mall",
  SHARING_ACTIVITY: "sharing_activity",
  SEND_COUPON_ACTIVITY: "send_coupon_activity",
  RANDOM_COUPON_ACTIVITY: "random_coupon_activity",
  BIRTHDAY_ACTIVITY: "birthday_activity",
};

export const RedirectPageTypeMap = {
  applet_home_page: "Index",
  member_code_page: "MemberQrCode",
  account_page: "Account",
};

export const QRCodeTypeEnum = {
  USER_CODE: "uc", // 会员页的二维码类型
};

export const CouponUseOrderType = {
  SELF_DELIVERY: "self-delivery",
  DINE_IN: "dine-in",
  TAKEAWAY: "takeaway",
  SELF_PICK_UP: "self-pickup",
};

export const UsingTimeRuleTypeEnum = {
  AFTER_SEND: "after_send",
  FIXED: "fixed",
};
