import "core-js/modules/es.number.constructor.js";
import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default {
  __name: 'Gap',
  props: {
    h: {
      type: Number,
      required: true
    }
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: "base-gap",
        style: _normalizeStyle({
          height: __props.h + 'rem'
        })
      }, null, 4);
    };
  }
};