import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "comp-client-login"
};
var _hoisted_2 = {
  class: "login-content"
};
var _hoisted_3 = {
  key: 1,
  class: "coming"
};
import GoogleSignInButton from "./GoogleSignInButton.vue";
import AppleSignInButton from "./AppleSignInButton.vue";
import MobileSignInButton from "./MobileSignInButton.vue";
import { onMounted } from "vue";
import { ref } from "vue";
import { useUserStore } from "@/store/userStore";
import { useSupplierStore } from "@/store/supplierStore";
export default {
  __name: 'LoginPopup',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    onSuccess: {
      type: Function,
      default: function _default() {
        return {};
      }
    },
    onError: {
      type: Function,
      default: function _default() {
        return {};
      }
    }
  },
  setup: function setup(__props) {
    var supplierStore = useSupplierStore();
    var userStore = useUserStore();
    var props = __props;
    var showPopup = ref(false);
    function handleGoogleLoginCompleted(res) {
      userStore.thirdLogin({
        token: res.token,
        third_platform: "google"
      }).then(function () {
        props.onSuccess && props.onSuccess();
        showPopup.value = false;
      }).catch(function (e) {
        props.onError && props.onError(e);
      });
    }
    function handleAppleLoginCompleted(data) {
      userStore.thirdLogin({
        token: data.id_token,
        third_platform: "apple"
      }).then(function () {
        showPopup.value = false;
        props.onSuccess && props.onSuccess();
      }).catch(function (e) {
        props.onError && props.onError(e);
      });
    }
    function handleMobileLoginCompleted() {
      showPopup.value = false;
      props.onSuccess && props.onSuccess();
    }
    function hide() {
      showPopup.value = false;
    }
    onMounted(function () {
      if (props.show) {
        showPopup.value = true;
      }
    });
    return function (_ctx, _cache) {
      var _component_Gap = _resolveComponent("Gap");
      var _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
        show: showPopup.value,
        "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
          return showPopup.value = $event;
        }),
        "safe-area-inset-bottom": "",
        round: "",
        position: "bottom",
        "lazy-render": false
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", {
            class: "login-header"
          }, [_cache[1] || (_cache[1] = _createElementVNode("div", {
            class: "header-title"
          }, "Log in to Continue", -1)), _createElementVNode("div", {
            class: "header-action",
            onClick: hide
          }, "Cancel")]), _createElementVNode("div", _hoisted_2, [_unref(supplierStore).getIsLoginOpen ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 0
          }, [_unref(supplierStore).getIsGoogleLoginOpen ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 0
          }, [_createVNode(GoogleSignInButton, {
            id: "googleSignInButton",
            onSuccess: handleGoogleLoginCompleted
          }), _createVNode(_component_Gap, {
            h: 0.16
          })], 64)) : _createCommentVNode("", true), _unref(supplierStore).getIsAppleLoginOpen ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 1
          }, [_createVNode(AppleSignInButton, {
            onSuccess: handleAppleLoginCompleted
          }), _createVNode(_component_Gap, {
            h: 0.16
          })], 64)) : _createCommentVNode("", true), _unref(supplierStore).getIsMobileLoginOpen ? (_openBlock(), _createBlock(MobileSignInButton, {
            key: 2,
            onSuccess: handleMobileLoginCompleted
          })) : _createCommentVNode("", true)], 64)) : (_openBlock(), _createElementBlock("div", _hoisted_3, "Coming soon"))])];
        }),
        _: 1
      }, 8, ["show"])]);
    };
  }
};