import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/images/back.svg';
export default {
  __name: 'Navbar',
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  emits: ["back"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var emit = __emit;
    var onBack = function onBack() {
      emit("back");
    };
    return function (_ctx, _cache) {
      var _component_van_nav_bar = _resolveComponent("van-nav-bar");
      return _openBlock(), _createBlock(_component_van_nav_bar, {
        class: "nav-bar",
        title: __props.title,
        fixed: "",
        onClickLeft: onBack
      }, {
        left: _withCtx(function () {
          return _cache[0] || (_cache[0] = [_createElementVNode("img", {
            src: _imports_0,
            class: "arrow"
          }, null, -1)]);
        }),
        _: 1
      }, 8, ["title"]);
    };
  }
};