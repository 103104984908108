const Mixins = {
  data() {
    return {};
  },
  computed: {
    isAgentChannel() {
      // 是否是合作方, 当前域名不等于orderpin的域名
      return (
        process.env.VUE_APP_ORDERPIN_HOST_NAME !== window.location.hostname
      );
    },
  },
};
export default Mixins;
