import { defineStore } from "pinia";

export const useListStore = defineStore({
  id: "list",
  state: () => {
    return {
      orderListScrollTop: 0,
      activityHistoryListScrollTop: 0,
    };
  },
  getters: {},
  actions: {
    setOrderListScrollTop(scrollTop) {
      this.orderListScrollTop = scrollTop;
    },
    setActivityHistoryListScrollTop(scrollTop) {
      this.activityHistoryListScrollTop = scrollTop;
    },
  },
});
