import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "account-asset"
};
var _hoisted_2 = {
  class: "account-my-list"
};
var _hoisted_3 = {
  class: "item-header"
};
var _hoisted_4 = {
  class: "price"
};
var _hoisted_5 = {
  class: "currency"
};
var _hoisted_6 = {
  class: "item-header"
};
var _hoisted_7 = {
  class: "ununsed"
};
var _hoisted_8 = {
  class: "ununsed-number"
};
var _hoisted_9 = {
  class: "item-header"
};
var _hoisted_10 = {
  class: "ununsed"
};
var _hoisted_11 = {
  class: "ununsed-number"
};
import { PriceStrCurrencySymbolEnum } from "@/constant/currency";
import { useRouter } from "@/hooks/useRouter";
import { useUserStore } from "@/store/userStore";
import { priceUtil } from "@op/shared";
export default {
  __name: 'AccountAsset',
  setup: function setup(__props) {
    var userStore = useUserStore();
    var _useRouter = useRouter(),
      routerPush = _useRouter.routerPush;
    var toMyBalance = function toMyBalance() {
      routerPush({
        name: "MyBalance"
      });
    };
    var toRewards = function toRewards() {
      routerPush({
        name: "Rewards"
      });
    };
    function toMyCounponList() {
      routerPush({
        name: "MyCouponList"
      });
    }
    return function (_ctx, _cache) {
      var _component_SvgIcon = _resolveComponent("SvgIcon");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[8] || (_cache[8] = _createElementVNode("div", {
        class: "account-my-title"
      }, "My Account", -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "item",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return toMyBalance();
        })
      }, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_SvgIcon, {
        name: "wallet",
        size: 20,
        class: "item-img"
      }), _cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "item-text"
      }, "Wallet", -1))]), _createElementVNode("div", _hoisted_4, [_createElementVNode("span", _hoisted_5, _toDisplayString(_unref(PriceStrCurrencySymbolEnum)[_unref(userStore).getWalletCurrency]), 1), _createTextVNode(_toDisplayString(_unref(priceUtil).formatBaseUnitToShowPrice(_unref(userStore).getWallet, _unref(userStore).getWalletCurrency, false)), 1)])]), _createElementVNode("div", {
        class: "item",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return toRewards();
        })
      }, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_SvgIcon, {
        name: "point",
        size: 20,
        class: "item-img"
      }), _cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "item-text"
      }, "Points", -1))]), _createElementVNode("div", _hoisted_7, [_createElementVNode("span", _hoisted_8, _toDisplayString(_unref(userStore).getPoints), 1), _cache[5] || (_cache[5] = _createTextVNode("unused "))])]), _createElementVNode("div", {
        class: "item",
        onClick: _cache[2] || (_cache[2] = function ($event) {
          return toMyCounponList();
        })
      }, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_SvgIcon, {
        name: "voucher",
        size: 20,
        class: "item-img"
      }), _cache[6] || (_cache[6] = _createElementVNode("div", {
        class: "item-text"
      }, "Vouchers", -1))]), _createElementVNode("div", _hoisted_10, [_createElementVNode("span", _hoisted_11, _toDisplayString(_unref(userStore).getVouchers), 1), _cache[7] || (_cache[7] = _createTextVNode("unused "))])])])]);
    };
  }
};