import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "sticky-footer"
};
var _hoisted_2 = {
  class: "sticky-footer_content"
};
var _hoisted_3 = {
  class: "sticky-footer_footer"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default")]), _createElementVNode("footer", _hoisted_3, [_renderSlot(_ctx.$slots, "footer")])]);
}