import { createApp } from "vue";
import Confirm from "./src/Confirm.vue";
import PwaGuideInIos from "./src/PwaGuideInIos.vue";
let parent = null;
class Dialog {
  // 实例记录
  static confirmInstance = null;
  static pwaGuideInIosInstance = null;

  // 调用方法
  static confirm(option) {
    if (parent) {
      try {
        parent.remove();
      } catch (_) {
        parent = null;
      }
    }

    parent = document.createElement("div");
    document.body.appendChild(parent);

    if (Dialog.confirmInstance) {
      try {
        Dialog.confirmInstance.unmount();
      } catch (_) {
        Dialog.confirmInstance = null;
      }
    }
    // eslint-disable-next-line vue/one-component-per-file
    const app = createApp(Confirm, {
      visible: false,
      showAfterMounted: true,
      title: option.title,
      desc: option.desc,
      descClass: option.descClass,
      danger: option.danger,
      success: option.success,
      onOk: option.onOk,
      onCancel: option.onCancel,
      okText: option.okText,
      cancelText: option.cancelText,
      withoutCancel: option.withoutCancel,
      confirmBorder: option.confirmBorder,
      zIndex: option.zIndex,
    });

    app.mount(parent);

    Dialog.confirmInstance = app;
  }

  static pwaGuidInIos() {
    const parent = document.createElement("div");
    document.body.appendChild(parent);

    if (this.pwaGuideInIosInstance) {
      this.pwaGuideInIosInstance.unmount();
    }
    // eslint-disable-next-line vue/one-component-per-file
    const app = createApp(PwaGuideInIos, {
      visible: false,
      showAfterMounted: true,
    });
    app.mount(parent);
    this.pwaGuideInIosInstance = app;
  }
}

export default Dialog;
