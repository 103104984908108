import _objectSpread from "/builds/qMu61Dfa/0/gcp-backend/tea-h5/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { defineComponent, computed } from "vue";
export default defineComponent({
  name: "SvgIcon",
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: [Number, String],
      default: ""
    },
    color: {
      type: String,
      default: ""
    }
  },
  setup: function setup(props) {
    var getStyle = computed(function () {
      var size = props.size,
        color = props.color;
      var s = "".concat(size / 100);
      s = "".concat(s.replace("px", ""), "rem");
      return _objectSpread(_objectSpread({}, size ? {
        width: s,
        height: s
      } : {}), color ? {
        color: color
      } : {});
    });
    return {
      getStyle: getStyle
    };
  }
});