import WebTracker from "@/utils/webTracker/webTracker";

let tracker;

export function setupWebTrack(app) {
  tracker = new WebTracker({
    app,
  });
}

export function useTracker() {
  return tracker;
}
