import { Persistent } from "@/utils/cache/persistent";
import { isArray } from "@/utils/isUtil";
// import { PiniaPluginContext } from "pinia";
import { toRaw } from "vue";

// type Store = PiniaPluginContext['store'];

// interface PersistOption {
//   enable: boolean;
//   key: string;
//   includes?: string[];
// }

// declare module 'pinia' {
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
//   export interface DefineStoreOptionsBase<S, Store> {
//     persist?: PersistOption;
//   }
// }

export const updateStorage = (key, store, includes) => {
  const storeData = toRaw(store.$state);
  if (isArray(includes)) {
    const storageResult = {};
    includes.forEach((key) => (storageResult[key] = storeData[key]));
    Persistent.setLocal(key, storageResult);
  } else {
    Persistent.setLocal(key, storeData);
  }
};

export default ({ options, store }) => {
  if (options.persist?.enable) {
    const storeKey = options.persist.key;
    const storageResult = Persistent.getLocal(storeKey);
    if (storageResult) {
      if (isArray(options.persist?.includes)) {
        const newStore = toRaw(store.$state);
        options.persist?.includes.forEach((key) => {
          if (storageResult[key] !== undefined) {
            newStore[key] = storageResult[key];
          }
        });
        store.$patch(newStore);
      } else {
        store.$patch(storageResult);
      }
      updateStorage(storeKey, store, options.persist?.includes);
    }
    store.$subscribe(
      () => {
        updateStorage(storeKey, store, options.persist?.includes);
      },
      {
        detached: true,
      }
    );
  }
};
