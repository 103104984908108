import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from '@/assets/images/reward.png';
var _hoisted_1 = {
  class: "order-status-card"
};
var _hoisted_2 = {
  class: "subtitle"
};
var _hoisted_3 = {
  key: 0,
  class: "code"
};
var _hoisted_4 = {
  key: 1,
  class: "reward-points-wrap"
};
var _hoisted_5 = {
  class: "desc"
};
var _hoisted_6 = {
  key: 2,
  class: "progress"
};
var _hoisted_7 = {
  class: "imgs"
};
var _hoisted_8 = {
  class: "icon-line"
};
var _hoisted_9 = {
  key: 0,
  class: "dashed"
};
var _hoisted_10 = {
  class: "text"
};
import { OrderStatusEnum, OrderTypeEnum } from "@/constant/order";
import { computed } from "vue";
import SvgIcon from "../base/SvgIcon.vue";
export default {
  __name: 'OrderStatusCard',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var isCancelled = computed(function () {
      return [OrderStatusEnum.CANCELLED, OrderStatusEnum.TO_DELIVER_CANCELLED, OrderStatusEnum.DELIVERING_CANCELLED, OrderStatusEnum.READY_CANCELLED, OrderStatusEnum.REJECT_CANCELLED].includes(props.order.status);
    });
    var isRefunded = computed(function () {
      return props.order.status === OrderStatusEnum.REFUNDED;
    });
    var descFromStatus = computed(function () {
      var status = props.order.status;
      var method = props.order.delivery_method;
      if (status === OrderStatusEnum.TO_ACCEPT) {
        return "Waiting for the store to accept the order";
      }
      if (status === OrderStatusEnum.PREPARING) {
        return "Kitchen is crafting your tea";
      }
      if (status === OrderStatusEnum.READY) {
        return method === OrderTypeEnum.SELF_DELIVERY ? "Your meal has been prepared,Please wait for deliver" : "Your meal has been prepared,please pick it up";
      }
      if (status === OrderStatusEnum.OUT_OF_DELIVERY) {
        return "Your order is currently being delivered";
      }
      if (status === OrderStatusEnum.COMPLETED) {
        return method === OrderTypeEnum.SELF_DELIVERY ? "Your order has been delivered" : "Your order has been completed";
      }
      return "";
    });
    var statusList = computed(function () {
      var method = props.order.delivery_method;
      if (method === OrderTypeEnum.SELF_DELIVERY) {
        return [{
          status: OrderStatusEnum.TO_ACCEPT,
          text: "Order placed",
          icon: "ordered",
          iconSelected: "ordered_selected"
        }, {
          status: OrderStatusEnum.PREPARING,
          text: "In the kitchen",
          icon: "preparing",
          iconSelected: "preparing_selected"
        }, {
          status: OrderStatusEnum.OUT_OF_DELIVERY,
          text: "In delivery",
          icon: "delivering",
          iconSelected: "delivering_selected"
        }, {
          status: OrderStatusEnum.COMPLETED,
          text: "Delivered",
          icon: "completed",
          iconSelected: "completed_selected"
        }];
      } else {
        return [{
          status: OrderStatusEnum.TO_ACCEPT,
          text: "Order placed",
          icon: "ordered",
          iconSelected: "ordered_selected"
        }, {
          status: OrderStatusEnum.PREPARING,
          text: "In the kitchen",
          icon: "preparing",
          iconSelected: "preparing_selected"
        }, {
          status: OrderStatusEnum.READY,
          text: "Ready to pickup",
          icon: "ready",
          iconSelected: "ready_selected"
        }, {
          status: OrderStatusEnum.COMPLETED,
          text: "Picked up",
          icon: "completed",
          iconSelected: "completed_selected"
        }];
      }
    });
    var rewardPointsText = computed(function () {
      if (!props.order.order_reward_points) {
        return "";
      }
      if ([OrderStatusEnum.COMPLETED].includes(props.order.status)) {
        return "Earn ".concat(props.order.order_reward_points, " points for this transaction");
      } else if ([OrderStatusEnum.TO_ACCEPT, OrderStatusEnum.READY, OrderStatusEnum.PREPARING, OrderStatusEnum.OUT_OF_DELIVERY].includes(props.order.status)) {
        return "Earn ".concat(props.order.order_reward_points, " points after the order is completed");
      } else {
        return "";
      }
    });
    return function (_ctx, _cache) {
      var _props$order$wait_in, _props$order$wait_in2, _props$order$wait_in3, _props$order$wait_in4;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [__props.order.status === _unref(OrderStatusEnum).TO_PAY ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "title"
      }, "Waiting for payment", -1)), _cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "subtitle"
      }, "Please pay for this order", -1))], 64)) : _createCommentVNode("", true), isCancelled.value ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "title"
      }, "Order Cancelled", -1)), _createElementVNode("div", _hoisted_2, _toDisplayString(__props.order.cancel_reason || "The order has been cancelled"), 1)], 64)) : isRefunded.value ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 2
      }, [_cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "title"
      }, "Order Refunded", -1)), _cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "subtitle"
      }, "The order has been refunded", -1))], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 3
      }, [__props.order.meal_number ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(__props.order.meal_number), 1)) : _createCommentVNode("", true), rewardPointsText.value ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_cache[5] || (_cache[5] = _createElementVNode("img", {
        class: "icon-reward",
        src: _imports_0,
        alt: ""
      }, null, -1)), _createElementVNode("span", null, _toDisplayString(rewardPointsText.value), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_5, _toDisplayString(descFromStatus.value), 1), __props.order.status === _unref(OrderStatusEnum).PREPARING && (_props$order$wait_in = __props.order.wait_info) !== null && _props$order$wait_in !== void 0 && _props$order$wait_in.order_count && (_props$order$wait_in2 = __props.order.wait_info) !== null && _props$order$wait_in2 !== void 0 && _props$order$wait_in2.dishes_count ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("span", null, _toDisplayString((_props$order$wait_in3 = __props.order.wait_info) === null || _props$order$wait_in3 === void 0 ? void 0 : _props$order$wait_in3.order_count), 1), _cache[6] || (_cache[6] = _createTextVNode(" orders / ")), _createElementVNode("span", null, _toDisplayString((_props$order$wait_in4 = __props.order.wait_info) === null || _props$order$wait_in4 === void 0 ? void 0 : _props$order$wait_in4.dishes_count), 1), _cache[7] || (_cache[7] = _createTextVNode(" cups before your order "))])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(statusList.value, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: item.text,
          class: _normalizeClass(["img", {
            active: __props.order.status === item.status
          }])
        }, [_createElementVNode("div", _hoisted_8, [_createVNode(SvgIcon, {
          class: "icon",
          name: __props.order.status === item.status ? item.iconSelected : item.icon,
          size: "32"
        }, null, 8, ["name"]), index < statusList.value.length - 1 ? (_openBlock(), _createElementBlock("div", _hoisted_9)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_10, _toDisplayString(item.text), 1)], 2);
      }), 128))])], 64))]);
    };
  }
};