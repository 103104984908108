import "core-js/modules/es.array.concat.js";
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue";
import _imports_0 from '@/assets/images/verified.png';
var _hoisted_1 = {
  class: "my-profile"
};
var _hoisted_2 = {
  class: "my-profile_form"
};
var _hoisted_3 = {
  class: "avatar"
};
var _hoisted_4 = {
  class: "my-profile_form-item"
};
var _hoisted_5 = {
  class: "value"
};
var _hoisted_6 = {
  class: "my-profile_form-item"
};
var _hoisted_7 = {
  class: "value"
};
var _hoisted_8 = {
  class: "my-profile_form-item"
};
var _hoisted_9 = {
  key: 0,
  class: "value"
};
var _hoisted_10 = {
  class: "inner-value"
};
var _hoisted_11 = {
  key: 0,
  src: _imports_0,
  alt: ""
};
var _hoisted_12 = {
  class: "my-profile_form-item"
};
var _hoisted_13 = {
  key: 0,
  class: "value"
};
import PagePopupLayout from "@/layouts/PagePopupLayout.vue";
import { computed } from "vue";
import StickyPowerBy from "../StickyPowerBy.vue";
import { useUserStore } from "@/store/userStore";
import accountAvatar from "@/assets/images/default_avatar.png";
import Image from "@/components/base/Image.vue";
import { PopupPageKey } from "@/constant/pageKey";
import { formatDateWithTZ } from "@/utils/format";
import BirthdayChange from "./BirthdayChange.vue";
import PhoneBind from "./PhoneBind.vue";
export default {
  __name: 'ProfilePopup',
  props: {
    show: {
      type: Boolean,
      required: false
    }
  },
  emits: ["update:show"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var showPopup = computed({
      get: function get() {
        return props.show;
      },
      set: function set(val) {
        emit("update:show", val);
      }
    });
    var userStore = useUserStore();
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(PagePopupLayout, {
        show: showPopup.value,
        "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
          return showPopup.value = $event;
        }),
        "is-yellow-bg": "",
        title: "My Profile",
        "page-key": _unref(PopupPageKey).PROFILE
      }, {
        default: _withCtx(function () {
          return [_createVNode(StickyPowerBy, null, {
            default: _withCtx(function () {
              return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(Image, {
                src: _unref(userStore).getUserInfo.avatar ? _unref(userStore).getUserInfo.avatar : _unref(accountAvatar),
                alt: "",
                "oss-stylename": "type_5"
              }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_4, [_cache[1] || (_cache[1] = _createElementVNode("span", {
                class: "label"
              }, "User ID", -1)), _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(userStore).getUserInfo.customer_id), 1)]), _createElementVNode("div", _hoisted_6, [_cache[2] || (_cache[2] = _createElementVNode("span", {
                class: "label"
              }, "Name", -1)), _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(userStore).getUserInfo.nickname), 1)]), _createElementVNode("div", _hoisted_8, [_cache[3] || (_cache[3] = _createElementVNode("span", {
                class: "label"
              }, "Phone", -1)), !!_unref(userStore).getUserInfo.telephone ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createElementVNode("span", null, _toDisplayString(_unref(userStore).getUserInfo.area_code ? "+".concat(_unref(userStore).getUserInfo.area_code, " ").concat(_unref(userStore).getUserInfo.telephone) : _unref(userStore).getUserInfo.telephone), 1), _unref(userStore).getUserInfo.phone_is_verified ? (_openBlock(), _createElementBlock("img", _hoisted_11)) : _createCommentVNode("", true)])])) : (_openBlock(), _createBlock(PhoneBind, {
                key: 1,
                class: "value-placeholder"
              }))]), _createElementVNode("div", _hoisted_12, [_cache[4] || (_cache[4] = _createElementVNode("span", {
                class: "label"
              }, "Birthday", -1)), !!_unref(userStore).getUserInfo.birthday ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_unref(formatDateWithTZ)(_unref(userStore).getUserInfo.birthday, "YYYY-MM-DD")), 1)) : (_openBlock(), _createBlock(BirthdayChange, {
                key: 1,
                class: "value-placeholder"
              }))])])])];
            }),
            _: 1
          })];
        }),
        _: 1
      }, 8, ["show", "page-key"]);
    };
  }
};