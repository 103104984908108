import { showNotify } from "vant";

export class Notify {
  static success(msg) {
    showNotify({
      type: "success",
      message: msg,
      background: "#DBFFDF",
      color: "#48A537",
    });
  }
  static warning(msg) {
    showNotify({
      type: "warning",
      message: msg,
      background: "#FFF7D1",
      color: "#C68C16",
    });
  }
  static error(msg) {
    showNotify({
      type: "error",
      message: msg,
      background: "#FFE2DE",
      color: "#D11016",
    });
  }
}
