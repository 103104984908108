import _defineProperty from "/builds/qMu61Dfa/0/gcp-backend/tea-h5/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.replace.js";
import { createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "page-popup"
};
var _hoisted_2 = {
  key: 0,
  class: "page-popup_footer"
};
import Navbar from "@/components/Navbar.vue";
// import { isIOS } from "@vueuse/core";
import { watch, nextTick, ref, computed, onMounted, onBeforeUnmount } from "vue";
import { isFunction } from "@/utils/isUtil";
import { addQueryString } from "@/utils/tools";
export default {
  __name: 'PagePopupLayout',
  props: {
    title: {
      type: String,
      default: ""
    },
    show: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: false
    },
    isYellowBg: {
      type: Boolean,
      default: false
    },
    contentClass: {
      type: String,
      default: ""
    },
    pageKey: {
      type: String,
      default: ""
    }
  },
  emits: ["update:show"],
  setup: function setup(__props, _ref) {
    var __expose = _ref.expose,
      __emit = _ref.emit;
    var props = __props;
    var disableAnimation = ref(false);
    var pagePopupContent = ref();
    var emit = __emit;
    var showPopup = computed({
      get: function get() {
        return props.show;
      },
      set: function set(val) {
        emit("update:show", val);
      }
    });

    // if (isIOS) {
    //   watch(showPopup, () => {
    //     if (showPopup.value) {
    //       disableAnimation.value = false;
    //     } else {
    //       disableAnimation.value = true;
    //     }
    //   });
    // }
    // const forceAnimation = ref(false);
    var onBack = function onBack() {
      showPopup.value = false;
      // if (isIOS) {
      //   forceAnimation.value = true;
      //   setTimeout(() => {
      //     forceAnimation.value = false;
      //   }, 60);
      // }
    };
    var toTop = function toTop() {
      nextTick(function () {
        if (pagePopupContent.value) pagePopupContent.value.scrollTop = 0;
      });
    };
    __expose({
      toTop: toTop
    });
    var pageKeyRegExp = /^[a-zA-Z]+$/;
    var queryStringTag = "page=".concat(props.pageKey, "_");
    var queryStringTagRegExp = /page=[a-zA-Z]+_/;
    if (pageKeyRegExp.test(props.pageKey) && isFunction(history.pushState)) {
      var onPopstate = function onPopstate() {
        if (showPopup.value && !location.href.includes(queryStringTag)) {
          showPopup.value = false;
        }
      };
      watch(showPopup, function (val) {
        if (val) {
          if (!location.href.includes(queryStringTag)) {
            history.pushState(null, "", queryStringTagRegExp.test(location.href) ? location.href.replace(queryStringTagRegExp, queryStringTag) : addQueryString(location.href, queryStringTag));
          }
        } else {
          if (location.href.includes(queryStringTag)) {
            history.go(-1);
          }
        }
      });
      onMounted(function () {
        window.addEventListener("popstate", onPopstate);
      });
      onBeforeUnmount(function () {
        window.removeEventListener("popstate", onPopstate);
      });
    }
    return function (_ctx, _cache) {
      var _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createBlock(_component_van_popup, {
        show: showPopup.value,
        "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
          return showPopup.value = $event;
        }),
        position: "right",
        style: {
          height: '100%',
          width: '100%'
        },
        teleport: "body",
        duration: 0.3
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createVNode(Navbar, {
            title: __props.title,
            onBack: onBack
          }, null, 8, ["title"]), _createElementVNode("div", {
            ref_key: "pagePopupContent",
            ref: pagePopupContent,
            class: _normalizeClass(["page-popup_content", _defineProperty({
              'yellow-bg': __props.isYellowBg
            }, __props.contentClass, true)])
          }, [_renderSlot(_ctx.$slots, "default")], 2), __props.showFooter ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_renderSlot(_ctx.$slots, "footer")])) : _createCommentVNode("", true)])];
        }),
        _: 3
      }, 8, ["show"]);
    };
  }
};