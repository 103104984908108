import { showLoadingToast, closeToast } from "vant";

export function showLoading(message) {
  showLoadingToast({
    duration: 0,
    message,
    forbidClick: true,
  });
}

export function hideLoading() {
  closeToast();
}
