import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "menu-container"
};
import MenuHeader from "@/components/menu/MenuHeader.vue";
import MenuBody from "@/components/menu/MenuBody.vue";
import MenuCart from "@/components/menu/MenuCart.vue";
import MenuTip from "@/components/menu/MenuTip.vue";
export default {
  __name: 'MenuView',
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(MenuHeader), _createVNode(MenuBody), _createVNode(MenuCart), _createVNode(MenuTip)]);
    };
  }
};