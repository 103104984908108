import { unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["src"];
import { ref, computed, nextTick } from "vue";
import defaultImg from "@/assets/images/default-img.png";
import { ossStyleProcess } from "@/utils/tools";
export default {
  __name: 'Image',
  props: {
    src: {
      type: String,
      default: ""
    },
    isFadeIn: {
      type: Boolean,
      default: false
    },
    defaultSrc: {
      type: String,
      default: defaultImg
    },
    ossStylename: {
      type: String,
      default: ""
    },
    lazyLoad: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var imgSrc = computed(function () {
      return ossStyleProcess(props.src || props.defaultSrc, props.ossStylename);
    });
    var isLoaded = ref(false);
    function handleLoaded() {
      nextTick(function () {
        isLoaded.value = true;
      });
    }
    return function (_ctx, _cache) {
      var _directive_lazy = _resolveDirective("lazy");
      return _withDirectives((_openBlock(), _createElementBlock("img", {
        class: _normalizeClass(["comp-image", {
          'comp-image--transparent': __props.isFadeIn,
          'comp-image--loaded': _unref(isLoaded)
        }]),
        src: imgSrc.value,
        onLoad: handleLoaded
      }, null, 42, _hoisted_1)), [[_directive_lazy, __props.lazyLoad]]);
    };
  }
};